import React, { useState } from 'react';
import EquipmentsListListing from './EquipmentsListListing';
import EquipmentsMapListing from './EquipmentsMapListing';
import { FormattedMessage } from 'react-intl';
import { Container, Button } from 'semantic-ui-react';
import { Icon } from '../';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faGrip } from '@fortawesome/free-solid-svg-icons';

const EquipmentsListing = (props) => {
	const { content } = props;
	const [activeViewMode, setActiveViewMode] = useState('list');

	const handleViewModeChange = (newViewMode) => {
		setActiveViewMode(newViewMode);
	};

	return (
		<>
			<Container className='text-center mb-3'>
				<div className="view-mode-switcher">
					{activeViewMode === 'list' && (
						<Button primary
							className="clickable-link d-print-none"
							onClick={() => handleViewModeChange('map')}
						>
							<FontAwesomeIcon icon={faMap} className='me-3' />
							<FormattedMessage
								id="Or also view the equipments map"
								defaultMessage="O tambè podeu visualitzar el mapa d'equipaments"
							/>
						</Button>
					)}

					{activeViewMode === 'map' && (
						<Button primary
							className="clickable-link d-print-none" 
							onClick={() => handleViewModeChange('list')}
						>
							<FontAwesomeIcon icon={faGrip} className='me-3' />
							<FormattedMessage
								id="Go back to equipments list"
								defaultMessage="Tornar al llistat d'equipaments"
							/>
						</Button>
					)}
				</div>
			</Container>


			{activeViewMode === 'list' && <EquipmentsListListing content={content} />}
			{activeViewMode === 'map' && <EquipmentsMapListing content={content} />}
		
		</>
	);
};


export default EquipmentsListing;
