import React from "react";
import { Image } from '@plone/volto/components';
import { Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faXmark} from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage,  } from 'react-intl';

const Modal = ({
  clickedImg,
  setClickedImg,
  handelRotationRight,
  handelRotationLeft
}) => {
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      setClickedImg(null); 
    }
  };
  const closeModal = (e) => {
    setClickedImg(null);
  }

  return (
    <>
      <div className="overlay dismiss" onClick={handleClick}>
        <Image  className="relatedMedia__img"
            item={clickedImg}
            loading='lazy'
            alt="Imatge gran" />
        <Button className="dismiss" onClick={closeModal} basic inverted>
          <FontAwesomeIcon icon={faXmark} />
          <span className="visually-hidden"><FormattedMessage id="Close" defaultMessage="Close"/></span>
        </Button>
        <Button onClick={handelRotationLeft} className="overlay-arrows_left" basic inverted>
          <FontAwesomeIcon icon={faArrowLeft} /> 
          <span className="visually-hidden"><FormattedMessage id="Previous" defaultMessage="Previous"/></span>
        </Button>
        <Button onClick={handelRotationRight} className="overlay-arrows_right" basic inverted>
          <FontAwesomeIcon icon={faArrowRight} />
          <span className="visually-hidden"><FormattedMessage id="Next" defaultMessage="Next"/></span>
        </Button>
      </div>
    </>
  );
};

export default Modal;