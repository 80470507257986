import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb, Segment } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { getBreadcrumbs } from '@plone/volto/actions';
import { getBaseUrl, hasApiExpander } from '@plone/volto/helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';

import imgHeader from './default_header.jpg';
import { getHeaderInfo } from '../../actions';

const messages = defineMessages({
	home: {
		id: 'Home',
		defaultMessage: 'Home',
	},
	breadcrumbs: {
		id: 'Breadcrumbs',
		defaultMessage: 'Breadcrumbs',
	},
	controlpanel: {
		id: 'Site Setup',
		defaultMessage: 'Site Setup',
	},
});


const BreadcrumbsComponent = ({ pathname }) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const { pathname: realPath } = useLocation();
	const controlpanelItems = [
		{
			url: '/controlpanel',
			title: intl.formatMessage(messages.controlpanel),
		},
	];

	const items = useSelector(
		(state) =>
			realPath.startsWith('/controlpanel')
				? controlpanelItems
				: state.breadcrumbs.items,
		shallowEqual,
	);
	const root = useSelector((state) => state.breadcrumbs.root);
	const headerImage = useSelector((state) => state?.header_info?.header_info?.header_image);
	const headerImageSrc = headerImage && headerImage.image_field !== 'none' && headerImage['@id']
    ? `${headerImage['@id']}/@@images/${headerImage.image_field}/slide`
    : null;

	useEffect(() => {
		if (!hasApiExpander('breadcrumbs', getBaseUrl(pathname))) {
			dispatch(getBreadcrumbs(getBaseUrl(pathname)));
		}
	}, [dispatch, pathname]);

  	useEffect(() => {
	    if (!hasApiExpander('header_info', getBaseUrl(pathname))) {
	      dispatch(getHeaderInfo(getBaseUrl(pathname)));
	    }
	}, [dispatch, pathname]);

	return (
		<Segment
			as="nav"
			aria-label={intl.formatMessage(messages.breadcrumbs)}
			className="breadcrumbs"
			secondary
			vertical
		>
			{headerImage?.image_field != 'none' ? (
				<img src={headerImageSrc} alt="" className='breadcrumbs__bg' />
			) : (
				<img src={imgHeader} alt="" className='breadcrumbs__bg' />
			)}
			<Breadcrumb as="ul" className='list-unstyled my-0'>
				<li>
					<Link
						to={root || '/'}
						className="section home">
						<FontAwesomeIcon icon={faHouse} />
						<span className="visually-hidden">{intl.formatMessage(messages.home)}</span>
					</Link>
				</li>
				{items.map((item, index, items) => [
					<li key={item.url} >
						{index < items.length - 1 ? (
							<Link to={item.url} className="section">
								{item.title}
							</Link>
						) : (
							<Breadcrumb.Section active as="strong">
								{item.title}
							</Breadcrumb.Section>
						)}
					</li>
				])}
			</Breadcrumb>
		</Segment>
	);
};

BreadcrumbsComponent.propTypes = {
	pathname: PropTypes.string.isRequired,
};

export default BreadcrumbsComponent;
