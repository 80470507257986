import React from 'react';
import loadable from '@loadable/component';
const ClusterInnerMap = loadable(() => import('./ClusterInnerMap'), {ssr: false});

const ClusterMap = (props) => {

  return (
    <ClusterInnerMap {...props} />
  );
};

export default ClusterMap;