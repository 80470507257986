import React from 'react';
import PropTypes from 'prop-types';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Grid, Container } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { UniversalLink, PreviewImage } from '@plone/volto/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { keys, isEmpty, map, filter } from 'lodash';
import { ClusterMap } from '../../';


const EquipmentsListing = ({ items, linkTitle, linkHref, isEditMode, blockTitle }) => {

	let href = (linkHref?.[0]?.['@id']) || '';
	let link = href ? flattenToAppURL(href) : '';

	const itemsGeolocation = items.filter(item => item.geolocation);

	return (
		<div className={!isEditMode && 'full-width ps-4'}>
			{blockTitle && <h3 className="directLinks__title mt-0">{blockTitle}</h3>}

			<Grid columns={12} className='equipmentsMapListing '>
				<Grid.Column className='d-flex flex-column' mobile={12} tablet={6} computer={4} widescreen={4}>
					<div className={"position-relative flex-grow-1 " + (!isEditMode && ' ps-3') }>
						<ul className="equipmentsMapListing__list list-unstyled mb-0">
							{itemsGeolocation.map((item) => (
								<li className='equipmentsMapListing__list__item' key={item['@id']}>
									<UniversalLink href={item['@id']}>
										<span className="equipmentsMapListing__list__item__title">{item.title}</span>
									</UniversalLink>
								</li>
							))}
						</ul>
					</div>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={6} computer={8} widescreen={8} className="equipmentsMapListing__map">
					<ClusterMap markers={itemsGeolocation} />
				</Grid.Column>
			</Grid>

			{link &&
				<p className="readMoreItem d-print-none">
					<UniversalLink href={link} className="readMoreItem__btn ui button primary">
						{linkTitle ? (<span>{linkTitle}</span>) :
							(<FormattedMessage id="See all" defaultMessage="See all" />)}
						{blockTitle}
						<FontAwesomeIcon icon={faChevronRight} className="readMoreItem__icon ms-3" />
					</UniversalLink>
				</p>
			}
		</div>
	);
}

EquipmentsListing.propTypes = {
	linkHref: PropTypes.arrayOf(PropTypes.any),
	items: PropTypes.arrayOf(PropTypes.any).isRequired,
	linkMore: PropTypes.any,
	isEditMode: PropTypes.bool,
};

export default EquipmentsListing;
