/**
 * EventsListListing component.
 * @module components/Events/EventsListListing
 */

import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Container, Header, Grid, Card, Form } from 'semantic-ui-react';
import { BodyClass, hasBlocksData } from '@plone/volto/helpers';
import { UniversalLink, FormattedDate, Pagination, Image } from '@plone/volto/components';
import { searchContent, getVocabulary } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { keys, isEmpty, map } from 'lodash';
import { useDebounce } from "use-debounce";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faCalendarDays, faLocationDot, faCalendar } from '@fortawesome/free-solid-svg-icons';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { SingleDatePicker } from 'react-dates';
import { toBackendLang } from '@plone/volto/helpers/Utils/Utils';
import { formatDate } from '../../../src/helpers/formatDate'
import { EventsList, EventsMapListing } from './'

const messages = defineMessages({
	labelText: {
		id: 'By words',
		defaultMessage: 'By words',
	},
	placeholderText: {
		id: 'What are you looking for?',
		defaultMessage: 'What are you looking for?',
	},
	defaultOptionSubjects: {
		id: 'All subjects',
		defaultMessage: 'All subjects',
	},
	labelSubject: {
		id: 'By category',
		defaultMessage: 'By category',
	},
	placeholderAllSubjects: {
		id: 'All subjects',
		defaultMessage: 'All subjects',
	},
	date: {
		id: 'Date',
		defaultMessage: 'Date',
	},
});

const EventsListListing = (props) => {
	const { content } = props;
	const lang = content.language.token;
	const dispatch = useDispatch();
	const intl = useIntl();
	const events = useSelector((state) => state.search.subrequests.events?.items);
	const total = useSelector((state) => state.search.subrequests.events?.total);
	const [currentPage, setCurrentPage] = useState(0);
	const [pageSize, setPageSize] = useState(12);
	const [search, setSearch] = useState(0);
	const [searchableTextInput, setSearchableText] = useState('');
	const [searchableText] = useDebounce(searchableTextInput, 500);
	const [subject, setSubject] = useState('');
	const [date, setDate] = useState(null);
	const [focused, setFocused] = useState(false);
	const vocabularySubjects = 'product.matadepera.vocabularies.EventSubjects';

	const itemsSubjects = useSelector(
		(state) =>
			state.vocabularies[vocabularySubjects] && state.vocabularies[vocabularySubjects].items
				? [{ value: '', label: intl.formatMessage(messages.defaultOptionSubjects) }, ...state.vocabularies[vocabularySubjects].items]
				: [],
		shallowEqual,
	);

	const getEventsSubject = function (Subject) {
		if (!itemsSubjects) {
			return Subject
		}
		for (let item of itemsSubjects) {
			if (item.value === Subject) return item.label
		}
		return Subject
	}

	const getEventDate = function (start) {
		const startDay = formatDate(start)
		//const endDay = formatDate(end)
		let date = ''
		const optionsFullTime = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
		const optionsTime = { hour: '2-digit', minute: '2-digit' };
		// if (startDay === endDay) {
		// 	date = formatDate(start, optionsFullTime) + ' - ' + formatDate(end, optionsTime)
		// } else {
		// 	date = formatDate(start, optionsFullTime) + ' - ' + formatDate(end, optionsFullTime)
		// }
		date = formatDate(start, optionsFullTime);
		return date
	}

	useEffect(() => {
		dispatch(getVocabulary({ vocabNameOrURL: vocabularySubjects, lang: content.language.token }));
	}, [dispatch]);

	useEffect(() => {
		let contentFilter = {
			portal_type: ['Event'],
			b_size: pageSize,
			b_start: currentPage * pageSize,
			review_state: 'published',
			SearchableText: searchableText,
			sort_on: 'start',
			metadata_fields: ['start', 'end', 'event_subjects', 'hasPreviewImage']
		};
		if (subject && subject !== '') {
			contentFilter.event_subjects = subject;
		}
		if (date) {
			const startDate = date.startOf('day').format('YYYY/MM/DD') + ' 00:00';
			const endDate = date.endOf('day').format('YYYY/MM/DD') + ' 23:59';
			contentFilter['start.query'] = [startDate, endDate]
			contentFilter['start.range'] = 'min:max'
		} else {
        	contentFilter['end.query'] = [moment().format('YYYY/MM/DD HH:mm')]
        	contentFilter['end.range'] = 'min'
		}
		dispatch(
			searchContent(
				flattenToAppURL(content['@id']),
				contentFilter,
				'events',
			),
		);
	}, [dispatch, currentPage, pageSize, searchableText, date, subject, search]);

	useEffect(() => {
		const searchForm = document.querySelector('.searchList');
		const searchFormPos = searchForm ? searchForm.offsetTop : 0;
		let scrollPos = window.scrollY;
		if (scrollPos > searchFormPos && searchForm) {
			window.scrollTo({ top: searchFormPos })
		}
	}, [currentPage]);

	useEffect(() => {
    	moment.locale(lang || 'ca');
	}, [lang]);

	const onChangePage = (event, activePage) => {
		event.preventDefault();
		setCurrentPage(activePage.value);
	};

	const onChangePageSize = (event) => {
		event.preventDefault();
		setPageSize(parseInt(event.target.text));
		setCurrentPage(0);
	};

	const onChangeSearchableText = (event, data) => {
		event.preventDefault();
		setSearchableText(data.value);
		setCurrentPage(0);
	};

	const onChangeSubject = (event, data) => {
		event.preventDefault();
		setSubject(data.value);
		setCurrentPage(0);
	};

	const onDateChange = (data) => {
		setDate(data)
		setCurrentPage(0);
	};

	const onSubmit = (event) => {
		event.preventDefault();
		setSearch(search + 1);
		setCurrentPage(0);
	};

	const onFocusChange = (data) => {
		setFocused(data.focused)
	};



	return (
		<>
			<BodyClass className='eventslisting' />

			{hasBlocksData(content) ? (
				<Container>
					<Grid>
						<Grid.Column width={12}>
							<RenderBlocks {...props} />
						</Grid.Column>
					</Grid>
				</Container>
			) : null}

			<Form className="searchList d-print-none" onSubmit={onSubmit}>
				<Container className="pb-4">
					<Header as="h2" className='justify-content-center searchList__title' size='small' textAlign='center'><FormattedMessage id="Filter" defaultMessage="Filter" /></Header>
					<Form.Group widths="equal" className="align-items-end">
						<Form.Input
							id="textInputId"
							fluid
							label={intl.formatMessage(messages.labelText)}
							focus
							name="searchableText"
							value={searchableTextInput}
							onChange={onChangeSearchableText}
							placeholder={intl.formatMessage(messages.placeholderText)}
							type="text"
						/>
						<Form.Select
							id="subjectSelectId"
							fluid
							label={intl.formatMessage(messages.labelSubject)}
							options={map(itemsSubjects, (item, index) => {
								return {
									key: index,
									value: item.value,
									text: item.label,
								};
							})}
							name="subject"
							value={subject}
							onChange={onChangeSubject}
							placeholder={intl.formatMessage(messages.placeholderAllSubjects)}
							aria-label={intl.formatMessage(messages.labelSubject)}
						/>
						<div className="field searchList__date">
							<label htmlFor="date"><FormattedMessage id="Date" defaultMessage="Date" /></label>
							<div className="date-widget-wrapper">
								<div className="ui input date-input">
									<SingleDatePicker
										date={date}
										onDateChange={onDateChange}
										focused={focused}
										numberOfMonths={1}
										onFocusChange={onFocusChange}
										noBorder
										showClearDate
										displayFormat={moment
											.localeData(toBackendLang(lang))
											.longDateFormat('L')}
										isOutsideRange={() => false}
										placeholder={intl.formatMessage(messages.date)}
										id="date"
									/>
								</div>
							</div>
						</div>
					</Form.Group>
				</Container>
			</Form>

			<div className="bg-gray-200 py-5">
				{!isEmpty(events) ? (
					<Container className="events eventsListing">
						<Grid>
							<Grid.Row columns={12}>
								{events.map((item) => (
									<Grid.Column className="mb-4" mobile={12} tablet={6} computer={4} widescreen={3} key={item['@id']}>
										<Card fluid as="article" className={'eventsItem position-relative ' + (item?.hasPreviewImage ? ' eventsItem-hasImage' : ' eventsItem-noImage')}>
											{item?.hasPreviewImage &&
												<Image
													item={item}
													loading='lazy'
													alt=""
													className="eventsItem__img m-0 image"
													sizes="(max-width: 767px) 100vw, (max-width: 991px) 50vw, (max-width: 1599px) 33.33vw, 25vw"
												/>
											}
											<Card.Content className='eventsItem__text'>
												<Card.Header className="eventsItem__title" as="h2">
													<UniversalLink
														className="eventsItem__link stretched-link"
														href={flattenToAppURL(item['@id'])}>
														{item.title}
													</UniversalLink>
												</Card.Header>
												{item.description && (<Card.Description as="p" className="eventsItem__desc">{item.description}</Card.Description>)}
												<hr />
												<time className="eventsItem__date mb-2" dateTime={item.start}>
													<FontAwesomeIcon className='eventsItem__icon' icon={faCalendarDays} /> 
													{getEventDate(item.start)}
												</time>
												{item.location && (<p className="eventsItem__location mb-2"><FontAwesomeIcon className='eventsItem__icon' icon={faLocationDot} /> {item.location}</p>)}
												{item.event_subjects?.length > 0  && (
													<Card.Meta className="eventsItem__meta">
														<FontAwesomeIcon className='eventsItem__icon' icon={faTag} />
														{map(item.event_subjects.slice(0, 1), (item, index) => (
															<span key={index} className="eventsItem__subject">{getEventsSubject(item)}</span>
														))}
													</Card.Meta>
												)}

											</Card.Content>
										</Card>
									</Grid.Column>
								))}
							</Grid.Row>
						</Grid>

						{total > pageSize && (
							<nav className="pagination">
								<Pagination
									current={currentPage}
									total={Math.ceil(total / pageSize)}
									onChangePage={onChangePage}
									onChangePageSize={onChangePageSize}
								/>
							</nav>
						)}

					</Container>
				) : (
					<Container className="events eventsListListing">
						<p><FormattedMessage id="No events found" defaultMessage="No events found" /></p>
					</Container>
				)}
			</div>
		</>
	);
};


export default EventsListListing;
