import React from 'react';
import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';
import { defineMessages } from 'react-intl';


const messages = defineMessages({
  term: {
    id: 'Term-Entry',
    defaultMessage: 'Term-Entry',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
});


const ItemSchema = (props) => {
    return {
        title: props.intl.formatMessage(messages.term),
        properties: {
            term_id: {
                title: 'ID',
            },
            title: {
                title: props.intl.formatMessage(messages.title),
            },
        },
        fieldsets: [
            {
                id: 'default',
                title: props.intl.formatMessage(messages.term),
                fields: [
                    'term_id',
                    'title',
                ],
            },
        ],
        required: ['term_id', 'title'],
    };
};


const AdvancedVocabularyTermWidget = (props) => {
    return (
        <ObjectListWidget
            schema={ItemSchema}
            {...props}
            value={props.value?.items || props.default?.items || []}
            onChange={(id, value) => props.onChange(id, { items: value })}
        />
    );
};

export default AdvancedVocabularyTermWidget;