/**
 * Tabular view component.
 * @module components/theme/View/TabularView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container as SemanticContainer, Table } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import config from '@plone/volto/registry';

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink, Pagination } from '@plone/volto/components';

/**
 * Tabular view component class.
 * @function TabularView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const TabularView = ({ content }) => {
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;

  const dispatch = useDispatch();
  const items = useSelector((state) => state.search.subrequests.items?.items);
  const total = useSelector((state) => state.search.subrequests.items?.total);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);

  useEffect(() => {
    let contentFilter = {
      b_size: pageSize,
      b_start: currentPage * pageSize,
      sort_on: 'getObjPositionInParent',
    };
    contentFilter['path.depth'] = 1
    dispatch(searchContent(flattenToAppURL(content['@id']), contentFilter, 'items'));
  }, [dispatch, currentPage, pageSize]);

  const onChangePage = (event, activePage) => {
    event.preventDefault();
    setCurrentPage(activePage.value);
  };

  const onChangePageSize = (event) => {
    event.preventDefault();
    setPageSize(parseInt(event.target.text));
    setCurrentPage(0);
  };

  return (
    <Container className="view-wrapper">
      <div id="content">      
        <sedivction id="content-core">
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <FormattedMessage id="Title" defaultMessage="Title" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage
                    id="Description"
                    defaultMessage="Description"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="Type" defaultMessage="Type" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="State" defaultMessage="State" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
            {items &&
              items.map((item, index) => (
                <Table.Row key={item.url}>
                  <Table.Cell>
                    <UniversalLink
                      item={item}
                      className="summary url"
                      title={item['@type']}
                    >
                      {item.title}
                    </UniversalLink>
                  </Table.Cell>
                  <Table.Cell>{item.description}</Table.Cell>
                  <Table.Cell>{item['@type']}</Table.Cell>
                  <Table.Cell>{item.review_state}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>

          {total > pageSize && (
            <nav className="pagination">
              <Pagination
                current={currentPage}
                total={Math.ceil(total / pageSize)}
                onChangePage={onChangePage}
                onChangePageSize={onChangePageSize}
              />
            </nav>
          )}

        </sedivction>
      </div>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
TabularView.propTypes = {
  /**
   * Content of the object
   */
  content: PropTypes.shape({
    /**
     * Title of the object
     */
    title: PropTypes.string,
    /**
     * Description of the object
     */
    description: PropTypes.string,
    /**
     * Child items of the object
     */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Title of the item
         */
        title: PropTypes.string,
        /**
         * Description of the item
         */
        description: PropTypes.string,
        /**
         * Url of the item
         */
        url: PropTypes.string,
        /**
         * Review state of the item
         */
        review_state: PropTypes.string,
        /**
         * Type of the item
         */
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default TabularView;
