import { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getWeather } from '../../actions';
import moment from 'moment';


const Weather = props => {
    const dispatch = useDispatch();
    const weather = useSelector((state) => state.weather?.data);
    const [weatherData, setWeatherData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://meteoct.com/meteoct/json.php?station_id=ESCAT0800000008230B&_=' + new Date().getTime());
                const result = await response.json();
                setWeatherData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

        const intervalId = setInterval(fetchData, 60000); 
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        dispatch(getWeather());
    }, [dispatch]);

    const getDate = function (dateStr) {
        if (!dateStr) return;

        const date = moment(dateStr, 'DD-MM-YYYY HH:mm');
        if (!date.isValid()) return;

        const formattedDate = new Intl.DateTimeFormat('ca-ES', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        }).format(date);
        return formattedDate
    }

    return (
        <>
            {(weatherData && 'TMP' in weatherData) && (
                <div className="portalHeader__info">
                    <time dateTime={weatherData.UPD} className="portalHeader__info__date">{getDate(weatherData.UPD)}</time>
                        <a className="portalHeader__info__weather" href={'/coneix-matadepera/temps-detall'} title="Temps al detall">
                            {weatherData?.TMP} &deg;C
                        {weather?.status === 1 && (
                            <img lang="es" src={'/aemet/' + weather.today.sky_status + '.svg'} alt={weather.today.sky_status_desc} className="portalHeader__info__weather__img" />
                        )}
                        </a>
                </div>
            )}
        </>
    );

};

export default Weather;