/**
 * MinisiteView component.
 * @module components/View/MinisiteView
 */
import React from 'react';
import config from '@plone/volto/registry';
import { hasBlocksData, getBaseUrl } from '@plone/volto/helpers';
import { FormattedMessage, defineMessages } from 'react-intl';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { Sharing, CustomerSatisfaction } from '../';
import { isEqual } from 'lodash';
import {
  Container as SemanticContainer,
} from 'semantic-ui-react';


const MinisiteView = (props) => {
	const { content, location } = props;
 	const path = getBaseUrl(location?.pathname || '');
	const contentLoaded = content && !isEqual(Object.keys(content), ['lock']);
  	const Container =
    	config.getComponent({ name: 'Container' }).component || SemanticContainer;

	 return contentLoaded ? (
	    hasBlocksData(content) && (
	      <>
	      <Container id="page-document">
	        <div className="mb-5">
	          <RenderBlocks {...props} path={path} />
	        </div>
	        <Sharing {...content} />
	        <CustomerSatisfaction/>
	      </Container>
	      </>
	    )
	  ) : null;
};

export default MinisiteView;