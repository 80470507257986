/**
 * Air quality reducer.
 * @module reducers/air_quality
 */

import {
  GET_AIR_QUALITY
} from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: {},
};

export default function air_quality(state = initialState, action = {}) {
  switch (action?.type) {
    case `${GET_AIR_QUALITY}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_AIR_QUALITY}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };

    case `${GET_AIR_QUALITY}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        data: action.result,
      };
    default:
      return state;
  }
};