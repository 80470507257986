import { last } from 'lodash';

export function getView(url) {
  const view = last(url.replace(/\?.*$/, '').split('/'));
  if (
    [
      'add',
      'layout',
      'contents',
      'edit',
      'delete',
      'diff',
      'history',
      'sharing',
      'controlpanel',
      'search',
    ].indexOf(view) === -1
  ) {
    return 'view';
  }
  if (view.includes('/controlpanel/')) {
    return 'controlpanel'
  }

  return view === 'layout' ? 'edit' : view;
}