import { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Segment, Header, Table } from 'semantic-ui-react';
import { FormattedMessage, injectIntl, defineMessages, useIntl } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { map, isEmpty } from 'lodash';
import { getWeather, getAirQuality } from '../../../actions';


// TRANSLATE
const messages = defineMessages({
	tempNow: {
		id: 'Actual',
		defaultMessage: 'Actual',
	},
	tempMin: {
		id: 'Mínima',
		defaultMessage: 'Máxima',
	},
	tempMax: {
		id: 'Mínima',
		defaultMessage: 'Máxima',
	},
});

const View = (props) => {
	const { data, mode, content } = props;
	const intl = useIntl();
    const dispatch = useDispatch();
    const weather = useSelector((state) => state.weather?.data);
    const airQuality = useSelector((state) => state.air_quality?.data);
	const [weatherData, setWeatherData] = useState(null);
	let weatherHref = (data.weatherLink?.[0]?.['@id']) || '';
	let weatherLink = weatherHref ? flattenToAppURL(weatherHref) : '';

	useEffect(() => {
	    const fetchData = async () => {
	      	try {
	        	const response = await fetch('https://meteoct.com/meteoct/json.php?station_id=ESCAT0800000008230B&_=' + new Date().getTime());
	        	const result = await response.json();
	        	setWeatherData(result);
	      	} catch (error) {
	        	console.error('Error fetching data:', error);
	      	}
	    };

	    fetchData();

	    // const intervalId = setInterval(fetchData, 60000); 
	    // return () => clearInterval(intervalId);
	}, []);

    useEffect(() => {
        dispatch(getWeather());
        dispatch(getAirQuality());
    }, [dispatch]);

	return (
		<Segment id="useful-info" as="section"  basic className='pb-0'>
		<div className='usefulInfo '>

			<div className="usefulInfo__wrapper">
				{data?.title ?
					<Header className='usefulInfo__title1' as="h2">{data.title}</Header>
					:
					<Header className='visually-hidden' as="h2"><FormattedMessage id="Matadepera en directe" defaultMessage="Matadepera en directe" /></Header>
				}
				
				{data.showWeather && (
				<>
				<Header as="h3" className='usefulInfo__title2 fs-6'><FormattedMessage id="El temps" defaultMessage="El temps" /></Header>
				<div className="usefulInfo__weather">
					<figure className="usefulInfo__weather__live">
                        {weather?.status === 1 && (
                        <img src={'/aemet/' + weather.today.sky_status + '.svg'} alt={weather.today.sky_status_desc} className="usefulInfo__weather__live__img" />
                        )}
						<figcaption className='usefulInfo__weather__live__link'>
							{weatherLink && (
								<UniversalLink href={weatherLink}>
									<FormattedMessage id="Veure amb detall" defaultMessage="Veure amb detall" />
								</UniversalLink>
							)}
						</figcaption>
					</figure>
					{(weatherData && 'TMP' in weatherData) && (
						<Table celled padded as="table"  inverted className='unstackable usefulInfo__weather__data'>
							<Table.Header as="thead">
								<Table.Row as="tr">
									<Table.Cell as="td">&nbsp;</Table.Cell>
									<Table.HeaderCell as="th"><abbr title={intl.formatMessage(messages.tempNow)}><FormattedMessage id="Act." defaultMessage="Act." /></abbr></Table.HeaderCell>
									<Table.HeaderCell as="th"><abbr title={intl.formatMessage(messages.tempMin)}><FormattedMessage id="Min." defaultMessage="Min." /></abbr></Table.HeaderCell> 
									<Table.HeaderCell as="th"><abbr title={intl.formatMessage(messages.tempMax)}><FormattedMessage id="Max." defaultMessage="Max." /></abbr></Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body as="tbody">
								{('TMP' in weatherData) && (
								<Table.Row as="tr">
									<Table.Cell as="th" scope="row">Temperatura</Table.Cell>
									<Table.Cell as="td">{weatherData?.TMP} &deg;C</Table.Cell>
									<Table.Cell as="td">{weatherData?.DLTM} &deg;C</Table.Cell>
									<Table.Cell as="td">{weatherData?.DHTM} &deg;C</Table.Cell>
								</Table.Row>
								)}
								{('HUM' in weatherData) && (
								<Table.Row as="tr">
									<Table.Cell as="th" scope="row">Humitat</Table.Cell>
									<Table.Cell as="td">{weatherData?.HUM}%</Table.Cell>
									<Table.Cell as="td">{weatherData?.DLHM}%</Table.Cell>
									<Table.Cell as="td">{weatherData?.DHHM}%</Table.Cell>
								</Table.Row>
								)}
								{('WND' in weatherData) && (
								<Table.Row as="tr">
									<Table.Cell as="th" scope="row">Vent</Table.Cell>
									<Table.Cell as="td">{weatherData?.WND} Km/h</Table.Cell>
									<Table.Cell as="td"></Table.Cell>
									<Table.Cell as="td">{weatherData?.DGST} Km/h</Table.Cell>
								</Table.Row>
								)}
							</Table.Body>
						</Table>
					)}
				</div>
				</>
				)}
	   
				{data.showAirQuality && (
				<div className="usefulInfo__aire">
					<Header as="h3" className='usefulInfo__title2 fs-6'><FormattedMessage id="Qualitat de l'aire" defaultMessage="Qualitat de l'aire" /></Header>
					<ul className='list-unstyled usefulInfo__aire__list'>
						<li> 
                            <abbr className="text-bold me-2" title="Monòxid de carboni">CO</abbr>
                            <span id="co" className={'me-2 ' + (airQuality?.co?.circle || 'grey-circle')} aria-hidden="true"
                            	title={airQuality?.co?.value || 'Sense dades'}></span>
                            <span id="co-text" className="text-capitalize">{airQuality?.co?.status || 'Sense dades'}</span>     				
						</li>
						<li>
                            <abbr className="text-bold me-2" title="Diòxid de nitrogen">NO<sub>2</sub></abbr>
                            <span id="no2" className={'me-2 ' + (airQuality?.no2?.circle || 'grey-circle')} aria-hidden="true"
                            	title={airQuality?.no2?.value || 'Sense dades'}></span>
                            <span id="no2-text" className="text-capitalize">{airQuality?.no2?.status || 'Sense dades'}</span>   
						</li>
						<li>
                            <abbr className="text-bold me-2" title="Ozó">O<sub>3</sub></abbr>
                            <span id="o3" className={'me-2 ' + (airQuality?.o3?.circle || 'grey-circle')} aria-hidden="true"
                            	title={airQuality?.o3?.value || 'Sense dades'}></span>
                            <span id="o3-text" className="text-capitalize">{airQuality?.o3?.status || 'Sense dades'}</span>      
						</li>
						<li>
                            <abbr className="text-bold me-2" title="Petites particules de pols">PM<sub>10</sub></abbr>
                            <span id="pm10" className={'me-2 ' + (airQuality?.pm10?.circle || 'grey-circle')} aria-hidden="true"
                            	title={airQuality?.pm10?.value || 'Sense dades'}></span>
                            <span id="pm10-text" className="text-capitalize">{airQuality?.pm10?.status || 'Sense dades'}</span>

						</li>
						<li>
                            <abbr className="text-bold me-2" title="Diòxid de sofre">SO<sub>2</sub></abbr>
                            <span id="so2" className={'me-2 ' + (airQuality?.so2?.circle || 'grey-circle')} aria-hidden="true"
                            	title={airQuality?.so2?.value || 'Sense dades'}></span>
                            <span id="so2-text" className="text-capitalize">{airQuality?.so2?.status || 'Sense dades'}</span>     
						</li>
					</ul>
				</div>
				)}
			</div>
		</div>
		</Segment>
	);
};

export default View;
