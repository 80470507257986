/**
 * ImportTramitsForm component.
 * @module components/Tramits/ImportTramitsForm
 */
import { useCallback, useEffect, useRef } from 'react';
import { Portal } from 'react-portal';
import { Container, Message, Icon } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Toast } from '@plone/volto/components';
import { getBaseUrl, usePrevious } from '@plone/volto/helpers';
import { importTramits } from '../../actions';

const messages = defineMessages({
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  file: {
    id: 'File',
    defaultMessage: 'File',
  },
  formTitle: {
    id: 'Importar tramits',
    defaultMessage: 'Importar tramits',
  },
  send: {
    id: 'Send',
    defaultMessage: 'Send',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  sucessMessage: {
    id: 'Your application has been registered successfully',
    defaultMessage: 'Your application has been registered successfully',
  },
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
});


const useImportTramits = () => {
  const loading = useSelector((state) => state.importTramits.loading);
  const loaded = useSelector((state) => state.importTramits.loaded);
  const error = useSelector((state) => state.importTramits.error);

  return { loading, loaded, error };
};


const ImportTramitsForm = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { pathname } = useLocation();

  const { loaded, loading, error } = useImportTramits();
  const prevloading = usePrevious(loading);

  useEffect(() => {
    if (prevloading && loaded) {
      toast.success(
        <Toast
          success
          title={intl.formatMessage(messages.success)}
          content={intl.formatMessage(messages.sucessMessage)}
        />,
      );
    }
  }, [intl, loaded, prevloading]);

  const onSubmit = (data) => {
    dispatch(importTramits(getBaseUrl('/'), data));
  };

  return (
    <div id="import-tramits-form">
        <Form
          id='form'
          onSubmit={onSubmit}
          formData={{ blocksLayoutFieldname: {} }}
          submitLabel={intl.formatMessage(messages.send)}
          resetAfterSubmit
          title={intl.formatMessage(messages.formTitle)}
          loading={loading}
          schema={{
            fieldsets: [
              {
                fields: [
                  'file'
                ],
                id: 'default',
                title: intl.formatMessage(messages.default),
              },
            ],
            properties: {
              file: {
                title: intl.formatMessage(messages.file),
                widget: 'file',
              }
            },
            required: ['file'],
          }}
        />
    </div>
  );
};

export default ImportTramitsForm;
