/**
 * importTramits actions.
 * @module actions/importTramits/importTramits
 */

import { IMPORT_TRAMITS } from '../../constants/ActionTypes';

/**
 * importTramits function
 * @function importTramits
 * @param {string} url Content url.
 * @param {Object} Form data
 * @returns {Object} importTramits action.
 */
export function importTramits(url, data) {
  return {
    type: IMPORT_TRAMITS,
    request: {
      op: 'post',
      path: `${url}/@import-tramits`, 
      data: data,
    },
  };
}
