import React from 'react';
import PropTypes from 'prop-types';
import { hasBlocksData, flattenHTMLToAppURL } from '@plone/volto/helpers';
import { Grid } from 'semantic-ui-react';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { Container } from 'semantic-ui-react';
import config from '@plone/volto/registry';
import { CustomerSatisfaction } from '../';
import { EquipmentDetails } from './';


const EquipmentView = (props) => {
  const { content } = props;
  return (
    <Container id="page-document" className="view-wrapper equipment-view">
      <Grid columns={12} className='equipment-view__wrapper'>
        <Grid.Column mobile={12} tablet={12} computer={8} widescreen={8} >
          {hasBlocksData(content) && (
            <RenderBlocks {...props} />
          )}
        </Grid.Column>
        <Grid.Column mobile={12} tablet={12} computer={4}  widescreen={8} as="aside" >
          <EquipmentDetails content={content} />
        </Grid.Column>
      </Grid>
      <CustomerSatisfaction/>
    </Container>
  );
};


export default EquipmentView;
