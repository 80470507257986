/**
 * NewsItemView view component.
 * @module components/theme/View/NewsItemView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container as SemanticContainer, Grid, Modal, Segment } from 'semantic-ui-react';
import { hasBlocksData, flattenHTMLToAppURL } from '@plone/volto/helpers';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import config from '@plone/volto/registry';
import { Attachments, Sharing, CustomerSatisfaction } from '../';
import { FormattedDate } from '@plone/volto/components';
import { FormattedMessage } from 'react-intl';

/**
 * NewsItemView view component class.
 * @function NewsItemView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const NewsItemView = ({ content }) => {
  const Image = config.getComponent({ name: 'Image' }).component;
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;

  return hasBlocksData(content) ? (
    <Container id="page-document" className="view-wrapper newsitem-view">
      <time dateTime={content.effective} className='d-block mb-4 fw-bold'>
        <FormattedDate date={content.effective} className=""
          format={{
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }}
        />
      </time>
      <Grid>
        <Grid.Row columns={12}>
          <Grid.Column mobile={12} tablet={6} computer={7} className='mb-5 mb-md-0'>
            <RenderBlocks content={content} />
          </Grid.Column>
          {content.preview_image && (
            <Grid.Column mobile={12} tablet={6} computer={5}>
              <Modal
                className="m-0 p-0"
                size="fullscreen"
                basic
                trigger={
                  <figure className="newsPage__img" >
                    <p className="d-block fs-sm fst-italic mb-1"><FormattedMessage id="Feu clic en la imatge per a veure-la més gran" defaultMessage="Feu clic en la imatge per a veure-la més gran" /></p>
                    <a href='#' role='button' className='documentImage'
                    >
                      <Image className="documentImage ui image"
                        alt=""
                        item={content}
                        imageField="preview_image"
                        responsive={true}
                        sizes="(max-width: 767px) 100vw, (max-width: 991px) 50vw, 20vw"
                      />
                    </a>
                    {content.preview_caption && (
                      <figcaption className='newsPage__img__caption'>{content.preview_caption}</figcaption>
                    )}
                  </figure>
                }
                closeIcon
              >
                {content.preview_caption && (
                  <Modal.Header>
                    <Header as="h2" className='fs-4'>{content.preview_caption}</Header>
                  </Modal.Header>
                )}
                <Segment basic className='m-0 p-0'>
                  <Modal.Content image as="figure" className='m-0' >
                    <Image className="documentImage ui image w-100 m-0"
                      alt={content?.preview_caption ? content.preview_caption : ""}
                      item={content}
                      imageField="preview_image"
                      responsive={true}
                      sizes="100vw"
                    />
                  </Modal.Content>
                </Segment>
              </Modal>

 
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
      <Attachments {...content} />
      <Sharing {...content} />
      <CustomerSatisfaction />
    </Container>
  ) : (
    <Container className="view-wrapper">
      {content.title && (
        <h1 className="documentFirstHeading">
          {content.title}
          {content.subtitle && ` - ${content.subtitle}`}
        </h1>
      )}
      {content.description && (
        <p className="documentDescription">{content.description}</p>
      )}
      <time dateTime={content.effective} className='d-block mb-4 fw-bold'>
        <FormattedDate date={content.effective} className=""
          format={{
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }}
        />
      </time>
      <Grid>
        <Grid.Row columns={12}>
          {content.text && (
            <Grid.Column mobile={12} tablet={6} computer={7} className='mb-5 mb-md-0'>
              <div
                dangerouslySetInnerHTML={{
                  __html: flattenHTMLToAppURL(content.text.data),
                }}
              />
            </Grid.Column>
          )}
          {content.image && (
            <Grid.Column mobile={12} tablet={6} computer={5}>
              <Image
                className="documentImage ui right floated image"
                alt={content.title}
                title={content.title}
                item={content}
                imageField="image"
                responsive={true}
              />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
NewsItemView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default NewsItemView;
