/**
 * Summary view component.
 * @module components/theme/View/SummaryView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container as SemanticContainer } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import config from '@plone/volto/registry';

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink, Pagination } from '@plone/volto/components';

/**
 * Summary view component class.
 * @function SummaryView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const SummaryView = ({ content }) => {
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;
  const PreviewImage = config.getComponent({ name: 'PreviewImage' }).component;

  const dispatch = useDispatch();
  const items = useSelector((state) => state.search.subrequests.items?.items);
  const total = useSelector((state) => state.search.subrequests.items?.total);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);

  useEffect(() => {
    let contentFilter = {
      b_size: pageSize,
      b_start: currentPage * pageSize,
      sort_on: 'getObjPositionInParent',
    };
    contentFilter['path.depth'] = 1
    dispatch(searchContent(flattenToAppURL(content['@id']), contentFilter, 'items'));
  }, [dispatch, currentPage, pageSize]);

  const onChangePage = (event, activePage) => {
    event.preventDefault();
    setCurrentPage(activePage.value);
  };

  const onChangePageSize = (event) => {
    event.preventDefault();
    setPageSize(parseInt(event.target.text));
    setCurrentPage(0);
  };

  return (
    <Container className="view-wrapper summary-view">
      <div id="content">
        <div id="content-core">
          {items &&
            items.map((item, index) => (
            <>
              <article key={item.url} className='summary-view__item'>
                <h2>
                  {item.title}
                </h2>
                <div className={' ' + (item.image_field && ' summary-view__item--hasImage') }>
                  <div className='flex-grow-1'>
                    {item.description && <p>{item.description}</p>}
                    <p>
                      <UniversalLink item={item} title={item['@type']}>
                        <FormattedMessage
                          id="Read More…"
                          defaultMessage="Read More…"
                        /> {item.title}
                      </UniversalLink>
                    </p>
                  </div>
                  {item.image_field && (
                    <PreviewImage
                      item={item}
                      alt={item?.image_caption ? item.image_caption : ''}
                      className="ui image clear summary-view__item__image"
                      responsive={true}
                      loading="lazy"
                      sizes="(max-width: 767px) 100vw, (max-width: 1279px) 12rem, 25rem"
                    />
                  )}
                </div>
              </article> 
            </>
          ))}

          {total > pageSize && (
            <nav className="pagination">
              <Pagination
                current={currentPage}
                total={Math.ceil(total / pageSize)}
                onChangePage={onChangePage}
                onChangePageSize={onChangePageSize}
              />
            </nav>
          )}          
        </div>
      </div>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
SummaryView.propTypes = {
  /**
   * Content of the object
   */
  content: PropTypes.shape({
    /**
     * Title of the object
     */
    title: PropTypes.string,
    /**
     * Description of the object
     */
    description: PropTypes.string,
    /**
     * Child items of the object
     */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Title of the item
         */
        title: PropTypes.string,
        /**
         * Description of the item
         */
        description: PropTypes.string,
        /**
         * Url of the item
         */
        url: PropTypes.string,
        /**
         * Image of the item
         */
        image: PropTypes.object,
        /**
         * Image caption of the item
         */
        image_caption: PropTypes.string,
        /**
         * Type of the item
         */
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default SummaryView;
