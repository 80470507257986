import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { Segment, Header, List, Button } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import { expandToBackendURL } from '@plone/volto/helpers';
import { Map } from '../';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare} from '@fortawesome/free-solid-svg-icons';

const messages = defineMessages({
  contact: {
    id: 'Contact',
    defaultMessage: 'Contact',
  },
  email: {
    id: 'Email',
    defaultMessage: 'Email',
  },
  telephone: {
    id: 'Telephone',
    defaultMessage: 'Telephone',
  },
  where: {
    id: 'Where',
    defaultMessage: 'Where',
  },
  website: {
    id: 'event_website',
    defaultMessage: 'Website',
  },
  visitWebsite: {
    id: 'visit_external_website',
    defaultMessage: 'Visit external website',
  },
  typologies: {
    id: 'Typologies',
    defaultMessage: 'Typologies',
  },
  address: {
    id: 'Address',
    defaultMessage: 'Address',
  },
});

const EventDetails = ({ content, display_as = 'aside' }) => {
  const intl = useIntl();

  const getPosition = function (geolocation) {
    if (! geolocation ) {
      return null
    }
    return geolocation.split(';').map(parseFloat);
  }

  const geolocation = getPosition(content.geolocation)

  return (
    <Segment
      as='dl'
      className='event-view__summary'
    >
      {content.place_typologies?.length > 0 && (
        <>
          <Header dividing sub as='dt' className='event-view__summary__term' >
            {intl.formatMessage(messages.typologies)}
          </Header>
          <dd>
            {content.place_typologies.map((item) => (
              <div>{item.title}</div>
            ))}
          </dd>
        </>
      )}

      {(content.email || content.telephone) && (
        <>
          <Header dividing sub as='dt' className='event-view__summary__term'>
            {intl.formatMessage(messages.contact)}
          </Header>
          <dd>
            {content.email && (
              <p className='mb-1'>
                <strong>{intl.formatMessage(messages.email)}</strong>:
                <a  className='ms-2' href={`mailto:${content.email}`}>
                  {content.email}
                </a>
              </p>
            )}
            {content.telephone && (
              <p className='mb-0'>
                <strong>{intl.formatMessage(messages.telephone)}</strong>:
                <a className='ms-2' href={'tel:+34' + content.telephone.replace(/ /g, "")}>
                  {content.telephone}
                </a>
              </p>
            )}            
          </dd>
        </>
      )}

      {content.web && (
        <>
          <Header dividing sub as='dt' className='event-view__summary__term'>
            {intl.formatMessage(messages.website)}
          </Header>
          <dd>
            <a
              href={content.web}
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage(messages.website)} <FontAwesomeIcon className='ms-2' icon={faUpRightFromSquare} title={intl.formatMessage(messages.visitWebsite)} />
            </a>
          </dd>
        </>
      )}

      {(content.location || content.geolocation) && (
        <>
          <Header dividing sub as='dt' className='event-view__summary__term' >
            {intl.formatMessage(messages.where)}
          </Header>

          {content.location && (<dd className="my-2"><em>{intl.formatMessage(messages.address)}:</em> {content.location}</dd>)}

          {geolocation && (
            <Map marker={geolocation} center={geolocation} />
          )}

        </>
      )}

    </Segment>
  );
};

export default EventDetails;
