import react, { Fragment } from 'react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Card, Header, Dropdown, Container, Button } from 'semantic-ui-react';
import { UniversalLink, PreviewImage } from '@plone/volto/components';
import { FormattedMessage } from 'react-intl';
import { searchContent } from '@plone/volto/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faRss, faCalendarDay, faCalendarDays, faSpinner} from '@fortawesome/free-solid-svg-icons';
import { flattenToAppURL } from '@plone/volto/helpers';
import { formatDate } from '../../../helpers'
import { useDebounce } from 'use-debounce';

const View = (props) => {
	const { data, mode, content } = props;
	let href = (data.linkHref?.[0]?.['@id']) || '';
	let link = href ? flattenToAppURL(href) : '';
	let today = new Date()

	const dispatch = useDispatch();
	const events = useSelector((state) => state.search.subrequests.events?.items);
	const [dateCalendar, setDateCalendar] = useState(new Date())
	const [dateSelected, setDateSelected] = useState(new Date())
	const [dateDebounce] = useDebounce(dateSelected, 500);
	const [monthName, setMonthName] = useState("");
	const [updating, setUpdating] = useState(true)

	const getDayStatus = (year, month, day) => {
		const inputDate = new Date(year, month, day);
  		const today = new Date();
  		today.setHours(0, 0, 0, 0);
		if (inputDate.getTime() === today.getTime()){
			return 'isToday'
		} else if ( inputDate < today ){
			return 'isPast'
		} else {
			return ''
		}
	};

	const nomDiaSetmana = [
		{ llarg: "Diumenge", curt: "Dg" },
		{ llarg: "Dilluns", curt: "Dl" },
		{ llarg: "Dimarts", curt: "Dt" },
		{ llarg: "Dimecres", curt: "Dc" },
		{ llarg: "Dijous", curt: "Dj" },
		{ llarg: "Divendres", curt: "Dv" },
		{ llarg: "Dissabte", curt: "Ds" }
	];

	const setSetmanaAnterior = () => {
		// setDateCalendar(new Date(dateCalendar.getFullYear(), dateCalendar.getMonth() - 1, 1));
		setDateCalendar(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1));
	};

	const setSetmanaSeguent = () => {
		// setDateCalendar(new Date(dateCalendar.getFullYear(), dateCalendar.getMonth() + 1, 1));
		setDateCalendar(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1));

	};

	const setDataActual = () => {
		setDateCalendar(new Date());
	};

	const generarCalendari = () => {
		const any = dateCalendar.getFullYear();
		const mes = dateCalendar.getMonth();
		const primerDiaDelMes = new Date(any, mes, 1);
		const diaDeLaSetmana = primerDiaDelMes.getDay();
		const diesDelMes = new Date(any, mes + 1, 0).getDate();
		const setmanes = [];
		let setmana = new Array(7).fill(null);
		let dia = 1;
		for (let i = diaDeLaSetmana; i < 7 && dia <= diesDelMes; i++) {
			setmana[i] = dia;
			dia++;
		}
		setmanes.push(setmana);
		while (dia <= diesDelMes) {
			setmana = new Array(7).fill(null);
			for (let i = 0; i < 7 && dia <= diesDelMes; i++) {
				setmana[i] = dia;
				dia++;
			}
			setmanes.push(setmana);
		}
		return setmanes;
	};

	const setmanesCalendari = generarCalendari();

	const getFormatedDate = (date, setHour = 'end') => {
		setHour === 'start' && date.setHours(0, 0, 0, 0) || date.setHours(23, 59, 0, 0)
		const formattedDate = moment(date).format('YYYY/MM/DD HH:mm')
		return formattedDate
	}

	useEffect(() => {
	  setMonthName(dateCalendar.toLocaleDateString("ca-ES", { year: "numeric", month: "long" }));
	}, [dateCalendar]);

	useEffect(() => {
		const eventStart = getFormatedDate(dateDebounce, 'start')
		const eventEnd = getFormatedDate(dateDebounce, 'end')
		let contentFilter = {
			portal_type: ['Event'],
			b_size: 10,
			review_state: 'published',
			sort_on: 'start',
			metadata_fields: ['start', 'end', 'hasPreviewImage', 'event_subjects', 'location']
		};
		contentFilter['start.query'] = [eventEnd];
		contentFilter['end.query'] = [eventStart];
		contentFilter['start.range'] = 'max';
		contentFilter['end.range'] = 'min';

		dispatch(searchContent(link, contentFilter, 'events')).then(()=> setUpdating(false));
	}, [dispatch, dateDebounce]);

	const getEventDate = function (start) {
		const startDay = formatDate(start)
		let date = ''
		const optionsFullTime = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
		const optionsTime = { hour: '2-digit', minute: '2-digit' };
		date = formatDate(start, optionsFullTime);
		return date
	}

	const updateEventsDay = (day) => {
		setUpdating(true)
		let selected = new Date(dateCalendar)
		selected.setDate(day)
		setDateSelected(selected)
	}

	return (
		<div className="full-width calendar__wrapper py-5">
			<Segment as="section" className="calendar px-0" basic>
				<Container>
					{data?.title ? (
						<div className="calendar__title">
							<Header as="h2">{data.title}</Header>
							{showRss && (
								<UniversalLink href="#" className="calendar__rss">
									<FontAwesomeIcon icon={faRss} />
									<abbr className='' title='Really Simple Syndication' lang="en">RSS</abbr>
								</UniversalLink>
							)}

						</div>
					) : (
						<Header as="h2" className='justify-content-center fs-3 text-center mb-5'><FormattedMessage id="Agenda" defaultMessage="Agenda" /></Header>
					)}
					<nav className='text-center calendarList'>
						<div className="d-lg-flex align-items-center justify-content-center gap-4">
							<h3 className='calendarList__month fs-6 mb-2 my-lg-0'>{monthName}</h3>
							<div className="d-flex justify-content-center gap-3">
								<Button className='py-2' size='small' basic onClick={setSetmanaAnterior}><FontAwesomeIcon icon={faAngleLeft} className=" me-3" /> Anterior</Button>
								<Button className='py-2' size='small' basic onClick={setDataActual}>Avui <FontAwesomeIcon icon={faCalendarDay} className=" ms-3" /></Button>
								<Button className='py-2' size='small' basic onClick={setSetmanaSeguent}>Següent <FontAwesomeIcon icon={faAngleRight} className=" ms-3" /></Button>
							</div>
						</div>
						<ul className='list-unstyled calendarList__days'>

							{setmanesCalendari.map((setmana, index) => (
								<Fragment key={index} >
									{setmana.map((dia, i) => (
										<Fragment key={i}>
											{dia &&
												<li className='calendarList__dropdown ui dropdown'>

													<button 
														onFocus={() => updateEventsDay(dia)}
														onMouseEnter={() => updateEventsDay(dia)}
														className={
															`calendarList__link ${getDayStatus(dateCalendar.getFullYear(), dateCalendar.getMonth(), dia)}`
														}
													>
														<abbr className='d-block' title={nomDiaSetmana[i].llarg}>{nomDiaSetmana[i].curt}</abbr><span className='d-block'>{dia}</span>
													</button>
													<ul className={'list-unstyled menu transition'} >
														{
															updating ? (
																<li className='item' key="no-events">
																	
																</li>
															) : (
																events && events.length > 0 ? (
																	events.map((event, key) => (
																		<li className='item '  key={key}>
																			<Card as="article" className="calendarItem position-relative " fluid>
																				<div className="calendarItem__img__wrapper ">
																					<PreviewImage
																						item={event}
																						alt={event.image_caption ? event.image_caption : ''}
																						className="calendarItem__img m-0 image "
																						sizes="20rem"
																					/>
																				</div>
																				<Card.Content className="calendarItem__text">
																					<Card.Header as="h3" className="calendarItem__title">
																						<UniversalLink href={flattenToAppURL(event['@id'])} className="calendarItem__link stretched-link">
																							{event.title}
																						</UniversalLink>
																					</Card.Header>
																					<time className="calendarItem__date mb-2">
																						<FontAwesomeIcon className="calendarItem__icon me-2" icon={faCalendarDays} />
																						{getEventDate(event.start)}
																					</time>
																				</Card.Content>
																			</Card>
																		</li>
																	))
																) : (
																	<li  className='item ' key="no-events">
																		<Card as="article" className="calendarItem position-relative " fluid>
																			<Card.Content className="calendarItem__text">
																				<Card.Header as="h3" className="calendarItem__title">
																					<p>No hi han esdeveniments</p>
																				</Card.Header>
																			</Card.Content>
																		</Card>
																	</li>
																)
															)
														}
													</ul>

												</li>
											}
										</Fragment>
									))}
								</Fragment>
							))}
						</ul>
					</nav>
				</Container>
			</Segment>
		</div>
	);
};

export default View;
