import { defineMessages } from 'react-intl';

const messages = defineMessages({
    title: {
      id: 'Title',
      defaultMessage: 'Title',
    },
    telephone: {
      id: 'Telephone',
      defaultMessage: 'Telephone',
    },
    telephones: {
      id: 'Telephones',
      defaultMessage: 'Telephones',
    },
    addTelephone: {
      id: 'Add Telephone',
      defaultMessage: 'Add Telephone',
    },
  });

const telephoneSchema = (props) => ({
  title: 'Item',
  addMessage: props.intl.formatMessage(messages.addTelephone),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'telephone'],
    },
  ],
  properties: {
    title: {
      title: props.intl.formatMessage(messages.title),
      widget: 'text',
    },
    telephone: {
      title: props.intl.formatMessage(messages.telephone),
      widget: 'text',
    },
  },
  required: ['title', 'telephone'],
});

export const schema = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'telephones'],
      },
    ],
    properties: {
      telephones: {
        title: props.intl.formatMessage(messages.telephones),
        description: props.intl.formatMessage(messages.addTelephone),
        schema: telephoneSchema(props),
        widget: 'object_list',
      },   
    },
  };
};

export default schema;