/**
 * Weather reducer.
 * @module reducers/weather
 */

import {
  GET_WEATHER
} from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: {},
};

export default function weather(state = initialState, action = {}) {
  switch (action?.type) {
    case `${GET_WEATHER}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_WEATHER}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };

    case `${GET_WEATHER}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        data: action.result,
      };
    default:
      return state;
  }
};