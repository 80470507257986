import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  socialNetworks: {
    id: 'SocialNetworks',
    defaultMessage: 'Social Networks',
  },
  addSocialNetwork: {
    id: 'AddSocialNetwork',
    defaultMessage: 'Add Social Network',
  },
  socialNetworkType: {
    id: 'SocialNetworkType',
    defaultMessage: 'Social Network Type',
  },
  socialNetworkUrl: {
    id: 'SocialNetworkUrl',
    defaultMessage: 'Social Network URL',
  },
});

const socialNetworkSchema = (props) => ({
  title: 'Social Network',
  addMessage: props.intl.formatMessage(messages.addSocialNetwork),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['type', 'url'],
    },
  ],
  properties: {
    type: {
      title: props.intl.formatMessage(messages.socialNetworkType),
      widget: 'text', 
    },
    url: {
      title: props.intl.formatMessage(messages.socialNetworkUrl),
      widget: 'url', 
    },
  },
  required: ['type', 'url'],
});

export const schema = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title','socialNetworks'],
      },
      {
        id: 'socialNetworks',
        title: props.intl.formatMessage(messages.socialNetworks),
        fields: ['socialNetworks'],
      },
    ],
    properties: {
      title: {
        title: props.intl.formatMessage(messages.title),
        widget: 'text',
      },
      socialNetworks: {
        title: props.intl.formatMessage(messages.socialNetworks),
        description: props.intl.formatMessage(messages.addSocialNetwork),
        schema: socialNetworkSchema(props),
        widget: 'object_list', 
      },
    },
  };
};

export default schema;