import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClusterMap } from '../';
import { searchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Form, Container, Header } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { map } from 'lodash';

const EquipmentsMapListing = (props) => {
	const dispatch = useDispatch();
	const intl = useIntl();
	const equipments = useSelector((state) => state.search.subrequests?.equipments?.items);
	const [category, setCategory] = useState('');
	
	const vocabularyTypologies = 'product.matadepera.vocabularies.PlaceTypologies';

  const itemsTypologies = useSelector(
    (state) =>
      state.vocabularies[vocabularyTypologies] && state.vocabularies[vocabularyTypologies].items
        ? [{ value: '', label: intl.formatMessage({ id: 'All typologies', defaultMessage: 'All typologies' }) }, ...state.vocabularies[vocabularyTypologies].items]
        : [],
  );

	useEffect(() => {
		const contentFilter = {
			portal_type: 'Place',
			review_state: 'published',
			sort_on: 'sortable_title',
			metadata_fields: ['geolocation', 'location', 'place_typologies'],
		};

		if (category) {
			contentFilter.place_typologies = category;
		}

		dispatch(
			searchContent(
				flattenToAppURL(props.content['@id']),
				contentFilter,
				'equipments'
			)
		);
	}, [dispatch, category]);


	const onCategoryChange = (event, data) => {
		setCategory(data.value);
	};

	const equipmentsWithGeolocation = equipments.filter(equipment => equipment.geolocation);

	return (
		<div className="equipmentsMapListing">
			<Form className="searchList">
				<Container
					className="filters-container mt-4">
						<Header as="h2" className='justify-content-center searchList__title' size='small' textAlign='center'><FormattedMessage id="Filter" defaultMessage="Filter" /></Header>
					<Form.Group widths="equal">
						<Form.Select
							fluid
							label={intl.formatMessage({ id: 'By typology', defaultMessage: 'By typology' })}
							options={map(itemsTypologies, (item, index) => ({
								key: index,
								value: item.value,
								text: item.label,
							}))}
							value={category}
							onChange={onCategoryChange}
							placeholder={intl.formatMessage({ id: 'All typologies', defaultMessage: 'All typologies' })}
						/>
					</Form.Group>
				</Container>
			</Form>
			<Container>
				<ClusterMap className="equipmentsMapListing__map" markers={equipmentsWithGeolocation} />

			</Container>
		</div>
	);
};

export default EquipmentsMapListing;
