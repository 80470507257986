/**
 * Search component.
 * @module components/Search/Search
 */

import React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Button, Container, Segment, List, Label, Grid, Card, Form, Header, Message } from 'semantic-ui-react';
import { BodyClass, Helmet, hasBlocksData } from '@plone/volto/helpers';
import { UniversalLink, Pagination, Image, FormattedDate } from '@plone/volto/components';
import { searchContent, getVocabulary } from '@plone/volto/actions';
import { flattenToAppURL, addAppURL } from '@plone/volto/helpers';
import { keys, isEmpty, map, filter } from 'lodash';
import { useDebounce } from "use-debounce";
import classNames from 'classnames';
import qs from 'query-string';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';


const messages = defineMessages({
  Search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  labelText: {
    id: 'Cercar',
    defaultMessage: 'Cercar',
  },
  placeholderText: {
    id: 'What are you looking for?',
    defaultMessage: 'What are you looking for?',
  },
});


const Search = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const results = useSelector((state) => state.search.subrequests.results?.items);
  const total = useSelector((state) => state.search.subrequests.results?.total);
  const navroot = useSelector((state) => state.navroot?.data);
  const navRootPath = flattenToAppURL(navroot?.navroot?.['@id']) || '';
  const language = useSelector((state) => state.intl.locale);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(0);
  const [sortOn, setSortOn] = useState('relevance');
  const [sortOrder, setSortOrder] = useState('ascending');
  const [searchableTextInput, setSearchableText] = useState('');
  const [initialSearch, setInitialSearch] = useState(true);
  const [searchableText] = useDebounce(searchableTextInput, 1000);
  const [portalTypes, setPortalTypes] = useState([]);

  const documentFirstHeading = props.searchableText ? (
    <FormattedMessage
      id="Search results for {term}"
      defaultMessage="Search results for {term}"
      values={{
        term: <q>{props.searchableText}</q>,
      }}
    />
  ) : (
    <FormattedMessage
      id="Search results"
      defaultMessage="Search results"
    />
  );

  const vocabularyPortalTypes = 'plone.app.vocabularies.UserFriendlyTypes';
  const disabledPortalTypes = ['LRF', 'LIF', 'Image', 'Banner', 'Slide', 'Announcement', 'Collection'];
  const defaultPortalTypes = ['Event', 'File', 'Folder', 'Industry', 'Instrument', 'Landing', 'Link', 'News Item', 'Document'];
  const itemPortalTypes = useSelector(
    (state) =>
      state.vocabularies[vocabularyPortalTypes] && state.vocabularies[vocabularyPortalTypes].items
        ? filter(state.vocabularies[vocabularyPortalTypes].items, (item) => !disabledPortalTypes.includes(item.value))
        : [],
    shallowEqual,
  );
  const allPortalTypes = !isEmpty(itemPortalTypes) ? itemPortalTypes.map((item, index) => item.value) : defaultPortalTypes;


  useEffect(() => {
    dispatch(getVocabulary({ vocabNameOrURL: vocabularyPortalTypes, lang: language }));
  }, [dispatch]);

  useEffect(() => {
    const input = qs.parse(props.history.location.search).SearchableText
    setSearchableText(input ? input : '')
  }, [location.search]);

  useEffect(() => {
    if (initialSearch) {
      setInitialSearch(false)
      if (qs.parse(props.history.location.search).SearchableText !== searchableText) {
        const input = qs.parse(props.history.location.search).SearchableText
        setSearchableText(input ? input : '')
        return
      }
    }

    let contentFilter = {
      b_size: pageSize,
      b_start: currentPage * pageSize,
      SearchableText: searchableText,
      sort_on: sortOn,
      sort_order: sortOrder,
      metadata_fields: ['effective', 'review_state']
    }

    if (!isEmpty(portalTypes)) {
      contentFilter.portal_type = portalTypes
    } else {
      contentFilter.portal_type = allPortalTypes
    }

    dispatch(
      searchContent(
        flattenToAppURL(navroot.navroot['@id']),
        contentFilter,
        'results',
      ),
    );
    history.push(`${navRootPath}/search?SearchableText=${encodeURIComponent(searchableText)}`);
  }, [dispatch, currentPage, pageSize, searchableText, portalTypes, sortOn, sortOrder, search]);

  // useEffect(() => {
  //   history.push(`${navRootPath}/search?SearchableText=${encodeURIComponent(searchableText)}`);
  //   setCurrentPage(0);
  // }, [searchableText]);

  const onChangePage = (event) => {
    event.preventDefault();
    setCurrentPage(parseInt(event.target.text) - 1);
  };
  const onChangePageSize = (event) => {
    event.preventDefault();
    setPageSize(parseInt(event.target.text));
    setCurrentPage(0);
  };

  const onChangeSearchableText = (event, data) => {
    event.preventDefault();
    setSearchableText(data.value);
  };

  const onChangePortalTypes = (event, data) => {
    if (data.checked) {
      setPortalTypes(portalTypes => [...portalTypes, data.name])
    } else {
      setPortalTypes(portalTypes => [...portalTypes.filter((item) => item != data.name)])
    }
    setCurrentPage(0);
  };

  const onChangeSort = (event, data) => {
    setSortOn(data.name)
    if (data.name === 'effective') {
      setSortOrder('reverse')
    } else {
      setSortOrder('ascending')
    }
    setCurrentPage(0);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setSearch(search + 1);
    setCurrentPage(0);
  };

  return (
    <>
      <BodyClass className='searchlisting headerNegativeBottom' />
      <Helmet title={intl.formatMessage(messages.Search)} />

      <Container className="searchResults">
        <Form className="searchResults__form d-print-none   " onSubmit={onSubmit}>
          <Form.Group >
            <Label className='searchResults__form__label' as="label" htmlFor="textInputId">{intl.formatMessage(messages.labelText)}</Label>
            <div className="searchResults__form__wrapper">
              <Form.Input
                id="textInputId"
                focus
                name="searchableText"
                value={searchableTextInput}
                onChange={onChangeSearchableText}
                placeholder={intl.formatMessage(messages.placeholderText)}
                type="search"
                className='searchResults__form__input'
              />
              <Form.Button className="searchResults__form__btn" primary>
                <FontAwesomeIcon icon={faMagnifyingGlass} className='me-3' />
                <FormattedMessage id="Search" defaultMessage="Search" />
              </Form.Button>
            </div>
          </Form.Group>
        </Form>
              <Header as="h1" size="large" className="h2 text-primary border-bottom pb-3 justify-content-center">
                <FormattedMessage id="Search results" defaultMessage="Search results" />
              </Header>
        <Grid >
          <Grid.Row >
            <Grid.Column mobile={12} tablet={4} computer={4} largeScreen={3} widescreen={3} className='mb-5 d-print-none'>
              <Form className="searchListFacets" onSubmit={onSubmit} >
                {!isEmpty(itemPortalTypes) && (
                  <>
                    <Header as="h2" size="medium" className="h3 ">
                      <FormattedMessage id="Types" defaultMessage="Types" />
                    </Header>
                    {itemPortalTypes.map((item, index) => (
                      <Form.Checkbox
                        key={index}
                        id={`portalType-${index}`}
                        label={item.label}
                        name={item.value}
                        checked={portalTypes.includes(item.value)}
                        onChange={onChangePortalTypes}
                      />

                    ))}
                  </>
                )}
              </Form>
            </Grid.Column>

            <Grid.Column mobile={12} tablet={8} computer={8} largeScreen={9} widescreen={9}>

              {!isEmpty(results) ? (
                <>
                  <div className="items_total sectionSort d-print-none">
                    <span className="sectionSort__total">
                      {total}{' '}
                      <FormattedMessage
                        id="results found"
                        defaultMessage="results"
                      />
                    </span>

                    <div className="d-flex flex-wrap align-items-center">
                      <span className="sort-by text-primary text-uppercase fw-bold">
                        <FormattedMessage
                          id="Sort By:"
                          defaultMessage="Sort by:"
                        />
                      </span>
                      <Button.Group>

                        <Button
                          onClick={onChangeSort}
                          name="relevance"
                          size="tiny"
                          className={classNames('button-sort', {
                            'button-active': sortOn === 'relevance',
                          })}
                        >
                          <FormattedMessage
                            id="Relevance"
                            defaultMessage="Relevance"
                          />
                        </Button>
                        <Button
                          onClick={onChangeSort}
                          name="sortable_title"
                          size="tiny"
                          className={classNames('button-sort', {
                            'button-active': sortOn === 'sortable_title',
                          })}
                        >
                          <FormattedMessage
                            id="Alphabetically"
                            defaultMessage="Alphabetically"
                          />
                        </Button>
                        <Button
                          onClick={onChangeSort}
                          name="effective"
                          size="tiny"
                          className={classNames('button-sort', {
                            'button-active': sortOn === 'effective',
                          })}
                        >
                          <FormattedMessage
                            id="Date (newest first)"
                            defaultMessage="Date (newest first)"
                          />
                        </Button>
                      </Button.Group>
                    </div>
                  </div>

                  <Card.Group className="results0">
                    {results.map((item) => (
                      <Card as="article" fluid className="searchResultItem position-relative" key={item['@id']} >
                        <Card.Content>
                          <Card.Header as="h2" className="searchResultItem__title h3">
                            <UniversalLink
                              className="searchResultItem__link stretched-link"
                              href={flattenToAppURL(item['@id'])}>
                              {item.title}
                            </UniversalLink>
                          </Card.Header>
                          <Card.Meta>
                            <p className="searchResultItem__path">{addAppURL(item['@id'])}</p>
                          </Card.Meta>
                          {item?.description && (
                            <Card.Description>
                              <p className="searchResultsItem__desc">{item.description}</p>
                            </Card.Description>
                          )}
                        </Card.Content>
                        <Card.Content extra className='fs-sm px-3'>
                          <FormattedMessage id="Data de publicació" defaultMessage="Data de publicació" />: 
                          {(item?.review_state === 'published' && item?.effective) && (
                            <time dateTime={item.effective} className='ms-2 '>
                              <FormattedDate date={item.effective} className=""
                                format={{
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                }}
                              />
                            </time>
                          )}
                        </Card.Content>
                      </Card>

                    ))}
                  </Card.Group>

                  {total > pageSize && (
                    <nav className="pagination d-print-none">
                      <Pagination
                        current={currentPage}
                        total={Math.ceil(total / pageSize)}
                        onChangePage={onChangePage}
                        onChangePageSize={onChangePageSize}
                      />
                    </nav>
                  )}
                </>
              ) : (
                <Message className="results">
                  <FormattedMessage id="No results found" defaultMessage="No results found" />
                </Message>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

    </>
  );
};


export default Search;
