import weather from './weather/weather';
import air_quality from './air_quality/air_quality';
import importTramits from './importTramits/importTramits';
import searchTramits from './searchTramits/searchTramits';
import header_info from './header_info/header_info';

const reducers = {
    weather,
    air_quality,
    importTramits,
    searchTramits,
    header_info
};

export default reducers;