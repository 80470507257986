import { Segment, Header, Button, Input, Container } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { map, isEmpty } from 'lodash';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { searchTramits } from '../../../actions';
import { useEffect, useState } from 'react';
import { useDebounce } from "use-debounce";


// TRANSLATE
const messages = defineMessages({
	labelText: {
		id: 'Cerca el tràmit',
		defaultMessage: 'Cerca el tràmit',
	},
	searchPlaceholder: {
		id: 'Introdueix un terme per a la cerca',
		defaultMessage: 'Introdueix un terme per a la cerca',
	},
});

const View = (props) => {
	const { data, isEditMode } = props;
	const dispatch = useDispatch();
	const [searchTerm, setSearchTerm] = useState('');
	const [searchableText] = useDebounce(searchTerm, 500);
	const tramits = useSelector((state) => state.searchTramits?.data, shallowEqual);

	useEffect(() => {
		if (searchableText) {
		  	dispatch(searchTramits({ searchableText: searchableText}));
		} else if (data.category) {
			dispatch(searchTramits({category: data.category}));
		}
	 }, [dispatch, searchableText]);

	const handleSearchChange = (e) => {
		if(e.target.value && e.target.value.length >= 3){
			setSearchTerm(e.target.value);
		} else {
			setSearchTerm()
		}
	};

	return (
	<div className={"secTramits__wrapper d-print-none " + (!isEditMode ? 'full-width' : '')}>
		<Segment as="section" className="secTramits" basic>
			<Container>
				{data?.title ? (
					<Header as="h2" inverted textAlign="center" className="secTramits__title">
						{data.title}
					</Header>
				) : (
					<Header className="visually-hidden" as="h2">
						<FormattedMessage id="Tràmits" defaultMessage="Tràmits" />
					</Header>
				)}

				<div className="secTramits__search">
					<label htmlFor="tramits-search-box" className="visually-hidden">
						Cerca el tràmit
					</label>
					<Input
						className="secTramits__search__input"
						type="search"
						inverted
						id="tramits-search-box"
						placeholder="Introdueix un terme per a la cerca"
						value={searchTerm}
						onChange={handleSearchChange} 
					/>
					<Button className="secTramits__search__btn" as="button" primary >
						<span className="visually-hidden">
							<FormattedMessage id="Cercar" defaultMessage="Cercar" />
						</span>
						<FontAwesomeIcon icon={faMagnifyingGlass} />
					</Button>
				</div>

				{!isEmpty(tramits) && (
            		<ul className="secTramits__list">
              			{map(tramits, (item, index) => (
                			<li key={index}>
                  				<UniversalLink href={item.url} className="secTramits__link">
                    				{item.title}
                  				</UniversalLink>
                			</li>
              			))}
            		</ul>
          		)}


				<p className="mb-4 text-center">La Carpeta Ciutadana és un servei de l'Ajuntament de Matadepera que permet a qualsevol ciutadà visualitzar la informació referida a les tramitacions i sol·licituds que ha realitzat electrònicament, l’expedient relacionat i el seu estat.</p>

				<p className="text-center">
					<UniversalLink
						href="https://bpm.matadepera.cat/OAC/SegExpStandalone.jsp?idioma=ca&_UNITREE=CARP&ddlo=true&"
						className="ui primary button secTramits__btnCarpeta"
					>
						<FormattedMessage id="Carpeta ciutadana" defaultMessage="Carpeta ciutadana" />
						<FontAwesomeIcon icon={faFolderOpen} className="ms-3" />
					</UniversalLink>
				</p>
			</Container>
		</Segment>
	</div>
	);
};


export default View;
