/**
 * Frontpage component.
 * @module components/Frontpage/Frontpage
 */
import React from 'react';
import { BodyClass, hasBlocksData } from '@plone/volto/helpers';
import { useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import { FormattedMessage, defineMessages } from 'react-intl';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';


const Frontpage = (props) => {
	const { content } = props;

	return (
		<>

			<BodyClass className='frontpage noBreadcrumbs' />

			{/* Volto Blocks */}
			{hasBlocksData(content) ? (
				<Container className="userBlocks">
					<RenderBlocks {...props} />
				</Container>
			) : null}

		</>
	);
};

export default Frontpage;