import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { Menu } from 'semantic-ui-react';

import cx from 'classnames';
import { BodyClass, getBaseUrl, hasApiExpander, addAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { getNavigation } from '@plone/volto/actions';
import { CSSTransition } from 'react-transition-group';
import NavItems from '@plone/volto/components/theme/Navigation/NavItems';


const messages = defineMessages({
    closeMobileMenu: {
        id: 'Close menu',
        defaultMessage: 'Close menu',
    },
    openMobileMenu: {
        id: 'Open menu',
        defaultMessage: 'Open menu',
    },
    Site: {
        id: 'Lloc web',
        defaultMessage: 'Lloc web'
    }
});


const useIsMobile = () => {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    setIsMobile(/android|iphone|ipad|ipod/i.test(userAgent));
  }, []);

  return isMobile;
};


const Navigation = (props) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { pathname, type } = props;
    const [isMobileMenuOpen, setisMobileMenuOpen] = useState(false);
    const isMobile = useIsMobile();
    const token = useSelector((state) => state.userSession.token, shallowEqual);
    const items = useSelector((state) => state.navigation.items, shallowEqual);
    const lang = useSelector((state) => state.intl.locale);
    // URL for content sharing
    const content = useSelector((state) => state.content.data);
    const url = content ? addAppURL(content?.['@id'] || '/') : null;

    useEffect(() => {
        const { settings } = config;
        if (!hasApiExpander('navigation', getBaseUrl(pathname))) {
            dispatch(getNavigation(getBaseUrl(pathname), settings.navDepth));
        }
    }, [pathname, token, dispatch]);

    const toggleMobileMenu = () => {
        setisMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        if (!isMobileMenuOpen) {
            return;
        }
        setisMobileMenuOpen(false);
    };
    return (
        <nav className="navigation d-print-none" id="navigation" aria-label={intl.formatMessage(messages.Site)} tabIndex="-1">            

            <Menu
                className=" "
                onClick={closeMobileMenu}
            >
                <NavItems items={items} lang={lang}  mobile={isMobile} />
            </Menu>
            
        </nav>
    );
};

Navigation.propTypes = {
    pathname: PropTypes.string.isRequired,
};

export default Navigation;
