/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, Button, Segment } from 'semantic-ui-react';
import { map } from 'lodash';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL, addAppURL } from '@plone/volto/helpers';
import imgLogoFooter from './img/escut-aj-matadepera-vertical.svg';
import imgEcityclic from './img/logo-ecityclic.svg';
import imgMuseu from './img/museu.svg';
import imgTelefon from './img/telefon.svg';
import imgUbicacio from './img/ubicacio.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faXTwitter, faWhatsapp, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Banners } from './';
import { useGoogleAnalytics } from '../../helpers/useGoogleAnalytics';
import {
  usePanelConfigAndPreferences,
  GDPRCookies,
  getLocaleConf, 
} from 'volto-gdpr-privacy/helpers';

const socialIcons = {
	facebook: faFacebookF,
	twitter: faXTwitter,
	youtube: faYoutube,
	whatsapp: faWhatsapp,
	instagram: faInstagram
}


const Footer = ({ intl }) => {
	const { siteActions = [] } = useSelector(
		(state) => ({
			siteActions: state.actions?.actions?.site_actions,
		}),
		shallowEqual,
	);

	 const { socialActions = [] } = useSelector(
	    (state) => ({
	      socialActions: state.actions?.actions?.social_actions,
	    }),
	    shallowEqual,
	 );

	const today = new Date();

  const token = useSelector((state) => state.userSession.token, shallowEqual);
	const cookies = new GDPRCookies();
	const { defaultPreferences } = usePanelConfigAndPreferences(cookies);
	const gdprPreferences = useSelector(
  	(state) => state.gdprPrivacyConsent.preferences ?? defaultPreferences,
	);

	useGoogleAnalytics(gdprPreferences?.GANALYTICS, token)

	return (
		<footer id="footer" >

			<Banners />

			<div className="portalFooter d-print-none">

				<div className="footerLogo__wrapper">
					<img className='footerLogo__img' src={imgLogoFooter} alt="Ajuntament de Matadepera." />
					{socialActions?.length &&
						<ul className="footerXXSS list-unstyled">
							{map(socialActions, (item) => (
								<li key={item.id}>
									<a className="footerXXSS__link" href={item.url}>
										<FontAwesomeIcon icon={socialIcons[item.id]} className='footerXXSS__icon' />
										<span className="visually-hidden">{item?.title}</span>
									</a>
								</li>
							))
							}
						</ul>
					}

				</div>

				<Segment basic className='footerInfo'>
					<p className="footerInfo__item">
						<img src={imgUbicacio} alt="" className="footerInfo__item__img" width="40" height="40" />
						<span className="footerInfo__item__txt">Pl. Ajuntament, 1 <br />08230 Matadepera</span>
					</p>
					<p className="footerInfo__item">
						<img src={imgTelefon} alt="" className="footerInfo__item__img" width="38" height="38" />
						<a href="tel:+34937870200" className="footerInfo__item__txt">93 787 02 00</a>
					</p>
					<p className="footerInfo__item">
						<img src={imgMuseu} alt="" className="footerInfo__item__img" width="40" height="40" />
						<span className="footerInfo__item__txt">P0811900J</span>
					</p>
					<div className='footerNewsletter'>
						<p><FormattedMessage id="Estigues sempre informat mitjançant el nostre servei de butlletins informatius." defaultMessage='Estigues sempre informat mitjançant el nostre servei de butlletins informatius.' /></p>
						<UniversalLink className='ui button footerNewsletter__btn' href="http://eepurl.com/i6WiP6">
							<FormattedMessage id="Subscriu-me" defaultMessage='Subscriu-me' />
						</UniversalLink>						
					</div>
				</Segment>

				<div className="footerNav">

					<Container className="footerNav__container">

						{siteActions?.length &&
							<ul className='footerNav__menu list-unstyled'>
								{map(siteActions, (item) => (
									<li className="item" key={item.id}>
										<UniversalLink
											className="item"
											href={
												item.url ? flattenToAppURL(item.url) : addAppURL(item.id)
											}
										>
											{item?.title}
										</UniversalLink>
									</li>
								))}
							</ul>
						}

						<p className='footerCopy'>
							<span>&copy; {today.getFullYear()} Ajuntament de Matadepera</span>
							<span className='d-flex align-content-center'>
								<FormattedMessage id="Project developed by" defaultMessage="Project developed by" /> <a className='ms-2' href="https://ecityclic.com"><img src={imgEcityclic} alt="ecityclic." /></a>
							</span>
						</p>
					</Container>

				</div>

			</div>
		</footer>
	);
};


export default Footer;