import { Fragment, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Container as SemanticContainer,
  GridColumn,
  Segment,
  Card,
  Header
} from 'semantic-ui-react';
import { Button, Modal, Grid } from 'semantic-ui-react';
import { Icon, UniversalLink, Pagination } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { searchContent } from '@plone/volto/actions';
import config from '@plone/volto/registry';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import prettybytes from 'pretty-bytes';


const AlbumView = ({ content }) => {
  const [openIndex, setOpenIndex] = useState(undefined);

  const closeModal = () => {
    setOpenIndex(-1);
  };

  const nextImage = () => {
    let OpenIndex = (openIndex + 1) % content.items.length;
    setOpenIndex(OpenIndex);
  };
  const prevImage = () => {
    const OpenIndex = (openIndex - 1) % content.items.length;
    setOpenIndex(OpenIndex);
  };
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;
  const PreviewImage = config.getComponent({ name: 'PreviewImage' }).component;

  const dispatch = useDispatch();
  const items = useSelector((state) => state.search.subrequests.items?.items);
  const total = useSelector((state) => state.search.subrequests.items?.total);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    let contentFilter = {
      b_size: pageSize,
      b_start: currentPage * pageSize,
      sort_on: 'getObjPositionInParent',
    };
    contentFilter['path.depth'] = 1
    dispatch(searchContent(flattenToAppURL(content['@id']), contentFilter, 'items'));
  }, [dispatch, currentPage, pageSize]);

  const onChangePage = (event, activePage) => {
    event.preventDefault();
    setCurrentPage(activePage.value);
  };

  const onChangePageSize = (event) => {
    event.preventDefault();
    setPageSize(parseInt(event.target.text));
    setCurrentPage(0);
  };

  return (
    <Container className="view-wrapper " >
      <Grid doubling stackable columns={4} centered className='mb-4' >

        {items &&
          items.map((item, index) => (
            <Fragment key={item.url}>
              {item.image_field && (
                <Modal
                  className="gallery galleryModal"
                  onClose={closeModal}
                  open={openIndex === index}
                  trigger={
                    <Grid.Column>
                      <Card className=" albumItem" as="figure" fluid>
                        <PreviewImage
                          item={item}
                          alt={item.image_caption || item.title}
                          onClick={() => {
                            setOpenIndex(index);
                          }}
                          className="ui middle aligned image ratio-16x9 ratio"
                          responsive={true}
                          loading="lazy"
                          tabIndex="0"
                        />
                      </Card>
                    </Grid.Column>
                  }
                  closeIcon
                >
                  <Modal.Header>
                    <Header as="h2" className='fs-4'>{item.title}</Header>
                  </Modal.Header>
                  <Segment basic className='mt-0'>
                    <Button
                      className="gallery  galleryModal__btn galleryModal__btn--prev"
                      onClick={prevImage}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                      <span className="visually-hidden"><FormattedMessage id="Previous" defaultMessage="Previous" /></span>
                    </Button>
                    <Modal.Content image as="figure">
                      <PreviewImage
                        item={item}
                        alt={item.image_caption || item.title}
                        onClick={() => {
                          setOpenIndex(index);
                        }}
                        className="ui image galleryModal__image"
                        responsive={true}
                      />
                      <Modal.Description as="figcaption">
                        {item?.description && <p>{item.description}</p> }
                          <p>
                            <a className='button ui primary' href={item['@id'] + '/' + flattenToAppURL(item?.image_scales?.image[0].download)} 
                              download={item?.image_scales?.image[0].filename}
                            >
                              <FontAwesomeIcon className='me-2' icon={faDownload} /> 
                              <FormattedMessage id="Descarregar imatge" defaultMessage="Descarregar imatge" />
                              &nbsp; &mdash; &nbsp;
                              { prettybytes(item?.image_scales?.image[0].size) }
                            </a>
                          </p>
                        </Modal.Description>                      
                    </Modal.Content>
                    <Button
                      onClick={nextImage}
                      className="gallery  galleryModal__btn galleryModal__btn--next"
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                      <span className="visually-hidden"><FormattedMessage id="Next" defaultMessage="Next" /></span>
                    </Button>
                  </Segment>
                </Modal>
              )}
            </Fragment>
          ))}
      </Grid>

      {total > pageSize && (
        <nav className="pagination">
          <Pagination
            current={currentPage}
            total={Math.ceil(total / pageSize)}
            onChangePage={onChangePage}
            onChangePageSize={onChangePageSize}
          />
        </nav>
      )}
    </Container>
  );
};

AlbumView.propTypes = {
  /**
   * Content of the object
   */
  content: PropTypes.shape({
    /**
     * Title of the object
     */
    title: PropTypes.string,
    /**
     * Description of the object
     */
    description: PropTypes.string,
    /**
     * Child items of the object
     */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Title of the item
         */
        title: PropTypes.string,
        /**
         * Description of the item
         */
        description: PropTypes.string,
        /**
         * Url of the item
         */
        url: PropTypes.string,
        /**
         * Image of the item
         */
        image: PropTypes.object,
        /**
         * Image caption of the item
         */
        image_caption: PropTypes.string,
        /**
         * Type of the item
         */
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default AlbumView;
