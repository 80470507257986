import React from 'react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Segment, Card, Header, Grid, Label, Button, Container } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getVocabulary } from '@plone/volto/actions';
import { UniversalLink, PreviewImage } from '@plone/volto/components';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faRss, faCircleCheck, faChevronRight, faCalendarDays, faLocationDot, faTag } from '@fortawesome/free-solid-svg-icons';
import { keys, isEmpty, map } from 'lodash';
import { formatDate } from '../../../helpers'


const EventsListing = ({ items, linkTitle, linkHref, isEditMode, blockTitle }) => {

	let href = (linkHref?.[0]?.['@id']) || '';
	let link = href ? flattenToAppURL(href) : '';

	const vocabularySubjects = 'plone.app.vocabularies.Keywords';
	const dispatch = useDispatch();
	const itemsSubjects = useSelector(
		(state) =>
			state.vocabularies[vocabularySubjects] && state.vocabularies[vocabularySubjects].items
				? [...state.vocabularies[vocabularySubjects].items]
				: [],
		shallowEqual,
	);

	useEffect(() => {
		dispatch(getVocabulary({ vocabNameOrURL: vocabularySubjects }));
	}, [dispatch]);

	const getEventSubject = function (subject) {
		if (!itemsSubjects) {
			return subject
		}
		for (let item of itemsSubjects) {
			if (item.value === subject) return item.label
		}
		return subject
	}

	const getEventDate = function (start) {
		const startDay = formatDate(start)
		// const endDay = formatDate(end)

		let date = ''
		const optionsFullTime = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
		const optionsTime = { hour: '2-digit', minute: '2-digit' };

		// if (startDay === endDay) {
		// 	date = formatDate(start, optionsFullTime) + ' - ' + formatDate(end, optionsTime)
		// } else {
		// 	date = formatDate(start, optionsFullTime) + ' - ' + formatDate(end, optionsFullTime)
		// }
		date = formatDate(start, optionsFullTime);
		return date
	}

	return (
		<>
			{blockTitle && <h3 className="directLinks__title mt-0">{blockTitle}</h3>}
			<Grid columns={12}>
				{items.map((item) => (
					<Grid.Column mobile={12} tablet={6} computer={4} widescreen={4} key={item['@id']} >

						<Card fluid as="article" className="eventsItem position-relative">
							{item.image_field && (
								<PreviewImage
									item={item}
									alt={item.image_caption ? item.image_caption : ''}
									className="eventsItem__img m-0 image"
									sizes="(max-width: 767px) 100vw, (max-width: 991px) 50vw, (max-width: 1599px) 33.33vw, 25vw"
								/>
							)}
							<Card.Content className='eventsItem__text'  >
								<Card.Header as="h3" className="eventsItem__title">
									<UniversalLink href={flattenToAppURL(item['@id'])} className="eventsItem__link stretched-link">{item.title}</UniversalLink>
								</Card.Header>
								{item.description && (<Card.Description as="p" className="eventsItem__desc">{item.description}</Card.Description>)}
								<hr />
								<time className="eventsItem__date mb-2" dateTime={item.start}>
									<FontAwesomeIcon className='eventsItem__icon' icon={faCalendarDays} /> 
									{getEventDate(item.start)}
								</time>
								{item.location && (<p className="eventsItem__location mb-2"><FontAwesomeIcon className='eventsItem__icon' icon={faLocationDot} /> {item.location}</p>)}
								{item?.Subject && (
									<Card.Meta className="eventsItem__meta">
										<FontAwesomeIcon className='eventsItem__icon' icon={faTag} />
										<ul className="list-unstyled eventsItem__meta__list">
											{map(item.Subject.slice(0, 1), (item, index) => (
												<li key={item}>
													<span className="eventsItem__subject" >{getEventSubject(item)}</span>
												</li>
											))}
										</ul>
									</Card.Meta>
								)}
							</Card.Content>
						</Card>
					</Grid.Column>
				))}
			</Grid>

			{link &&
				<p className="readMoreItem d-print-none">
					<UniversalLink href={link} className="readMoreItem__btn ui button primary">
						{linkTitle ? (<span>{linkTitle}</span>) :
							(<FormattedMessage id="See all" defaultMessage="See all" />)}
						<span className="visually-">{blockTitle}</span>
						<FontAwesomeIcon icon={faChevronRight} className="readMoreItem__icon ms-3" />
					</UniversalLink>
				</p>
			}
		</>
	);
}

EventsListing.propTypes = {
	linkHref: PropTypes.arrayOf(PropTypes.any),
	items: PropTypes.arrayOf(PropTypes.any).isRequired,
	linkMore: PropTypes.any,
	isEditMode: PropTypes.bool,
};

export default EventsListing;
