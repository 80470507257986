import { useEffect, useState } from 'react';
import { Container, Segment, Dropdown, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark, faMagnifyingGlass, faShareNodes, faCircleHalfStroke, faTextHeight, faPrint } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faXTwitter, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { BodyClass, hasApiExpander, addAppURL, flattenToAppURL } from '@plone/volto/helpers';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { Announcements, Weather, HeaderMinisite } from './'
import { useLocation } from 'react-router-dom';
import { SearchWidgetModal } from '../';


import {
	LanguageSelector,
	Logo,
	Navigation,
	SearchWidget,
} from '@plone/volto/components';

const messages = defineMessages({
	closeMobileMenu: {
		id: 'Close menu',
		defaultMessage: 'Close menu',
	},
	openMobileMenu: {
		id: 'Open menu',
		defaultMessage: 'Open menu',
	},
	openSearch: {
		id: 'Open search form',
		defaultMessage: 'Open search form',
	}
});

const Header = ({ pathname }) => {
	const intl = useIntl();
	const token = useSelector((state) => state.userSession.token, shallowEqual);
	const content = useSelector((state) => state.content.data);
	const url = content ? addAppURL(content?.['@id'] || '/') : null;

	/* Controls mobile menu state */
	const [isMobileMenuOpen, setisMobileMenuOpen] = useState(false);
	const toggleMobileMenu = () => {
		setisMobileMenuOpen(!isMobileMenuOpen);
	};
	const closeMobileMenu = () => {
		if (!isMobileMenuOpen) {			
			return;
		} 
		setisMobileMenuOpen(false);
	};

	const [darkMode, setDarkMode] = useState(false);
	const handleContrastClick = () => {
		setDarkMode(!darkMode);
	};

	const [fontSizeMode, setFontSizeMode] = useState(false);
	const handleFontSizeClick = () => {
		setFontSizeMode(!fontSizeMode);
		if (fontSizeMode) {
			document.documentElement.style.fontSize = "24px";
		} else {
			document.documentElement.style.fontSize = null;
		}
	};
	
	const handlePrint = () => {
		window.print();
	};

	useEffect(() => {
		if (typeof window !== 'undefined') {
			localStorage.setItem('darkMode', darkMode);
			localStorage.setItem('fontSizeMode', fontSizeMode);
		}

		if (darkMode) {
			document.body.classList.add('highContrast');
		} else {
			document.body.classList.remove('highContrast');
		}

		if (fontSizeMode) {
			document.documentElement.style.fontSize = "24px";
		} else {
			document.documentElement.style.fontSize = null;
		}


	}, [darkMode, fontSizeMode]);

	// Current URL is the frontpage?
	const navroot = useSelector((state) => state.navroot.data);
	const actualPathname = useLocation().pathname;
	const navRootPath = flattenToAppURL(navroot?.navroot?.['@id']) || '/';
  	const currentURLIsNavRoot = actualPathname === navRootPath;
	 
	return (
		<>
			<BodyClass className={darkMode ? 'highContrast' : 'noContrast'} />

				{ /* Announcements only on frontpage */
					currentURLIsNavRoot &&
					<Announcements />
				}
			<Segment basic className="portalHeader" as="header">			


				<Container>

					<Logo />

					<button className={'navBarToggle mobile tablet only portalHeader__navToggle d-print-none ' + (isMobileMenuOpen && 'is-active')}
						type="button"
						onClick={toggleMobileMenu}
						aria-expanded={isMobileMenuOpen}
						aria-controls="main-nav" >
						{isMobileMenuOpen ? (
							<>
								<FontAwesomeIcon icon={faXmark} />
								<span className="visually-hidden">{intl.formatMessage(messages.closeMobileMenu)}</span>
							</>
						) : (
							<>
								<FontAwesomeIcon icon={faBars} />
								<span className="visually-hidden">{intl.formatMessage(messages.openMobileMenu)}</span>
							</>
						)}
					</button>

					<div id="main-nav"
						className={'portalHeader__wrapper ' + (isMobileMenuOpen && 'expand')}
						onClick={closeMobileMenu}>
	
						<Weather />

						<Navigation pathname={pathname} />

						<SearchWidgetModal />

						<div className="portalHeader__actions d-print-none">
							<Dropdown
								icon={<><FontAwesomeIcon icon={faShareNodes} /><span className="visually-hidden"><FormattedMessage id="Share" defaultMessage="Share" /></span></>}
								labeled
								button
								className='sharePage' >
								<Dropdown.Menu>
									<Dropdown.Item>
										<Button as="a" className='btnFacebook' href={'https://www.facebook.com/sharer/sharer.php?u=' + url} >
											<FontAwesomeIcon icon={faFacebookF} /><span className="visually-hidden">Compartir a Facebook</span>
										</Button>
									</Dropdown.Item>
									<Dropdown.Item>
										<Button as="a" className='btnTwitter' href={'https://twitter.com/share?url=' + url} >
											<FontAwesomeIcon icon={faXTwitter} /><span className="visually-hidden">Compartir a Twitter</span>
										</Button>
									</Dropdown.Item>
									<Dropdown.Item>
										<Button as="a" className='btnWhatsapp' href={'https://api.whatsapp.com/send?text= ' + url} >
											<FontAwesomeIcon icon={faWhatsapp} /><span className="visually-hidden">Compartir a Whatsapp</span>
										</Button>
									</Dropdown.Item>
									<Dropdown.Item>
										<Button as="a" className='btnLinkedin' href={'http://www.linkedin.com/shareArticle?mini=true&amp;url=' + url} >
											<FontAwesomeIcon icon={faLinkedin} /><span className="visually-hidden">Compartir a Linkedin</span>
										</Button>
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>

							<Button basic className="contrastToggle" onClick={handleContrastClick}>
								<FontAwesomeIcon icon={faCircleHalfStroke} />
								<span className="visually-hidden"><FormattedMessage id="Contrast" defaultMessage="Contrast" /></span>
							</Button>

							<Button basic className="fontSwitch " onClick={handleFontSizeClick}> {/* TODO: falta fer que funcioni */}
								<FontAwesomeIcon icon={faTextHeight} />
								<span className="visually-hidden"><FormattedMessage id="Modify font size" defaultMessage="Modify font size" /></span>
							</Button>

							<Button basic onClick={handlePrint} className='printBtn' >
								<FontAwesomeIcon icon={faPrint} />
								<span className="visually-hidden"><FormattedMessage id="Print" defaultMessage="Print" /></span>
							</Button>

							<LanguageSelector />

							<SearchWidgetModal />

						</div>


					</div>

				</Container>

			</Segment>

			{(navroot?.custom_root && navroot?.custom_root?.['@type'] === 'Minisite') && (
				<>
					<BodyClass className='minisiteTree' />
					<HeaderMinisite />
				</>
			)}
		</>

	);
};

export default Header;

Header.propTypes = {
	token: PropTypes.string,
	pathname: PropTypes.string.isRequired,
	content: PropTypes.objectOf(PropTypes.any),
};

Header.defaultProps = {
	token: null,
	content: null,
};
