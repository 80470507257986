/**
 * importTramits actions.
 * @module actions/searchTramits/searchTramits
 */

import { SEARCH_TRAMITS } from '../../constants/ActionTypes';

/**
 * searchTramits function
 * @function searchTramits
 * @param {string} url Content url.
 * @param {Object} Form data
 * @returns {Object} searchTramits action.
 */
export function searchTramits({ searchableText = null, category = null } = {}) {
  let parameters = ''
  if (searchableText) { parameters = `${searchableText? `?searchable_text=${encodeURIComponent(searchableText)}` : ''}`}
  if (category) { parameters = `${category? `?category=${encodeURIComponent(category)}` : ''}`}

  return {
    type: SEARCH_TRAMITS,
    request: {
      op: 'get',
      path: `@tramits-list${parameters}`,
    },
  };
}