import react from 'react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Segment, Card, Header, Grid, Label, Button, Container } from 'semantic-ui-react';
import { UniversalLink, PreviewImage } from '@plone/volto/components';
import { FormattedMessage } from 'react-intl';
import { getVocabulary, searchContent } from '@plone/volto/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faRss, faCircleCheck, faCalendarDays, faLocationDot, faTag } from '@fortawesome/free-solid-svg-icons';
import { flattenToAppURL } from '@plone/volto/helpers';
import { keys, isEmpty, map } from 'lodash';
import { formatDate } from '../../../helpers'


const View = (props) => {
	const { data, mode, content } = props;
	let href = (data.linkHref?.[0]?.['@id']) || '';
	let link = href ? flattenToAppURL(href) : '';

	const vocabularySubjects = 'product.matadepera.vocabularies.EventSubjects';
	const dispatch = useDispatch();
	const events = useSelector((state) => state.search.subrequests.events?.items);
	const itemsSubjects = useSelector(
		(state) =>
			state.vocabularies[vocabularySubjects] && state.vocabularies[vocabularySubjects].items
				? [...state.vocabularies[vocabularySubjects].items]
				: [],
		shallowEqual,
	);
	const [subject, setSubject] = useState('')

	useEffect(() => {
		let contentFilter = {
			portal_type: ['Event'],
			b_size: 3,
			review_state: 'published',
			sort_on: 'start',
			event_subjects: subject,
			metadata_fields: ['start', 'end', 'hasPreviewImage', 'event_subjects', 'location']
		};
        contentFilter['end.query'] = [moment().format('YYYY/MM/DD HH:mm')]
        contentFilter['end.range'] = 'min'

		dispatch(searchContent(link, contentFilter, 'events'));
	}, [dispatch, subject]);

	useEffect(() => {
		dispatch(getVocabulary({ vocabNameOrURL: vocabularySubjects }));
	}, [dispatch]);

	const getEventSubject = function (subject) {
		if (!itemsSubjects) {
			return subject
		}
		for (let item of itemsSubjects) {
			if (item.value === subject) return item.label
		}
		return subject
	}

	const getEventDate = function (start) {
		const startDay = formatDate(start)
		//const endDay = formatDate(end)

		let date = ''
		const optionsFullTime = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
		const optionsTime = { hour: '2-digit', minute: '2-digit' };

		// if (startDay === endDay) {
		// 	date = formatDate(start, optionsFullTime) + ' - ' + formatDate(end, optionsTime)
		// } else {
		// 	date = formatDate(start, optionsFullTime) + ' - ' + formatDate(end, optionsFullTime)
		// }
		date = formatDate(start, optionsFullTime);

		return date
	}

	const onClickSubject = function (e) {
		setSubject(e.target.value)
	}

	const showRss = false;

	return (

		<div className="full-width eventsHome__wrapper">

			<Segment as="section" className="eventsHome px-0" basic>

				<Container>

					{data?.title ? (
						<div className="eventsHome__title">
							<Header as="h2">{data.title}</Header>
							{showRss && (
							<UniversalLink href="#" className="eventsHome__rss">
								<FontAwesomeIcon icon={faRss} />
								<abbr className='visually-hidden' title='Really Simple Syndication' lang="en">RSS</abbr>
							</UniversalLink>
							)}

						</div>
					) : (
						<Header as="h2" className='visually-hidden'><FormattedMessage id="Agenda" defaultMessage="Agenda" /></Header>
					)}

					{!isEmpty(itemsSubjects) && (
						<div className='eventsHome__categories d-print-none'>
							<Header as="h3" className='visually-hidden'><FormattedMessage id="Selecciona categoria" defaultMessage="Selecciona categoria" /></Header>
							<Button onClick={onClickSubject} value="" className={'eventsHome__categories__item ' + (subject === '' ? 'active' : '')}>
								<FormattedMessage id="Totes" defaultMessage="Totes" />
								{subject === '' && <FontAwesomeIcon icon={faCircleCheck} className="eventsHome__categories__item__icon" />}
							</Button>
							<>
								{itemsSubjects.map((item) => (
									<Button key={item.value} onClick={onClickSubject} value={item.value} className={'eventsHome__categories__item ' + (subject === item.value ? 'active' : '')}> 
										{item.label} 
										{subject === item.value && <FontAwesomeIcon icon={faCircleCheck} className="eventsHome__categories__item__icon" />}
									</Button>
								))}
							</>
						</div>
					)}

					<Grid columns={12}>
						{!isEmpty(events) ? (
							<>
								{events.map((item) => (
									<Grid.Column mobile={12} tablet={6} computer={4} widescreen={4} key={item['@id']} >

										<Card fluid as="article" className="eventsItem position-relative">
											{item.image_field && (
												<PreviewImage
													item={item}
													alt={item.image_caption ? item.image_caption : ''}
													className="eventsItem__img m-0 image"
													sizes="(max-width: 767px) 100vw, (max-width: 991px) 50vw, (max-width: 1599px) 33.33vw, 25vw"
												/>
											)}
											<Card.Content className='eventsItem__text' >
												<Card.Header as="h3" className="eventsItem__title">
													<UniversalLink href={flattenToAppURL(item['@id'])} className="eventsItem__link stretched-link">{item.title}</UniversalLink>
												</Card.Header>
												{item.description && (<Card.Description as="p" className="eventsItem__desc">{item.description}</Card.Description>)}
												<hr />
												<time className="eventsItem__date mb-2" dateTime={item.start} >
													<FontAwesomeIcon className='eventsItem__icon' icon={faCalendarDays} /> {getEventDate(item.start)}</time> 
												{item.location && (<p className="eventsItem__location mb-2"><FontAwesomeIcon className='eventsItem__icon' icon={faLocationDot} /> {item.location}</p>)}
												{item.event_subjects?.length > 0 && (
													<Card.Meta className="eventsItem__meta">
														<FontAwesomeIcon className='eventsItem__icon' icon={faTag} />
														<ul className="list-unstyled eventsItem__meta__list">
															{map(item.event_subjects.slice(0, 1), (item, index) => (
																<li key={index}>
																	<span className="eventsItem__subject" >{getEventSubject(item)}</span>
																</li>
															))}
														</ul>
													</Card.Meta>
												)}
											</Card.Content>
										</Card>
									</Grid.Column>
								))}
							</>
						) : (
							<Container>
								<p><FormattedMessage id="No events found" defaultMessage="No events found" /></p>
							</Container>
						)}
					</Grid>

					{link &&
						<p className="pt-5 mb-4 text-center d-print-none">
							<UniversalLink href={link} className="readMoreItem button ui primary">
								<span className="readMoreItem__text">
									{data.linkTitle ? (<span>{data.linkTitle}</span>) :
										(<FormattedMessage id="See all" defaultMessage="See all" />)}
								</span>
								<FontAwesomeIcon icon={faAngleRight} className="readMoreItem__icon ms-3" />
							</UniversalLink>
						</p>
					}
				</Container>

			</Segment>

		</div>

	);
};

export default View;
