/**
 * NewsListing component.
 * @module components/News/NewsListing
 */

import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Container, Header, Grid, Card, Form } from 'semantic-ui-react';
import { BodyClass, hasBlocksData } from '@plone/volto/helpers';
import { UniversalLink, PreviewImage, FormattedDate, Pagination, Image } from '@plone/volto/components';
import { searchContent, getVocabulary } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { keys, isEmpty, map, filter } from 'lodash';
import { useDebounce } from "use-debounce";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { formatDate } from '../../helpers'


const messages = defineMessages({
	labelText: {
		id: 'By words',
		defaultMessage: 'By words',
	},
	placeholderText: {
		id: 'What are you looking for?',
		defaultMessage: 'What are you looking for?',
	},
	defaultOptionSubjects: {
		id: 'All subjects',
		defaultMessage: 'All subjects',
	},
	labelSubject: {
		id: 'By category',
		defaultMessage: 'By category',
	},
	placeholderAllSubjects: {
		id: 'All subjects',
		defaultMessage: 'All subjects',
	},
	labelMonth: {
		id: 'Month',
		defaultMessage: 'Month',
	},
	placeholderAllMonths: {
		id: 'All months',
		defaultMessage: 'All months',
	},
	labelYear: {
		id: 'Year',
		defaultMessage: 'Year',
	},
	placeholderAllYears: {
		id: 'All years',
		defaultMessage: 'All years',
	},
});

const NewsListing = (props) => {
	const { content } = props;
	const lang = content.language.token;
	const dispatch = useDispatch();
	const intl = useIntl();
	const news = useSelector((state) => state.search.subrequests.news?.items);
	const total = useSelector((state) => state.search.subrequests.news?.total);
	const [currentPage, setCurrentPage] = useState(0);
	const [pageSize, setPageSize] = useState(12);
	const [search, setSearch] = useState(0);
	const [searchableTextInput, setSearchableText] = useState('');
	const [searchableText] = useDebounce(searchableTextInput, 500);
	const [month, setMonth] = useState('');
	const [year, setYear] = useState('');
	const [subject, setSubject] = useState('');
	const vocabularySubjects = 'plone.app.vocabularies.Keywords';

	const months = [
		{ value: '', label: intl.formatMessage(messages.placeholderAllMonths) },
		{ value: 1, label: 'Gener' },
		{ value: 2, label: 'Febrer' },
		{ value: 3, label: 'Març' },
		{ value: 4, label: 'Abril' },
		{ value: 5, label: 'Maig' },
		{ value: 6, label: 'Juny' },
		{ value: 7, label: 'Juliol' },
		{ value: 8, label: 'Agost' },
		{ value: 9, label: 'Setembre' },
		{ value: 10, label: 'Octubre' },
		{ value: 11, label: 'Novembre' },
		{ value: 12, label: 'Desembre' },
	];

	const years = [
		{ value: '', label: intl.formatMessage(messages.placeholderAllYears) },
		...Array.from({ length: 20 }, (_, index) => {
			const year = moment().year() - index;
			return { value: year, label: year };
		}),
	];

	const itemsSubjects = useSelector(
		(state) =>
			state.vocabularies[vocabularySubjects] && state.vocabularies[vocabularySubjects].items
				? [{ value: '', label: intl.formatMessage(messages.defaultOptionSubjects) }, ...state.vocabularies[vocabularySubjects].items]
				: [],
		shallowEqual,
	);

	const getSubject = function (Subject) {
		if (!itemsSubjects) {
			return Subject
		}
		for (let item of itemsSubjects) {
			if (item.value === Subject) return item.label
		}
		return Subject
	}

	useEffect(() => {
		dispatch(getVocabulary({ vocabNameOrURL: vocabularySubjects, lang: content.language.token }));
	}, [dispatch]);

	useEffect(() => {
		let contentFilter = {
			portal_type: ['News Item'],
			b_size: pageSize,
			b_start: currentPage * pageSize,
			review_state: 'published',
			SearchableText: searchableText,
			sort_on: 'effective',
			sort_order: 'reverse',
			metadata_fields: ['effective', 'Subject', 'hasPreviewImage', 'featured']
		};
        contentFilter['effective.query'] = [moment().format('YYYY/MM/DD HH:mm')]
        contentFilter['effective.range'] = 'max'

		if (subject && subject !== '') {
			contentFilter.Subject = subject;
		}

		if (month && year) {
			const startOfMonth = moment(`${year}-${month}`, 'YYYY-MM').startOf('month');
			const endOfMonth = moment(`${year}-${month}`, 'YYYY-MM').endOf('month');
			contentFilter['effective.query'] = [
				startOfMonth.format('YYYY/MM/DD') + ' 00:00',
				endOfMonth.format('YYYY/MM/DD') + ' 23:59'
			];
			contentFilter['effective.range'] = 'min:max';
		}
		else if (year) {
			const startOfYear = moment(`${year}-01-01`, 'YYYY-MM-DD').startOf('year');
			const endOfYear = moment(`${year}-12-31`, 'YYYY-MM-DD').endOf('year');

			contentFilter['effective.query'] = [
				startOfYear.format('YYYY/MM/DD') + ' 00:00',
				endOfYear.format('YYYY/MM/DD') + ' 23:59'
			];
			contentFilter['effective.range'] = 'min:max';
		}

		dispatch(searchContent(flattenToAppURL(content['@id']), contentFilter, 'news'));
	}, [dispatch, currentPage, pageSize, searchableText, month, year, subject, search]);


	useEffect(() => {
		const searchForm = document.querySelector('.searchList');
		const searchFormPos = searchForm ? searchForm.offsetTop : 0;
		let scrollPos = window.scrollY;
		if (scrollPos > searchFormPos && searchForm) {
			window.scrollTo({ top: searchFormPos })
		}
	}, [currentPage]);

	const onChangePage = (event, activePage) => {
		event.preventDefault();
		setCurrentPage(activePage.value);
	};

	const onChangePageSize = (event) => {
		event.preventDefault();
		setPageSize(parseInt(event.target.text));
		setCurrentPage(0);
	};

	const onChangeSearchableText = (event, data) => {
		event.preventDefault();
		setSearchableText(data.value);
		setCurrentPage(0);
	};

	const onChangeSubject = (event, data) => {
		event.preventDefault();
		setSubject(data.value);
		setCurrentPage(0);
	};

	const onChangeMonth = (event, data) => {
		event.preventDefault();
		setMonth(data.value);
		setCurrentPage(0);
	};

	const onChangeYear = (event, data) => {
		event.preventDefault();
		setYear(data.value);
		setCurrentPage(0);
	};

	const onSubmit = (event) => {
		event.preventDefault();
		setSearch(search + 1);
		setCurrentPage(0);
	};


	return (
		<>
			<BodyClass className='newslisting' />

			{hasBlocksData(content) ? (
				<Container>
					<Grid>
						<Grid.Column width={12}>
							<RenderBlocks {...props} />
						</Grid.Column>
					</Grid>
				</Container>
			) : null}

			<Form className="searchList" onSubmit={onSubmit}>
				<Container className="pb-4 d-print-none">
				<Header as="h2" className='justify-content-center searchList__title' size='small' textAlign='center'><FormattedMessage id="Filter" defaultMessage="Filter" /></Header>
					<Form.Group widths="equal" className="searchList__formGroup align-items-end">
						<Form.Input
							id="textInputId"
							fluid
							label={intl.formatMessage(messages.labelText)}
							focus
							name="searchableText"
							value={searchableTextInput}
							onChange={onChangeSearchableText}
							placeholder={intl.formatMessage(messages.placeholderText)}
							type="text"
						/>
						<Form.Select
							id="subjectSelectId"
							fluid
							label={intl.formatMessage(messages.labelSubject)}
							options={map(itemsSubjects, (item, index) => {
								return {
									key: index,
									value: item.value,
									text: item.label,
								};
							})}
							name="subject"
							value={subject}
							onChange={onChangeSubject}
							placeholder={intl.formatMessage(messages.placeholderAllSubjects)}
						/>
						<Form.Select
							id="monthSelectId"
							fluid
							label={intl.formatMessage(messages.labelMonth)}
							options={months.map((month, index) => {
								return {
									key: index,
									value: month.value,
									text: month.label,
								};
							})}
							name="month"
							value={month}
							onChange={onChangeMonth}
							placeholder={intl.formatMessage(messages.placeholderAllMonths)}
						/>
						<Form.Select
							id="yearSelectId"
							fluid
							label={intl.formatMessage(messages.labelYear)}
							options={years.map((year, index) => {
								return {
									key: index,
									value: year.value,
									text: year.label,
								};
							})}
							name="year"
							value={year}
							onChange={onChangeYear}
							placeholder={intl.formatMessage(messages.placeholderAllYears)}
						/>
					</Form.Group>
				</Container>
			</Form>

			<div className='newsListing__wrapper'>
				{!isEmpty(news) ? (
					<Container className="news newsListing pt-1">
						<Grid className='mt-5'>
							<Grid.Row columns={12}>
								{news.map((item) => (
									<Grid.Column className="mb-4" mobile={12} tablet={6} computer={4} widescreen={3} key={item['@id']}>
										<Card fluid as="article" className={'newsItem position-relative ' + (item?.hasPreviewImage ? ' newsItem-hasImage' : ' newsItem-noImage')}>
											<Image
												className="newsItem__img m-0 image"
												item={item}
												loading='lazy'
												alt=""
												sizes="(max-width: 767px) 100vw, (max-width: 991px) 50vw, (max-width: 1599px) 33.33vw, 25vw"
											/>
											<Card.Content  >
												<Card.Header className="newsItem__title" as="h2">
													<UniversalLink
														className="newsItem__link newsItem__title stretched-link"
														href={flattenToAppURL(item['@id'])}>
														{item.title}
													</UniversalLink>
												</Card.Header>
												<Card.Description as="p" className="newsItem__desc">{item.description}</Card.Description>
												<Card.Meta>
													{item?.Subject ? (
														<ul className='newsItem__meta__list list-unstyled mt-0 mb-2'>
															{map(item.Subject.slice(0, 1), (item, index) => (
																<li key={item}>
																	<span className="newsItem__subject" >{getSubject(item)}</span>
																</li>
															))}
														</ul>
													) : null}
													<FormattedDate date={item.effective} className="newsItem__date "
														format={{
															day: "2-digit",
															month: "2-digit",
															year: "numeric",
														}}
													/>
												</Card.Meta>
											</Card.Content>
										</Card>
									</Grid.Column>
								))}
							</Grid.Row>
						</Grid>

						{total > pageSize && (
							<nav className="pagination">
								<Pagination
									current={currentPage}
									total={Math.ceil(total / pageSize)}
									onChangePage={onChangePage}
									onChangePageSize={onChangePageSize}
								/>
							</nav>
						)}

					</Container>
				) : (
					<Container className="news newsListing">
						<p><FormattedMessage id="No news found" defaultMessage="No news found" /></p>
					</Container>
				)}
			</div>
		</>
	);
};


export default NewsListing;
