import React, { useState } from 'react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Header, Container, Button } from 'semantic-ui-react';
import { searchContent } from '@plone/volto/actions';
import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isEmpty } from 'lodash';
import { Carousel } from '../';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faXmark } from '@fortawesome/free-solid-svg-icons';


const SLIDE_OPTIONS = {
	loop: false,
	autoplay: false,
	dots: false,
	buttons: true,
	customClass: 'announcements-slider',
	stopOnMouseEnter: true
}


const ItemView = props => {
	const { item } = props

	return (
		<>
		{item.getRemoteUrl ? (
		<UniversalLink href={item.remoteUrl} className=" announcementItem " key={item['@id']}>
			<FontAwesomeIcon icon={faTriangleExclamation} className='announcementItem__icon me-2' /> {item.title}
		</UniversalLink>
		) : (
		<UniversalLink href={flattenToAppURL(item['@id'])} className=" announcementItem " key={item['@id']}>
			<FontAwesomeIcon icon={faTriangleExclamation} className='announcementItem__icon me-2' /> {item.title}
		</UniversalLink>
		)}
		</>
	)

};

const Announcements = props => {
	const dispatch = useDispatch();
	const announcements = useSelector((state) => state.search.subrequests.announcements?.items);

	useEffect(() => {
		dispatch(
			searchContent(
				'/actualitat/avisos',
				{
					portal_type: ['Announcement'],
					sort_on: 'getObjPositionInParent',
          			metadata_fields: ['getRemoteUrl', 'remoteUrl']
				},
				'announcements',
			),
		);
	}, [dispatch]);

	// Hide Announcements on clic
	const [visible, setVisible] = useState(true);
	const toggleVisibility = () => {
		setVisible(!visible);
	};

	return (
		<>
			{(!isEmpty(announcements) && visible) ? (
				<Segment basic className='announcements' as="aside" aria-labelledby="announcements-title" >

					<Container>

						<div className="announcements__wrapper">

							<Header as="h2" className='visually-hidden' id="announcements-title">
								<FormattedMessage id="Avisos" defaultMessage='Avisos' />
							</Header>

							<Carousel slides={announcements.slice(0, 8)} ItemView={ItemView} options={SLIDE_OPTIONS} />

						</div>

					</Container>

					<Button className='announcements__close d-print-none' basic onClick={toggleVisibility}>
						<span className='visually-hidden'><FormattedMessage id="Tancar" defaultMessage='Tancar' /></span>
						<FontAwesomeIcon icon={faXmark} />
					</Button>

				</Segment>
			) : (
				<></>
			)}
		</>
	);
};

export default Announcements;