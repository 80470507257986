import React from 'react';
import PropTypes from 'prop-types';
import { Container as SemanticContainer, Card, Grid } from 'semantic-ui-react';
import config from '@plone/volto/registry';
import { BodyClass } from '@plone/volto/helpers';
import { filter } from 'lodash'; 

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink, Pagination } from '@plone/volto/components';

const BlogView2 = ({ content }) => {
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;
  const PreviewImage = config.getComponent({ name: 'PreviewImage' }).component;

  const getViewURL = function (item) {
    let URL = flattenToAppURL(item['@id']);
    if (item['@type'] === 'File') {
      URL += '/@@display-file/file';
    }
    if (item['@type'] === 'Image') {
      URL += '/@@display-file/image';
    }
    if (item?.getRemoteUrl) {
      URL = item?.getRemoteUrl;
    }
    return URL;
  };

  const dispatch = useDispatch();
  const items = useSelector((state) => state.search.subrequests.items?.items);
  const total = useSelector((state) => state.search.subrequests.items?.total);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);

  useEffect(() => {
    let contentFilter = {
      exclude_from_nav: 'false',
      b_size: pageSize,
      b_start: currentPage * pageSize,
      sort_on: 'getObjPositionInParent',
    };
    contentFilter['path.depth'] = 1
    dispatch(searchContent(flattenToAppURL(content['@id']), contentFilter, 'items'));
  }, [dispatch, currentPage, pageSize]);

  const onChangePage = (event, activePage) => {
    event.preventDefault();
    setCurrentPage(activePage.value);
  };

  const onChangePageSize = (event) => {
    event.preventDefault();
    setPageSize(parseInt(event.target.text));
    setCurrentPage(0);
  };


  return (
    <>
      <BodyClass className="blogView" />

      <Container id="content-core" className="blogView__page pt-5">
        <Grid columns={12} as="ul" className='list-unstyled'>

          {items &&
            items.map((item, index) => (
              <Grid.Column key={item.url} mobile={12} tablet={6} computer={4} as="li" className='position-relative'>
                <Card
                  as="article"
                  fluid
                  className={
                    'blogItem ' +
                    (item.image_field ? 'blogItem-hasImage' : 'blogItem-noImage')
                  }
                >
                    {item.image_field && (
                        <PreviewImage
                          item={item}
                          alt={item.image_caption ? item.image_caption : ''}
                          className="blogItem__img"
                          responsive={true}
                          sizes="(min-width: 1600px) 500px, (min-width: 1280px) 400px, (min-width: 600px) 200px, 50vw"
                          loading="lazy"
                        />
                    )}
                  <Card.Content className="blogItem__text">
                    <Card.Header as="h2" className="blogItem__title">
                      <UniversalLink
                        href={getViewURL(item)}
                        className="blogItem__link stretched-link"
                      >
                        <span className="blogItem__link__text">{item.title}</span>
                      </UniversalLink>
                    </Card.Header>
                    {item.description && (
                      <Card.Description as="p" className="blogItem__desc">
                        {item.description}
                      </Card.Description>
                    )}
                  </Card.Content>
                </Card>
              </Grid.Column>
            ))}
 
        </Grid>

          {total > pageSize && (
            <nav className="pagination">
              <Pagination
                current={currentPage}
                total={Math.ceil(total / pageSize)}
                onChangePage={onChangePage}
                onChangePageSize={onChangePageSize}
              />
            </nav>
          )}     

      </Container>
    </>
  );
};

export default BlogView2;