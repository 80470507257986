/**
 * Weather actions.
 * @module actions/weather/weather
 */

import {
  GET_WEATHER,
} from '../../constants/ActionTypes';


/**
 * Get weather function.
 * @function getWeather
 * @returns {Object} Get weather action.
 */
export function getWeather(options) {
  return {
    type: GET_WEATHER,
    request: {
      op: 'get',
      path: '@weather',
    },
  };
}
