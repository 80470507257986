import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Button } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';


const messages = defineMessages({
    labelNav: {
        id: 'Minisite navigation',
        defaultMessage: 'Minisite navigation',
    },
    closeMobileMenu: {
        id: 'Close menu',
        defaultMessage: 'Close menu',
    },
    openMobileMenu: {
        id: 'Open menu',
        defaultMessage: 'Open menu',
    },
});


const NavigationMinisite = props => {
    const intl = useIntl();
    const navigation = useSelector((state) => state.navroot.data?.custom_root?.navigation);

    /* Controls mobile menu state */
    const [isMobileMinisiteMenuOpen, setisMobileMinisiteMenuOpen] = useState(false);
    const toggleMobileMinisiteMenu = () => {
        setisMobileMinisiteMenuOpen(!isMobileMinisiteMenuOpen);
    };
    const closeMobileMinisiteMenu = () => {
        if (!isMobileMinisiteMenuOpen) {
            return;
        }
        setisMobileMinisiteMenuOpen(false);
    };

    return (
        navigation.length > 0 && (
            <nav id="minisite-nav" className="minisiteNav" aria-label={intl.formatMessage(messages.labelNav)}>

                <Container textAlign='center'>

                    <button className={'navBarToggle mobile tablet only portalHeader__navToggle ' + (isMobileMinisiteMenuOpen && 'is-active')}
                        type="button"
                        onClick={toggleMobileMinisiteMenu}
                        aria-expanded={isMobileMinisiteMenuOpen}
                        aria-controls="minisite-nav" >
                        {isMobileMinisiteMenuOpen ? (
                            <>
                                <FontAwesomeIcon icon={faXmark} />
                                <span className="visually-hidden">{intl.formatMessage(messages.closeMobileMenu)}</span>
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faBars} />
                                <span className="visually-hidden">{intl.formatMessage(messages.openMobileMenu)}</span>
                            </>
                        )}
                    </button>


                    <ul className={"minisiteNav__list list-unstyled " + (isMobileMinisiteMenuOpen && 'expand')} onClick={closeMobileMinisiteMenu}>
                        {navigation.map((item) => (
                            <li className="minisiteNav__item" key={item['@id']}>
                                <UniversalLink href={flattenToAppURL(item['@id'])} className="minisiteNav__link">{item.title}</UniversalLink>
                            </li>
                        ))}
                    </ul>


                </Container>

            </nav>
        )
    )
};

export default NavigationMinisite;