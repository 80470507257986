import React from 'react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { isInternalURL } from '@plone/volto/helpers';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Segment, Card, Header, Grid, Label, Container } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { UniversalLink, PreviewImage } from '@plone/volto/components';
import { getVocabulary } from '@plone/volto/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faRss, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { keys, isEmpty, map, filter } from 'lodash';
import { formatDate } from '../../../helpers'


const NewsListing = ({ items, linkTitle, linkHref, isEditMode, blockTitle }) => {

	let href = (linkHref?.[0]?.['@id']) || '';
	let link = href ? flattenToAppURL(href) : '';


	const vocabularySubjects = 'plone.app.vocabularies.Keywords';
	const dispatch = useDispatch();
	const itemsSubjects = useSelector(
		(state) =>
			state.vocabularies[vocabularySubjects] && state.vocabularies[vocabularySubjects].items
				? [...state.vocabularies[vocabularySubjects].items]
				: [],
		shallowEqual,
	);

	useEffect(() => {
		dispatch(getVocabulary({ vocabNameOrURL: vocabularySubjects }));
	}, [dispatch]);

	const getNewsItemSubject = function (subject) {
		if (!itemsSubjects) {
			return subject
		}
		for (let item of itemsSubjects) {
			if (item.value === subject) return item.label
		}
		return subject
	}

	return (
		<>
			{blockTitle && <h3 className="directLinks__title mt-0">{blockTitle}</h3>}
			<Grid columns={12}>
				{items.map((item) => (
					<Grid.Column mobile={12} tablet={6} computer={4} widescreen={4} key={item['@id']} >

						<Card fluid as="article" className="newsItem position-relative">
							{item.image_field && (
								<PreviewImage
									className="newsItem__img m-0 image"
									item={item}
									alt={item.image_caption ? item.image_caption : ''}

								/>
							)}
							<Card.Content >
								<Card.Header as="h3" className="newsItem__title">
									<UniversalLink href={flattenToAppURL(item['@id'])} className="newsItem__link stretched-link">{item.title}</UniversalLink>
								</Card.Header>
								<Card.Description as="p" className="newsItem__desc">{item.description}</Card.Description>
								<Card.Meta className=" newsItem__meta">													
									{item?.Subject && (
										<ul className='newsItem__meta__list list-unstyled mt-0 mb-2'>
											{map(item.Subject.slice(0, 1), (item, index) => (
												<li key={item}>
													<span className="newsItem__subject">{getNewsItemSubject(item)}</span>
												</li>
											))}
										</ul>
									)}
									<time className="newsItem__date" dateTime={item.Date}>{formatDate(item.effective)}</time>
								</Card.Meta>
							</Card.Content>
						</Card>
					</Grid.Column>
				))}
			</Grid>

			{link &&
				<p className="readMoreItem d-print-none">
					<UniversalLink href={link} className="readMoreItem__btn ui button primary">
						{linkTitle ? (<span>{linkTitle}</span>) :
							(<FormattedMessage id="See all" defaultMessage="See all" />)}
						<span className="visually-">{blockTitle}</span>
						<FontAwesomeIcon icon={faChevronRight} className="readMoreItem__icon ms-3" />
					</UniversalLink>
				</p>
			}
		</>
	);
}

NewsListing.propTypes = {
	linkHref: PropTypes.arrayOf(PropTypes.any),
	items: PropTypes.arrayOf(PropTypes.any).isRequired,
	linkMore: PropTypes.any,
	isEditMode: PropTypes.bool,
};

export default NewsListing;
