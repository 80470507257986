import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ClusterMap } from '../';
import { searchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import moment from 'moment';
import { Form, Container, Header } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { map } from 'lodash';


const messages = defineMessages({
	defaultOptionSubjects: {
		id: 'All subjects',
		defaultMessage: 'All subjects',
	},
});


const EventsMapListing = (props) => {
	const dispatch = useDispatch();
	const intl = useIntl();
	const events = useSelector((state) => state.search.subrequests?.events?.items);
	const places = useSelector((state) => state.search.subrequests?.places?.items);
	const [category, setCategory] = useState('');
	const [dateFrom, setDateFrom] = useState(null);
	const [dateTo, setDateTo] = useState(null);
	const [focusedFrom, setFocusedFrom] = useState(false);
	const [focusedTo, setFocusedTo] = useState(false);
	const [eventsGeolocation, setEventsGeolocation] = useState([]);
	const vocabularySubjects = 'product.matadepera.vocabularies.EventSubjects';

	const itemsSubjects = useSelector(
		(state) =>
			state.vocabularies[vocabularySubjects] && state.vocabularies[vocabularySubjects].items
				? [{ value: '', label: intl.formatMessage(messages.defaultOptionSubjects) }, ...state.vocabularies[vocabularySubjects].items]
				: [],
		shallowEqual,
	);

	const getPlaceGeolocation = function (places, relatedPlace) {
		const place = places.find(item => item.UID === relatedPlace);
		return place?.geolocation
	};

	useEffect(() => {
		const contentFilter = {
			portal_type: 'Event',
			review_state: 'published',
			sort_on: 'sortable_title',
			b_size: 500,
			metadata_fields: ['geolocation', 'related_place', 'location', 'start', 'end', 'Subject'],
		};

		if (category) {
			contentFilter.event_subjects = category;
		}

		if (dateFrom) {
			const startDate = dateFrom.startOf('day').format('YYYY/MM/DD') + ' 00:00';
			contentFilter['start.query'] = startDate;
			contentFilter['start.range'] = 'min';
		}

		if (dateTo) {
			const endDate = dateTo.endOf('day').format('YYYY/MM/DD') + ' 23:59';
			contentFilter['start.query'] = [contentFilter['start.query'], endDate];
			contentFilter['start.range'] = 'min:max';
		}
		if (!dateFrom && !dateTo) {
        	contentFilter['end.query'] = [moment().format('YYYY/MM/DD HH:mm')]
        	contentFilter['end.range'] = 'min'
		}

		dispatch(
			searchContent(
				flattenToAppURL(props.content['@id']),
				contentFilter,
				'events'
			)
		);
	}, [dispatch, category, dateFrom, dateTo]);

	useEffect(() => {
		const relatedPlaces = events.filter(event => event.related_place).map(event => event.related_place);
		const contentFilter = {
			portal_type: 'Place',
			review_state: 'published',
			UID: relatedPlaces,
			metadata_fields: ['geolocation', 'UID'],
		};

		dispatch(
			searchContent(
				flattenToAppURL('/'),
				contentFilter,
				'places'
			)
		);
	}, [dispatch, events]);

	useEffect(() => {
		const eventsGeolocationOrPlace = events.filter(event => event.geolocation || event.related_place).map(event => {
			if (!event.geolocation && event.related_place) {
				event.geolocation = getPlaceGeolocation(places, event.related_place)
			}
			return event
		});
		const eventsGeolocation = eventsGeolocationOrPlace.filter(event => event.geolocation || event.related_place)
		setEventsGeolocation(eventsGeolocation)
	}, [events, places]);

	const onCategoryChange = (event, data) => {
		setCategory(data.value);
	};

	const onDateFromChange = (date) => {
		setDateFrom(date);
	};

	const onDateToChange = (date) => {
		setDateTo(date);
	};

	return (
		<div className="eventsMapListing">
			<Form className="searchList  d-print-none">
				<Container
					className="filters-container mt-4 pb-4">
						<Header as="h2" className='justify-content-center searchList__title' size='small' textAlign='center'><FormattedMessage id="Filter" defaultMessage="Filter" /></Header>
					<Form.Group widths="equal">
						<Form.Select
							fluid
							label={intl.formatMessage({ id: 'By category', defaultMessage: 'By category' })}
							options={map(itemsSubjects, (item, index) => ({
								key: index,
								value: item.value,
								text: item.label,
							}))}
							value={category}
							onChange={onCategoryChange}
							placeholder={intl.formatMessage({ id: 'All categories', defaultMessage: 'All categories' })}
						/>
						<div className="field searchList__date">
							<label><FormattedMessage id="From Date" defaultMessage="From Date" /></label>
							<div className="ui input date-input">
								<SingleDatePicker
									date={dateFrom}
									onDateChange={onDateFromChange}
									focused={focusedFrom}
									numberOfMonths={1}
									onFocusChange={({ focused }) => setFocusedFrom(focused)}
									noBorder
									showClearDate
									displayFormat={moment.localeData().longDateFormat('L')}
									isOutsideRange={() => false} />
							</div>
						</div>
						<div className="field searchList__date">
							<label><FormattedMessage id="To Date" defaultMessage="To Date" /></label>
							<div className="ui input date-input">
								<SingleDatePicker
									date={dateTo}
									onDateChange={onDateToChange}
									focused={focusedTo}
									numberOfMonths={1}
									onFocusChange={({ focused }) => setFocusedTo(focused)}
									noBorder
									showClearDate
									displayFormat={moment.localeData().longDateFormat('L')}
									isOutsideRange={() => false}
								/>
							</div>
						</div>
					</Form.Group>
				</Container>
			</Form>
			<Container>
				<ClusterMap className="eventsMapListing__map" markers={eventsGeolocation} />

			</Container>
		</div>
	);
};

export default EventsMapListing;
