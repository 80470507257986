/**
 * searchTramits reducer.
 * @module reducers/searchTramits/searchTramitsTramits
 */

import { SEARCH_TRAMITS } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  data: [],
};

/**
 * searchTramits reducer.
 * @function searchTramits
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function searchTramits(state = initialState, action = {}) {
  switch (action.type) {
    case `${SEARCH_TRAMITS}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${SEARCH_TRAMITS}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        data: action.result,
      };
    case `${SEARCH_TRAMITS}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
