import { searchContent } from '@plone/volto/actions';
import { FormattedDate, PreviewImage, UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Card, Container, Grid, Segment } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCalendarDays } from '@fortawesome/free-solid-svg-icons';

export default function PublicationsView(props) {

  const dispatch = useDispatch();
  const publications = useSelector((state) => state.search.subrequests.publications?.items);
  const total = useSelector((state) => state.search.subrequests.publications?.total);

  const [sortedPublications, setSortedPublications] = useState([])
  const [allYears, setAllYears] = useState([])

  useEffect(() => {
    const contentFilter = {
      b_size: 360,
      portal_type: 'Publication',
      review_state: 'published',
      sort_on: 'publicationtype_date',
      sort_order: 'descending',
      metadata_fields: ['publicationtype_date']
    }
    dispatch(
      searchContent(
        flattenToAppURL(props.content['@id']),
        contentFilter,
        'publications'
      ),
    )
  }, [dispatch])

  useEffect(() => {
    if (publications && publications.length) {
      setVariables(publications);
    }
  }, [publications]);

  const setVariables = (data) => {
    const sPublications = [...data].sort((a, b) => {
      return b.publicationtype_date - a.publicationtype_date;
    });
    let results = {}
    const yearSet = new Set()
    sPublications.forEach((value) => {
      let year = value.publicationtype_date.split('-')
      year = year[0]
      yearSet.add(year)
      if (!results[year]) {
        results[year] = []
      }
      results[year].push(value)
    })
    setSortedPublications(results)
    setAllYears(Array.from(yearSet).sort((a, b) => b - a));
  }

  return (
    <Container id="content-core" className="blogView__page pt-5">
      {
        allYears && (
          allYears.map((year, yearKey) => (
            <Segment key={yearKey} as="section" basic className='px-0'>
              <h2 className='border-bottom pb-1' key={yearKey}>{year}</h2>
              {
                <Grid columns={12} as="ul" className='list-unstyled'>
                  {sortedPublications[year].map((item, index) => (
                    <Grid.Column key={item.url} mobile={12} tablet={6} computer={6} widescreen={4} as="li" className='position-relative'>
                      <Card
                        as="article"
                        fluid
                        className={
                          'blogItem ' +
                          (item.image_field ? 'blogItem-hasImage' : 'blogItem-noImage')
                        }
                      >
                        {item.image_field && (
                          <PreviewImage
                            item={item}
                            alt=''
                            className="blogItem__img w-100"
                            responsive={true}
                            sizes="(min-width: 1600px) 500px, (min-width: 1280px) 400px, (min-width: 600px) 200px, 33vw"
                            loading="lazy"
                          />
                        )}
                        <Card.Content className="blogItem__text">
                          <Card.Header as="h3" className="blogItem__title">
                            <UniversalLink
                              href={`${flattenToAppURL(item['@id'])}/@@display-file/publication_document`}
                              className="blogItem__link stretched-link"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="blogItem__link__text">{item.title}</span>
                            </UniversalLink>
                          </Card.Header>
                            {item.description && 
                              <Card.Description as="p" className="blogItem__desc">{item.description}</Card.Description>
                            }
                            <Card.Meta className=" newsItem__date">
                              <FontAwesomeIcon className='calendarItem__icon me-2' icon={faCalendarDays} />
                              <FormattedDate date={item.publicationtype_date} as="time" dateTime={item.publicationtype_date}  
                                  format={{
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  }}
                                />
                            </Card.Meta>
                        </Card.Content>
                      </Card>
                    </Grid.Column>
                  ))}
                </Grid>
              }
            </Segment>
          ))
        )
      }
    </Container>
  )
}
