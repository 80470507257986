/**
 * Slides component.
 * @module components/Frontpage/Slides
 */

import React from 'react';
import { UniversalLink, Image } from '@plone/volto/components';
import { searchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import { Carousel } from '../../';
import { Container } from 'semantic-ui-react';

const SLIDE_OPTIONS = {
    loop: true,
    autoplay: true,
    dots: true,
    buttons: false,
    customClass: 'main-slider',
    stopOnMouseEnter: true
}


const ItemView = props => {
    const { item } = props

    return (
        <figure className=" slideItem " key={item['@id']}>
            <Image
                item={item}
                className="slideItem__image"
                alt=""
                sizes="100vw"
            />
            <Container as="figcaption" className="slideItem__text">
                {item?.description ? (
                    <span className="slideItem__desc">
                        {item.description}
                    </span>
                ) : null}
                {item?.getRemoteUrl ? (
                    <UniversalLink href={item.getRemoteUrl} className="slideItem__title slideItem__link stretched-link">{item.title}</UniversalLink>
                ) : (
                    <span className="slideItem__title ">{item.title}</span>  
                )}
            </Container>
        </figure>
    )

};

const SliderListing = ({ items, linkTitle, linkHref, isEditMode, blockTitle }) => {
    let href = (linkHref?.[0]?.['@id']) || '';
    let link = href ? flattenToAppURL(href) : '';

    return (
        <div className="full-width">
            <section id="featuredSection" className="slides slidesWrapper">
                <h2 className="visually-hidden"><FormattedMessage id="featured" defaultMessage="Featured" /></h2>
                {items && <Carousel slides={items.slice(0, 8)} delayTime={6500} ItemView={ItemView} options={SLIDE_OPTIONS} />}
            </section>
        </div>
    );

};

export default SliderListing;
