/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';
import { Form, Button, TextArea, Loader, Message, Grid, Segment } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import { isCmsUi } from '@plone/volto/helpers';
import ThumbsUp from 'volto-customer-satisfaction/icons/thumbs-up-regular.svg';
import ThumbsDown from 'volto-customer-satisfaction/icons/thumbs-down-regular.svg';
import GoogleReCaptchaWidget from 'volto-customer-satisfaction/components/widgets/GoogleReCaptchaWidget';
import HoneypotWidget from 'volto-customer-satisfaction/components/widgets/HoneypotWidget/HoneypotWidget';
import {
  submitCustomerSatisfaction,
  resetSubmitCustomerSatisfaction,
} from 'volto-customer-satisfaction/actions';
import 'volto-customer-satisfaction/components/CustomerSatisfaction/customer-satisfaction.css';

const messages = defineMessages({
  title: {
    id: 'customer_satisfaction_title',
    defaultMessage: 'Was this page useful to you?',
  },
  yes: {
    id: 'customer_satisfaction_yes',
    defaultMessage: 'Yes, very useful',
  },
  no: {
    id: 'customer_satisfaction_no',
    defaultMessage: 'Not much',
  },
  suggestions_placeholder: {
    id: 'customer_satisfaction_suggestions_placeholder',
    defaultMessage:
      'Explain us why, and help us improve the quality of the site',
  },
  title_satisfaction: {
    id: 'help_us_improve',
    defaultMessage: 'Help us improve',
  },
  submit: {
    id: 'customer_satisfaction_submit',
    defaultMessage: 'Submit your comment',
  },
  thank_you: {
    id: 'customer_satisfaction_thank_you',
    defaultMessage: 'Thank you for your feedback!',
  },
  comment_label: {
    id: 'customer_satisfaction_comment',
    defaultMessage: 'Your comment'
  }
});

const CustomerSatisfaction = () => {
  const intl = useIntl();
  const location = useLocation();
  const path = location.pathname ?? '/';
  const dispatch = useDispatch();
  const [satisfaction, setSatisfaction] = useState(null);
  const [formData, setFormData] = useState({});
  const captcha = !!process.env.RAZZLE_RECAPTCHA_KEY ? 'GoogleReCaptcha' : null;
  const submitResults = useSelector(
    (state) => state.submitCustomerSatisfaction,
  );
  const [validToken, setValidToken] = useState(null);
  let fieldHoney = process.env.RAZZLE_HONEYPOT_FIELD;

  if (__CLIENT__) {
    fieldHoney = window.env.RAZZLE_HONEYPOT_FIELD;
  }

  const changeSatisfaction = (e, s) => {
    e.stopPropagation();
    e.preventDefault();

    if (s === satisfaction) {
      setSatisfaction(null);
    } else {
      setSatisfaction(s);
    }
  };

  const updateFormData = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  useEffect(() => {
    setSatisfaction(null);
    setValidToken(null);
    return () => {
      dispatch(resetSubmitCustomerSatisfaction());
    };
  }, [path]);

  useEffect(() => {
    updateFormData(
      'vote',
      satisfaction === true ? 'ok' : satisfaction === false ? 'nok' : null,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [satisfaction]);

  // initialized honeypot field
  useEffect(() => {
    updateFormData(fieldHoney, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldHoney]);

  const onVerifyCaptcha = useCallback(
    (token) => {
      if (satisfaction != null && !validToken) {
        setValidToken(token);
      }
    },
    [satisfaction, setValidToken, validToken],
  );

  const sendFormData = () => {
    dispatch(
      submitCustomerSatisfaction(path, {
        ...formData,
        ...(captcha && { 'g-recaptcha-response': validToken }),
      }),
    );
  };

  let action = path?.length > 1 ? path.replace(/\//g, '') : path;
  if (action?.length > 0) {
    action = action?.replace(/-/g, '_');
  } else {
    action = 'homepage';
  }

  if (isCmsUi(path)) {
    return null;
  }

  return (
    
    <Segment basic as="aside" className="customer-satisfaction d-print-none">
      <Grid  >
        <Grid.Row>
          <Grid.Column mobile={12} tablet={6} computer={4}>
            <h2 id="cs-radiogroup-label" className="customer-satisfaction__title">{intl.formatMessage(messages.title_satisfaction)}</h2>
            <p className="customer-satisfaction__desc">{intl.formatMessage(messages.title)}</p>
          </Grid.Column>
          <Grid.Column mobile={12} tablet={6} computer={8}>
            {!submitResults?.loading && !submitResults.loaded && (
              <Form
                onSubmit={() => {
                  sendFormData();
                }}
              >
                <div className="buttons" aria-labelledby="cs-radiogroup-label">
                  <Button
                   
                    onClick={(e) => {
                      changeSatisfaction(e, true);
                    }}
                    aria-controls="cs-more"
                    active={satisfaction === true}
                    className="customer-satisfaction__btn"
                  >
                    <Button.Content>
                      {intl.formatMessage(messages.yes)}
                    </Button.Content>
                    {/* {(satisfaction == null || satisfaction !== true) && (
                      <Button.Content visible className="customer-satisfaction__btn__icon">
                        <Icon name={ThumbsUp} size="1.5rem" />
                      </Button.Content>
                    )} */}
                  </Button>

                  <Button
                    onClick={(e) => {
                      changeSatisfaction(e, false);
                    }}
                    aria-controls="cs-more"
                    active={satisfaction === false}
                    className="customer-satisfaction__btn"
                  >
                    <Button.Content >
                      {intl.formatMessage(messages.no)}
                    </Button.Content>
                    {/* {(satisfaction == null || satisfaction !== false) && (
                      <Button.Content visible className="customer-satisfaction__btn__icon customer-satisfaction__btn__icon-no">
                        <Icon name={ThumbsDown} size="1.5rem" />
                      </Button.Content>
                    )} */}
                  </Button>
                </div>

                <div
                  id="cs-more"
                  role="region"
                  aria-expanded={satisfaction !== null}
                  aria-hidden={satisfaction === null}
                >
                  <div className="comment">
                    <label htmlFor="cs-comment-textarea">{intl.formatMessage(messages.comment_label)}</label>
                    <TextArea
                      id="cs-comment-textarea"
                      placeholder={intl.formatMessage(messages.suggestions_placeholder,)}
                      onChange={(e, v) => {
                        updateFormData('comment', v.value);
                      }}
                    />
                  </div>

                  <HoneypotWidget
                    updateFormData={updateFormData}
                    field={fieldHoney}
                  />
                  <GoogleReCaptchaWidget
                    key={action}
                    onVerify={onVerifyCaptcha}
                    action={action}
                  />

                  <div className="submit-wrapper">
                    <Button
                      type="submit"
                      content={intl.formatMessage(messages.submit)}
                      primary
                      disabled={captcha && !validToken}
                    />
                  </div>
                </div>
              </Form>
            )}
            {submitResults?.loading && <Loader active inline="centered" />}
            {submitResults?.loaded && (
              <Message positive>
                <p>{intl.formatMessage(messages.thank_you)}</p>
              </Message>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

  );
};

export default CustomerSatisfaction;
