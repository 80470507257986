import React from 'react';
import PropTypes from 'prop-types';
import { isInternalURL } from '@plone/volto/helpers';
import { flattenToAppURL } from '@plone/volto/helpers';
import { FormattedMessage, injectIntl } from 'react-intl';
import { UniversalLink, Image } from '@plone/volto/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../../'


const IconListing = ({ items, linkTitle, linkHref, isEditMode, blockTitle }) => {

	let href = (linkHref?.[0]?.['@id']) || '';
	let link = href ? flattenToAppURL(href) : '';

	return (
		<>
			{blockTitle && <h3 className="directLinks__title mt-0">{blockTitle}</h3>}
			<ul className="directLinks__list list-unstyled mt-0 mb-4">
				{items.map((item) => (
					<li key={item['@id']}>
						<UniversalLink
							className="directLinksItem"
						
							href={item?.getRemoteUrl ? item?.getRemoteUrl : item['@id']}>
							<span aria-hidden="true" className="directLinksItem__icon__wrapper">
								{item?.content_icon && (<Icon name={item.content_icon} className="directLinksItem__icon" />)}
							</span>
							{item.title || item.id}
						</UniversalLink>
					</li>
				))}
			</ul>

			{link &&
				<p className="readMoreItem mb-4 d-print-none">
					<UniversalLink href={link} className="readMoreItem__btn ui button primary">
						{linkTitle ? (<span>{linkTitle}</span>) :
							(<FormattedMessage id="See all" defaultMessage="See all" />)}
						<span className="visually-">{blockTitle}</span>
						<FontAwesomeIcon icon={faChevronRight} className="readMoreItem__icon ms-3" />
					</UniversalLink>
				</p>
			}
		</>
	);
}

IconListing.propTypes = {
	linkHref: PropTypes.arrayOf(PropTypes.any),
	items: PropTypes.arrayOf(PropTypes.any).isRequired,
	linkMore: PropTypes.any,
	isEditMode: PropTypes.bool,
};

export default IconListing;
