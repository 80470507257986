/**
 * EquipmentsListListing component.
 * @module components/Equipments/EquipmentsListListing
 */

import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Container, Header, Grid, Card, Form } from 'semantic-ui-react';
import { BodyClass, hasBlocksData } from '@plone/volto/helpers';
import { UniversalLink, Pagination, Image } from '@plone/volto/components';
import { searchContent, getVocabulary } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { keys, isEmpty, map } from 'lodash';
import { useDebounce } from "use-debounce";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag,faLocationDot } from '@fortawesome/free-solid-svg-icons';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { toBackendLang } from '@plone/volto/helpers/Utils/Utils';
import { EquipmentsList, EquipmentsMapListing } from './'

const messages = defineMessages({
	labelText: {
		id: 'By words',
		defaultMessage: 'By words',
	},
	placeholderText: {
		id: 'What are you looking for?',
		defaultMessage: 'What are you looking for?',
	},
	labelSubject: {
		id: 'By typology',
		defaultMessage: 'By typology',
	},
	placeholderAllTypologies: {
		id: 'All typologies',
		defaultMessage: 'All typologies',
	},
    defaultOptionTypologies: {
        id: 'All typologies',
        defaultMessage: 'All typologies', 
      }
    
});

const EquipmentsListListing = (props) => {
	const { content } = props;
	const lang = content.language.token;
	const dispatch = useDispatch();
	const intl = useIntl();
	const equipments = useSelector((state) => state.search.subrequests.equipments?.items);
	const total = useSelector((state) => state.search.subrequests.equipments?.total);
	const [currentPage, setCurrentPage] = useState(0);
	const [pageSize, setPageSize] = useState(12);
	const [search, setSearch] = useState(0);
	const [searchableTextInput, setSearchableText] = useState('');
	const [searchableText] = useDebounce(searchableTextInput, 500);
    const [placeTypology, setPlaceTypology] = useState('');


	const vocabularyTypologies = 'product.matadepera.vocabularies.PlaceTypologies';

	const itemsTypologies = useSelector(
		(state) =>
			state.vocabularies[vocabularyTypologies] &&
			state.vocabularies[vocabularyTypologies].items
				? [{ value: '', label: intl.formatMessage(messages.defaultOptionTypologies) }, ...state.vocabularies[vocabularyTypologies].items]
				: [],
		shallowEqual
	);

	const getTypology = function (Typology) {
		if (!itemsTypologies) {
			return Typology;
		}
		for (let item of itemsTypologies) {
			if (item.value === Typology) return item.label;
		}
		return Typology;
	};

	useEffect(() => {
		dispatch(getVocabulary({ vocabNameOrURL: vocabularyTypologies, lang: content.language.token }));
	}, [dispatch]);

	useEffect(() => {
		let contentFilter = {
			portal_type: ['Place'],
			b_size: pageSize,
			b_start: currentPage * pageSize,
			review_state: 'published',
			SearchableText: searchableText,
			metadata_fields: ['place_typologies']
		};
		
        if (placeTypology && placeTypology !== '') {
			contentFilter.place_typologies = placeTypology; 
		}
		
		dispatch(
			searchContent(
				flattenToAppURL(content['@id']),
				contentFilter,
				'equipments',
			),
		);
	}, [dispatch, currentPage, pageSize, searchableText, placeTypology, search]);

	useEffect(() => {
		const searchForm = document.querySelector('.searchList');
		const searchFormPos = searchForm ? searchForm.offsetTop : 0;
		let scrollPos = window.scrollY;
		if (scrollPos > searchFormPos && searchForm) {
			window.scrollTo({ top: searchFormPos })
		}
	}, [currentPage]);

	const onChangePage = (event, activePage) => {
		event.preventDefault();
		setCurrentPage(activePage.value);
	};

	const onChangePageSize = (event) => {
		event.preventDefault();
		setPageSize(parseInt(event.target.text));
		setCurrentPage(0);
	};

	const onChangeSearchableText = (event, data) => {
		event.preventDefault();
		setSearchableText(data.value);
		setCurrentPage(0);
	};

	const onChangeTypology = (event, data) => {
		event.preventDefault();
		setPlaceTypology(data.value);
		setCurrentPage(0);
	};

	const onSubmit = (event) => {
		event.preventDefault();
		setSearch(search + 1);
		setCurrentPage(0);
	};

	
	return (
		<>
			<BodyClass className='equipmentslisting' />

			{hasBlocksData(content) ? (
				<Container>
					<Grid>
						<Grid.Column width={12}>
							<RenderBlocks {...props} />
						</Grid.Column>
					</Grid>
				</Container>
			) : null}

			<Form className="searchList" onSubmit={onSubmit}>
				<Container className="pb-4 d-print-none">
					<Header as="h2" className='justify-content-center searchList__title' size='small' textAlign='center'><FormattedMessage id="Filter" defaultMessage="Filter" /></Header>
					<Form.Group widths="equal" className="align-items-end">
						<Form.Input
							id="textInputId"
							fluid
							label={intl.formatMessage(messages.labelText)}
							focus
							name="searchableText"
							value={searchableTextInput}
							onChange={onChangeSearchableText}
							placeholder={intl.formatMessage(messages.placeholderText)}
							type="text"
						/>
						<Form.Select
							id="placeTypologySelectId"
							fluid
							label={intl.formatMessage(messages.labelSubject)}
							options={map(itemsTypologies, (item, index) => {
								return {
									key: index,
									value: item.value,
									text: item.label,
								};
							})}
							name="tipology"
							value={placeTypology}
							onChange={onChangeTypology}
							placeholder={intl.formatMessage(messages.placeholderAllTypologies)}
							aria-label={intl.formatMessage(messages.labelSubject)}
						/>
					</Form.Group>
				</Container>
			</Form>

			<div className="bg-gray-200 py-5">
				{!isEmpty(equipments) ? (
					<Container className="equipments equipmentsListing">
						<Grid>
							<Grid.Row columns={12}>
								{equipments.map((item) => (
									<Grid.Column className="mb-4" mobile={12} tablet={6} computer={4} widescreen={3} key={item['@id']}>
										<Card fluid as="article" className={'equipmentsItem position-relative ' + (item?.hasPreviewImage ? ' equipmentsItem-hasImage' : ' equipmentsItem-noImage')}>
											{item?.hasPreviewImage &&
												<Image
													item={item}
													loading='lazy'
													alt=""
													className="equipmentsItem__img m-0 image"
													sizes="(max-width: 767px) 100vw, (max-width: 991px) 50vw, (max-width: 1599px) 33.33vw, 25vw"
												/>
											}
											<Card.Content className='equipmentsItem__text'>
												<Card.Header className="equipmentsItem__title" as="h2">
													<UniversalLink
														className="equipmentsItem__link stretched-link"
														href={flattenToAppURL(item['@id'])}>
														{item.title}
													</UniversalLink>
												</Card.Header>
												{item.description && (<Card.Description as="p" className="equipmentsItem__desc">{item.description}</Card.Description>)}
												{(item.location || item?.place_typologies && 
													<hr />
												)}
												{item.location && (<p className="equipmentsItem__location mb-2"><FontAwesomeIcon className='equipmentsItem__icon' icon={faLocationDot} /> {item.location}</p>)}
												{item?.place_typologies ? (
													<Card.Meta className="equipmentsItem__meta">
														<FontAwesomeIcon className='equipmentsItem__icon' icon={faTag} />
														<ul className="list-unstyled equipmentsItem__meta__list">
															{item.place_typologies.map((typology, index) => (
																<li key={index}>
																	<span className="equipmentsItem__typology">{getTypology(typology)}</span>
																</li>
															))}
														</ul>
													</Card.Meta>
												) : null}

											</Card.Content>
										</Card>
									</Grid.Column>
								))}
							</Grid.Row>
						</Grid>

						{total > pageSize && (
							<nav className="pagination">
								<Pagination
									current={currentPage}
									total={Math.ceil(total / pageSize)}
									onChangePage={onChangePage}
									onChangePageSize={onChangePageSize}
								/>
							</nav>
						)}

					</Container>
				) : (
					<Container className="equipments equipmentsListListing">
						<p><FormattedMessage id="No equipments found" defaultMessage="No equipments found" /></p>
					</Container>
				)}
			</div>
		</>
	);
};


export default EquipmentsListListing;
