/**
 * Attachments component.
 * @module components/Attachments/Attachments
 */

import React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Segment, Grid, Header, Container } from 'semantic-ui-react';
import { defineMessages, useIntl, FormattedMessage,  } from 'react-intl';
import { searchContent } from '@plone/volto/actions';
import { UniversalLink, Image } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faEye, faFileLines} from '@fortawesome/free-solid-svg-icons';
import { keys, isEmpty, map, filter } from 'lodash';
import { BodyClass } from '@plone/volto/helpers';
import Lightbox from '../Lightbox/Lightbox';


const Attachments = props => {
  const content = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const attachments = useSelector((state) => state.search.subrequests.attachments?.items || []);

  const files = useMemo(() => 
    filter(attachments, (item) => item['@type'] === 'File'), 
    [attachments]
  );

  const links = useMemo(() => 
    filter(attachments, (item) => item['@type'] === 'Link'), 
    [attachments]
  );

  const images = useMemo(() => 
    filter(attachments, (item) => item['@type'] === 'Image'), 
    [attachments]
  );

  useEffect(() => {
    dispatch(
      searchContent(
        flattenToAppURL(content['@id']),
        {
          portal_type: ['Image', 'File', 'Link'],
          sort_on: 'getObjPositionInParent',
          metadata_fields: ['getObjSize', 'mime_type']
        },
        'attachments',
      ),
    );
  }, [dispatch]);

  return (!isEmpty(attachments)) ? (
    <>
      <BodyClass className='hasRelatedContent'/>
      <Segment as="section" basic id="relatedContent" className="relatedContent px-0">
 
            {/* <Header as="h2" size="huge" className="relatedContent__title" ><FormattedMessage id="Related content" defaultMessage="Related content"/></Header> */}

            {!isEmpty(files) && (
              <Segment as="section" basic className="relatedContent__section">
                <Header as="h2" size="small" className="relatedContent__title"><FormattedMessage id="Documents" defaultMessage="Documents"/></Header>
                <Grid columns={12}>
                  <Grid.Row>
                    {map(files, (item, index) => (
                      <Grid.Column key={index} mobile={12} tablet={6} computer={6} largeScreen={4} widescreen={4}>
                        <UniversalLink
                          className="relatedItem" 
                          href={flattenToAppURL(item['@id'])}>
                          <span className="relatedItem__icon" > 
                            <FontAwesomeIcon icon={faFileLines} />
                          </span>
                          <span className="relatedItem__text">{item.title}</span>
                          <span className="relatedItem__size">{item.getObjSize}</span>
                          {/* <span className="relatedItem__size"><FormattedMessage id="Format" defaultMessage="Format"/>: {item.mime_type.split('/').pop()} - {item.getObjSize}</span> */}
                          {/* TODO: Amago el tipus de document perque no surt be */}
                        </UniversalLink>
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                </Grid>
              </Segment>
            )}

            {!isEmpty(links) && (
              <Segment as="section" basic className="relatedContent__section">
                <Header as="h2" size="small" className="relatedContent__title"><FormattedMessage id="Links" defaultMessage="Links"/></Header>
                <Grid columns={12}>
                  <Grid.Row >
                    {map(links, (item, index) => (
                      <Grid.Column key={index} mobile={12} tablet={6} computer={6} largeScreen={4} widescreen={4}>
                        <UniversalLink
                          openLinkInNewTab
                          className="relatedItem" 
                          href={flattenToAppURL(item['@id'])}>
                          <span className="relatedItem__icon" >
                            <FontAwesomeIcon icon={faLink} /> 
                          </span>
                          <span className="relatedItem__text relatedItem__text-links">{item.title}</span>
                        </UniversalLink>
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                </Grid>
              </Segment>
            )}

            {!isEmpty(images) && (
              <Segment as="section" basic className="relatedContent__section relatedMedia">
                <Header as="h2" size="small" className="relatedContent__title"><FormattedMessage id="Media" defaultMessage="Media"/></Header>
                <Lightbox images={images} />
              </Segment>
            )}
 
      </Segment>
    </>
  ) : (
    <></>
  );
};

export default Attachments;