import React, { useState } from 'react';
import EventsListListing from './EventsListListing';
import EventsMapListing from './EventsMapListing';
import { FormattedMessage } from 'react-intl';
import { Container, Button } from 'semantic-ui-react';
import { Icon } from '../';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faGrip } from '@fortawesome/free-solid-svg-icons';

const EventsListing = (props) => {
	const { content } = props;
	const [activeViewMode, setActiveViewMode] = useState('list');

	const handleViewModeChange = (newViewMode) => {
		setActiveViewMode(newViewMode);
	};

	return (
		<>
			<Container className='text-center mb-3'>
				<div className="view-mode-switcher">
					{activeViewMode === 'list' && (
						<Button primary
							className="clickable-link d-print-none"
							onClick={() => handleViewModeChange('map')}
						>
							<FontAwesomeIcon icon={faMap} className='me-3' />
							<FormattedMessage
								id="Or also view the nearby activity map"
								defaultMessage="O tambè podeu visualitzar el mapa d'activitats properes"
							/>
						</Button>
					)}

					{activeViewMode === 'map' && (
						<Button primary
							className="clickable-link" 
							onClick={() => handleViewModeChange('list')}
						>
							<FontAwesomeIcon icon={faGrip} className='me-3' />
							<FormattedMessage
								id="Go back to events list"
								defaultMessage="Tornar al llistat d'activitats"
							/>
						</Button>
					)}
				</div>
			</Container>


			{activeViewMode === 'list' && <EventsListListing content={content} />}
			{activeViewMode === 'map' && <EventsMapListing content={content} />}
		
		</>
	);
};


export default EventsListing;
