const defaultPanelConfig = {
  last_updated: '2022-01-11T10:36:28+00:00', //it is used to know whether to resubmit the banner to the user if the choices have changed.
  focusTrapEnabled: false,
  text: {
    en: {
      title: 'We use cookies',
      description:
        "This site uses technical navigation and session cookies to ensure a better site navigation service, and analytical cookies to collect information on the use of the site by users. It also uses user profiling cookies for statistical and remarketing purposes. For profiling cookies you can decide whether to enable them or not by clicking on the 'Change settings' button. To find out more, on how to disable cookies or enable only some of them, consult our <a href='/privacy-policy' target='_blank'>Cookie Policy</a>.",
    },
    es: {
      title: 'Usamos cookies',
      description:
        "Este sitio utiliza cookies técnicas de navegación y de sesión para garantizar un mejor servicio de navegación en el sitio, y cookies analíticas para recopilar información sobre el uso del sitio por parte de los usuarios. También utiliza cookies de elaboración de perfiles de usuario con fines estadísticos y de remarketing. Para las cookies de perfil, puede decidir si habilitarlas o no haciendo clic en el botón 'Cambiar configuración'. Para obtener más información sobre cómo deshabilitar las cookies o habilitar solo algunas de ellas, consulte nuestra <a href='/politica-de-cookies' target='_blank'>Política de Cookies</a>.",
    },
    ca: {
      title: 'Utilitzem cookies',
      description:
        "Aquest lloc utilitza cookies tècniques de navegació i sessió per garantir un millor servei de navegació del lloc, i cookies analítiques per recollir informació sobre l'ús del lloc per part dels usuaris. També utilitza cookies de perfil d'usuari amb finalitats estadístiques i de remàrqueting. Per a les galetes de perfil, podeu decidir si les habiliteu o no fent clic al botó 'Canvia la configuració'. Per obtenir més informació sobre com desactivar les galetes o activar només algunes d'elles, consulteu la nostra <a href='/politica-de-cookies' target='_blank'>Política de cookies</a>.",
    },
  },

  //technical cookies configurations
  technical: {
    //main title and text for the technical cookies column in banner-cookies-settings
    text: {
      en: {
        title: 'Technical cookies',
        description:
          'The site uses technical cookies to analyze traffic to and from the site. Technical cookies also allow us to provide a better navigation service on the site, and collect navigation information for this purpose.',
      },
      es: {
        title: 'Cookies técnicas',
        description:
          'El sitio utiliza cookies técnicas para analizar el tráfico hacia y desde el sitio. Las cookies técnicas también nos permiten ofrecer un mejor servicio de navegación en el sitio y recopilar información de navegación para este fin.',      },
      ca: {
        title: 'Cookies tècniques',
        description:
          'El lloc utilitza cookies tècniques per analitzar el trànsit cap a i des del lloc. Les cookies tècniques també ens permeten oferir un millor servei de navegació al lloc, i recopilar informació de navegació amb aquesta finalitat.',      },
    },

    //technical cookies
    choices: [
      {
        config_key: 'TECHNICALS',
        text: {
          en: {
            title: 'Strictly necessary',
            description:
                'Cookies strictly necessary for the operation of the website. These cookies ' +
                'cannot be rejected and are responsible, among other things, for allowing ' +
                'the identification of a registered user or for saving the configuration of cookies.'
          },
          es: {
            title: 'Estrictamente necesarias',
            description:
                'Cookies estrictamente necesarias para el funcionamiento del sitio web. ' +
                'Estas cookies no pueden rechazarse y son las encargadas entre otras finalidades ' +
                'de permitir la identificación de un usuario registrado o guardar la configuración de cookies.'
          },
          ca: {
            title: 'Estrictament necessàries',
            description:
                'Cookies estrictament necessàries per al funcionament del lloc web.' +
                'Aquestes cookies no poden rebutjar-se i són les encarregades entre altres finalitats ' +
                'de permetre la identificació d\'un usuari registrat o desar la configuració de cookies.'
          },
        },
      },
    ],
  },

  //profiling cookies configuration
  profiling: {
    text: {
      en: {
        title: 'Profiling cookies',
        description:
          "The site uses profiling cookies to analyze user behavior and choices in order to propose targeted content corresponding to the user's profile",
      },
      es: {
        title: 'Cookies estadísticas y de personalización',
        description:
          "El sitio utiliza cookies de creación de perfiles para analizar el comportamiento y las elecciones del usuario con el fin de proponer contenidos específicos correspondientes al perfil del usuario",
      },
      ca: {
        title: 'Cookies estadístiques i de personalització',
        description:
          "El lloc utilitza galetes de perfil per analitzar el comportament i les opcions de l'usuari amb la finalitat de proposar contingut orientat corresponent al perfil de l'usuari",      },
    },

    choices: [
      {
        config_key: 'GANALYTICS', //reference to config.gdprPrivacyConfig keys
        text: {
          en: {
            title: 'Google Analytics',
            description:
              'Google Analytics cookies are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
          },
          es: {
            title: 'Google Analytics',
            description:
              'Las cookies de Google Analytics se utilizan para analizar la navegación en el sitio con el fin de mejorarla y proporcionar al usuario la mejor experiencia de navegación posible.',
          },
          ca: {
            title: 'Google Analytics',
            description:
              "Les cookies de Google Analytics s'utilitzen per analitzar la navegació al lloc amb la finalitat de millorar-la i oferir a l'usuari la millor experiència de navegació possible.'",
          },
        },
      },
      {
        config_key: 'GOOGLEMAPS',
        referenceUrls: ['google.com/maps'],
        text: {
          en: {
            title: 'Google Maps',
            description:
              'Las cookies de perfiles de Google le permiten analizar el acceso a la página y el comportamiento del usuario, y mostrarle los anuncios que más pueden interesarle.',
            conditional_embed_text:
              'To view map, please accept Google Maps cookies.',
          },
          es: {
            title: 'Google Maps',
            description:
              "Les galetes de perfil de Google us permeten analitzar l'accés a la pàgina i el comportament dels usuaris, i mostrar-vos els anuncis que més us poden interessar.",
            conditional_embed_text:
              'Para ver el mapa, acepte las cookies de Google Maps.',
          },
          ca: {
            title: 'Google Maps',
            description:
              "Les galetes de perfil de Google us permeten analitzar l'accés a la pàgina i el comportament dels usuaris, i mostrar-vos els anuncis que més us poden interessar.",
            conditional_embed_text:
              'Per veure el mapa, accepteu les galetes de Google Maps.',
          },
        },
      },
      {
        config_key: 'YOUTUBE',
        referenceUrls: ['youtube.com', 'youtube-nocookie.com', 'youtu.be'],
        text: {
          en: {
            title: 'Youtube',
            description:
              'Youtube profiling cookies allow you to show advertisements that may interest you the most, analyze page access and user behavior, facilitate access to Google services. ',
            conditional_embed_text:
              'To view the video, please accept Youtube cookies.',
          },
          es: {
            title: 'Youtube',
            description:
              'Las cookies de elaboración de perfiles de Youtube le permiten mostrar los anuncios que más pueden interesarle, analizar el acceso a las páginas y el comportamiento del usuario, facilitar el acceso a los servicios de Google.',
            conditional_embed_text:
              'Para ver el vídeo, por favor acepte las cookies de Youtube.',
          },
          ca: {
            title: 'Youtube',
            description:
              "Les galetes de perfil de YouTube permeten mostrar anuncis que més et poden interessar, analitzar l'accés a la pàgina i el comportament dels usuaris, facilitar l'accés als serveis de Google.",
            conditional_embed_text:
              'Per veure el vídeo, accepteu les galetes de Youtube.',
          },
        },
      },
    ],
  },
};

export default defaultPanelConfig;
