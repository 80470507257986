import React from 'react';
import { useEffect } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Header, Container } from 'semantic-ui-react';
import { searchContent } from '@plone/volto/actions';
import { UniversalLink, Image } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { keys, isEmpty } from 'lodash';
import { Carousel } from '../';


const SLIDE_OPTIONS = {
    loop: false,
    autoplay: false,
    dots: false,
    buttons: true,
    customClass: 'banners-slider',
    stopOnMouseEnter: true
}

const ItemView = props => {
    const { item } = props

    return (
        <>
        {item.getRemoteUrl ? (
        <UniversalLink href={item.remoteUrl} className=" bannerItem " key={item['@id']}>
            <Image
                item={item}
                className="bannerItem__image image ui"
                alt={item.title}
                sizes="(max-width: 767px) 100vw, (max-width: 991px) 25rem, (max-width: 1279px) 20rem, 25rem"
            />
        </UniversalLink>
        ) : (
        <UniversalLink href={flattenToAppURL(item['@id'])} className=" bannerItem " key={item['@id']}>
            <Image
                item={item}
                className="bannerItem__image image ui"
                alt={item.title}
                sizes="(max-width: 767px) 100vw, (max-width: 991px) 25rem, (max-width: 1279px) 20rem, 25rem"
            />
        </UniversalLink>
        )}
        </>
    )

};

const Banners = (props) => {
  const dispatch = useDispatch();
  const banners = useSelector((state) => state.search.subrequests.banners?.items);

  useEffect(() => {
    dispatch(
      searchContent(
        '/portada/banners',
        {
            portal_type: ['Banner'],
            sort_on: 'getObjPositionInParent',
            metadata_fields: ['getRemoteUrl', 'remoteUrl']
        },
        'banners',
      ),
    );
  }, [dispatch]);

    return (
        <>
        {!isEmpty(banners) && (
        <Segment as="section" className="bannersFooter px-0 d-print-none" basic >

            <Container>

                <Header as="h2" className='bannersFooter__title'>
                    <FormattedMessage id="Tambe et pot interessar" defaultMessage='Tambe et pot interessar' />
                </Header>

                <Carousel slides={banners.slice(0, 8)} ItemView={ItemView} options={SLIDE_OPTIONS} />

            </Container>

        </Segment>
        )}
        </>
    );
};


export default Banners;