import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  LinkTitle: {
    id: 'Link title',
    defaultMessage: 'Link title',
  },
  LinkTo: {
    id: 'Link to',
    defaultMessage: 'Link a',
  },
});


export const schema = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'linkTitle', 'linkHref'],
      },
    ],
    properties: {
      title: {
        title: props.intl.formatMessage(messages.title),
        widget: 'text',
      },
      linkTitle: {
        title: props.intl.formatMessage(messages.LinkTitle),
      },
      linkHref: {
        title: props.intl.formatMessage(messages.LinkTo),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description'],
        allowExternals: true,
      },
    },
  };
};

export default schema;