import contentExistingSVG from '@plone/volto/icons/content-existing.svg';
import linkSVG from '@plone/volto/icons/link.svg';
import calendarSVG from '@plone/volto/icons/calendar.svg';
import folderSVG from '@plone/volto/icons/folder.svg';
import fileSVG from '@plone/volto/icons/file.svg';
import pageSVG from '@plone/volto/icons/page.svg';
import imageSVG from '@plone/volto/icons/image.svg';
import minisiteSVG from '@plone/volto/icons/add-on.svg';
import bannerSVG from '@plone/volto/icons/image-wide.svg';
import iconSVG from '@plone/volto/icons/flag.svg';
import locationSVG from '@plone/volto/icons/map.svg';
import slideSVG from '@plone/volto/icons/presentation.svg';

export const contentIcons = {
  Document: pageSVG,
  Folder: folderSVG,
  'News Item': contentExistingSVG,
  Event: calendarSVG,
  Image: imageSVG,
  File: fileSVG,
  Link: linkSVG,
  Minisite: minisiteSVG,
  Banner: bannerSVG,
  Icon: iconSVG,
  Place: locationSVG,
  Slide: slideSVG,
};