import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { NavigationMinisite } from './'

import imgHeader from './default_header.jpg';
import { getHeaderInfo } from '../../actions';
import { Breadcrumbs } from '@plone/volto/components';
import { getBaseUrl, hasApiExpander } from '@plone/volto/helpers';
import { getBreadcrumbs } from '@plone/volto/actions';


const HeaderMinisite = ({ pathname }) => {
    const dispatch = useDispatch();
    const { pathname: realPath } = useLocation();

    const title = useSelector((state) => state?.content?.data?.title);
    const customRoot = useSelector((state) => state.navroot.data?.custom_root);

    const headerImage = useSelector((state) => state?.header_info?.header_info?.header_image);
    const headerImageSrc = headerImage?.image_field != 'none' && headerImage['@id'] + '/@@images/' + headerImage.image_field + '/slide'

    useEffect(() => {
        if (!hasApiExpander('header_info', getBaseUrl(pathname))) {
          dispatch(getHeaderInfo(getBaseUrl(pathname)));
        }
    }, [dispatch, pathname]);

    function hexToRgb(hex) {
      hex = hex.replace(/^#/, '');

      if (hex.length === 3) {
        hex = hex.split('').map(function (hexChar) {
          return hexChar + hexChar;
        }).join('');
      }
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;

      return {red: r, green: g, blue: b};
    }

    const getColor = function (customRoot) {
        if (! customRoot?.content_color ) {
            return {red: 232, green: 70, blue: 0}
        }
        return hexToRgb(customRoot.content_color)
    }
    const color = getColor(customRoot)

    // Set minisite color
    useEffect(() => {
        // Set the custom property on the :root element
        document.documentElement.style.setProperty('--mdp-minisiteColor-red', color.red);
        document.documentElement.style.setProperty('--mdp-minisiteColor-green', color.green);
        document.documentElement.style.setProperty('--mdp-minisiteColor-blue', color.blue);
      }, []);

    return (
        <div className="headMinisite" >
            <div className='headMinisite__title__wrapper'>

                {headerImage?.image_field != 'none' ? (
                    <img src={headerImageSrc} alt="" className='headMinisite__title__img' />
                ) : (
                    <img src={imgHeader} alt="" className='headMinisite__title__img' />
                )}

                {title && (
                    <h1 className='headMinisite__title mt-0'>{customRoot?.title}</h1>
                )}
            </div>
            <NavigationMinisite />
            {customRoot?.title ? ( 
              <>
                {title != customRoot.title && <Breadcrumbs  /> } 
              </>
            ) : (
              <></>
            )}
        </div>
    )
};

export default HeaderMinisite;