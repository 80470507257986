/*
 * TitleView & description Container.
 */
import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'semantic-ui-react';
import { getView } from '../../helpers';


const TitleView = ({ pathname }) => {
    const action = getView(pathname);
    const title = useSelector((state) => state?.content?.data?.title);
    const description = useSelector((state) => state?.content?.data?.description);
    const showTitle = action === 'view' ? true : false

    return showTitle ? (
        <Container>
            {title && (
                <h1 className="documentFirstHeading">{title}</h1>
            )}
            {description && (
                <p className="documentDescription">{description}</p>
            )}
        </Container>
    ) : (<></>);
};

export default TitleView;