/**
 * Document view component.
 * @module components/theme/View/ListingView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Container as SemanticContainer } from 'semantic-ui-react';
import config from '@plone/volto/registry';

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink, Pagination } from '@plone/volto/components';

/**
 * List view component class.
 * @function ListingView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ListingView = ({ content }) => {
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;
  const PreviewImage = config.getComponent({ name: 'PreviewImage' }).component;

  const dispatch = useDispatch();
  const items = useSelector((state) => state.search.subrequests.items?.items);
  const total = useSelector((state) => state.search.subrequests.items?.total);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);

  useEffect(() => {
    let contentFilter = {
      b_size: pageSize,
      b_start: currentPage * pageSize,
      sort_on: 'getObjPositionInParent',
    };
    contentFilter['path.depth'] = 1
    dispatch(searchContent(flattenToAppURL(content['@id']), contentFilter, 'items'));
  }, [dispatch, currentPage, pageSize]);

  const onChangePage = (event, activePage) => {
    event.preventDefault();
    setCurrentPage(activePage.value);
  };

  const onChangePageSize = (event) => {
    event.preventDefault();
    setPageSize(parseInt(event.target.text));
    setCurrentPage(0);
  };

  return (
    <Container id="page-home">
      <div id="content-core">
        {items &&
          items.map((item, index) => (
          <Segment key={item.url} className="listing-item">
            <Container>
              <h2>
                <UniversalLink item={item} title={item['@type']}>
                  {item.title}
                </UniversalLink>
              </h2>
              {item.description && <p>{item.description}</p>}
            </Container>
            {item.image_field && (
              <PreviewImage
                item={item}
                alt={item?.image_caption ? item.image_caption : ''}
                className="ui image"
                responsive={true}
                loading="lazy"
              />
            )}
          </Segment>
        ))}

        {total > pageSize && (
          <nav className="pagination">
            <Pagination
              current={currentPage}
              total={Math.ceil(total / pageSize)}
              onChangePage={onChangePage}
              onChangePageSize={onChangePageSize}
            />
          </nav>
        )}

      </div>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ListingView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
        '@type': PropTypes.string,
        description: PropTypes.string,
        review_state: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default ListingView;
