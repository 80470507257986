import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Segment, Header, Button, Form, Input, Container, Label } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const messages = defineMessages({
	labelText: {
		id: 'Cerca per paraules',
		defaultMessage: 'Cerca per paraules',
	},
	placeholderText: {
		id: 'Introdueix un terme per a la cerca',
		defaultMessage: 'Introdueix un terme per a la cerca',
	},
});

const View = (props) => {
	const { data, mode, content } = props;
	const intl = useIntl();
  	const navroot = useSelector((state) => state.navroot?.data);
  	const navRootPath = flattenToAppURL(navroot?.navroot?.['@id']) || '';
  	const [text, setText] = useState('');

  	const history = useHistory();
 	const onChangeText = (event, { value }) => {
    	setText(value);
  	};

  	const onSubmit = (event) => {
    	history.push(`${navRootPath}/search?SearchableText=${encodeURIComponent(text)}`);
    	// reset input value
    	setText('');
    	event.preventDefault(); 
  	};

	return (
		<div className='basicSearch__wrapper full-width d-print-none'>
			<Segment as="section" className="basicSearch " basic>

				<Container>

					{data?.title ? (
						<Header as="h2" className='basicSearch__title'>{data.title}</Header>
					) : (
						<Header as="h2" className='visually-hidden'><FormattedMessage id="En què et podem ajudar?" defaultMessage="En què et podem ajudar?" /></Header>
					)}

					<Form className="basicSearch__group" action={`${navRootPath}/search`} onSubmit={onSubmit}>
						<label htmlFor="front-page-search" className='visually-hidden'>{intl.formatMessage(messages.labelText)}</label>
						<Input
							id="front-page-search"
							type='search'
							className='basicSearch__input'
							onChange={onChangeText}
							placeholder={intl.formatMessage(messages.placeholderText)} />
						<Button className='basicSearch__btn' primary >
							<FontAwesomeIcon icon={faMagnifyingGlass} />
							<span  className="visually-hidden" ><FormattedMessage id="Cercar" defaultMessage="Cercar" /></span>							
						</Button>
					</Form>

				</Container>

			</Segment>

		</div>
	);
};

export default View;