import { useDispatch  } from 'react-redux';
import { Segment, Header, Container } from 'semantic-ui-react';
import { FormattedMessage, injectIntl, defineMessages, useIntl } from 'react-intl';
import { isEmpty } from 'lodash';



const View = (props) => {
	const { data, mode, content } = props;
	const intl = useIntl();
	const dispatch = useDispatch();

	return (
		<div className='full-width phones__wrapper'>
			<Segment id="phones" as="section" basic className='phones' >

				{!isEmpty(data.telephones) && (
					<Container>
						<Header as="h2" className='phones__title'><FormattedMessage id="Telèfons d'interès" defaultMessage="Telèfons d'interès" /></Header>
						<dl className='phones__list'>
							{data.telephones.map((item, index) => (
								<React.Fragment key={index}>
									<dt className='phones__ph'>{item.title}</dt>
									<dd className='phones__nu'><a href={'tel:' + item.telephone?.replace(/\s+/g, '')}>{item.telephone}</a></dd>
								</React.Fragment>
							))}
						</dl>
					</Container>
				)}

			</Segment>
		</div>
	);
};

export default View;
