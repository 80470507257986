import { defineMessages } from 'react-intl';


const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  categoryTramits: {
    id: 'Category',
    defaultMessage: 'Category',
  },
});

export const schema = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'category'],
      },
    ],
    properties: {
      title: {
        title: props.intl.formatMessage(messages.title),
        widget: 'text',
      },
      category: {
          title: props.intl.formatMessage(messages.categoryTramits),
          type: 'choice',
          vocabulary: {
              '@id': '/@vocabularies/product.matadepera.vocabularies.TramitSubjects',
          },
      },
    },
  };
};

export default schema;