import react from 'react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Segment, Card, Header, Grid, Label, Container } from 'semantic-ui-react';
import { UniversalLink, PreviewImage } from '@plone/volto/components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getVocabulary, searchContent } from '@plone/volto/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faRss } from '@fortawesome/free-solid-svg-icons';
import { flattenToAppURL } from '@plone/volto/helpers';
import { keys, isEmpty, map, filter } from 'lodash';
import { formatDate } from '../../../helpers'


const View = (props) => {
	const { data, mode, content } = props;
	let href = (data.linkHref?.[0]?.['@id']) || '';
	let link = href ? flattenToAppURL(href) : '';

	const vocabularySubjects = 'plone.app.vocabularies.Keywords';
	const dispatch = useDispatch();
	const news = useSelector((state) => state.search.subrequests.news?.items);
	const newsFeatured = useSelector((state) => state.search.subrequests.news_featured?.items);
	const itemsSubjects = useSelector(
		(state) =>
			state.vocabularies[vocabularySubjects] && state.vocabularies[vocabularySubjects].items
				? [...state.vocabularies[vocabularySubjects].items]
				: [],
		shallowEqual,
	);
	const getNews = function (news, newsFeatured) {
		if (news === undefined || newsFeatured === undefined) {
			return [{}, []]
		}
		if (isEmpty(newsFeatured)) {
			return [news[0], news.slice(1, 4)]
		}

		const sortedNews = [...filter(news, (item) => item.UID !== newsFeatured[0].UID)]
		return [newsFeatured[0], sortedNews.slice(0, 3)]
	}
	const [featuredNew, filteredNews] = getNews(news, newsFeatured)

	useEffect(() => {
		let contentFilter = {
			portal_type: ['News Item'],
			b_size: 4,
			review_state: 'published',
			sort_on: 'effective',
			sort_order: 'reverse',
			metadata_fields: ['effective', 'hasPreviewImage', 'UID', 'Subject']
		};
        contentFilter['effective.query'] = [moment().format('YYYY/MM/DD HH:mm')]
        contentFilter['effective.range'] = 'max'
		let contentFilterFeatured = {
			...contentFilter,
			featured: true,
			b_size: 1,
		}
		dispatch(searchContent(link, contentFilter, 'news'));
		dispatch(searchContent(link, contentFilterFeatured, 'news_featured'));
		dispatch(getVocabulary({ vocabNameOrURL: vocabularySubjects }));
	}, [dispatch]);

	const getNewsItemSubject = function (subject) {
		if (!itemsSubjects) {
			return subject
		}
		for (let item of itemsSubjects) {
			if (item.value === subject) return item.label
		}
		return subject
	}

	const showRss = false;

	return (
		<div className="full-width newsHome__wrapper">

			<Segment as="section" className="newsHome px-0" basic>

				<Container>

					{data?.title ? (
						<div className="newsHome__title">
							<Header as="h2">{data.title}</Header>
							{showRss && (
							<UniversalLink href="#" className="eventsHome__rss">
								<FontAwesomeIcon icon={faRss} />
								<abbr className='visually-hidden' title='Really Simple Syndication' lang="en">RSS</abbr>
							</UniversalLink>
							)}
						</div>
					) : (
						<Header as="h2" className='visually-hidden'><FormattedMessage id="Notícies" defaultMessage="Notícies" /></Header>
					)}

					{featuredNew && (
						<Card as="article" className="newsItem newsItem--featured position-relative">
							{featuredNew.image_field && (
								<PreviewImage
									className="newsItem__img m-0 image"
									item={featuredNew}
									alt={featuredNew.image_caption ? featuredNew.image_caption : ''}
								/>
							)}
							<Card.Content className="newsItem__text">
								<Card.Header as="h3" className="newsItem__title">
									<UniversalLink href={flattenToAppURL(featuredNew['@id'])} className="newsItem__link stretched-link">{featuredNew.title}</UniversalLink>
								</Card.Header>
								<Card.Description as="p" className="newsItem__desc">{featuredNew.description}</Card.Description>
								<Card.Meta className="newsItem__meta">
									{featuredNew?.Subject && (
										<ul className="newsItem__meta__list list-unstyled mt-0 mb-2">
											{map(featuredNew.Subject.slice(0, 1), (item, index) => (
												<li key={item}>
													<span className="newsItem__subject">{getNewsItemSubject(item)}</span>
												</li>
											))}
										</ul>
									)}
									<time className="newsItem__date" dateTime={featuredNew.effective}>{formatDate(featuredNew.effective)}</time>
								</Card.Meta>
							</Card.Content>
						</Card>
					)}

					<Grid columns={12}>

						{!isEmpty(filteredNews) && (
							<>
								{filteredNews.map((item, index) => (
									<Grid.Column mobile={12} tablet={6} computer={4} widescreen={4} key={item['@id']} >

										<Card fluid as="article" className="newsItem position-relative">
											{item.image_field && (
												<PreviewImage
													className="newsItem__img m-0 image"
													item={item}
													alt={item.image_caption ? item.image_caption : ''}

												/>
											)}
											<Card.Content >
												<Card.Header as="h3" className="newsItem__title">
													<UniversalLink href={flattenToAppURL(item['@id'])} className="newsItem__link stretched-link">{item.title}</UniversalLink>
												</Card.Header>
												<Card.Description as="p" className="newsItem__desc">{item.description}</Card.Description>
												<Card.Meta className=" newsItem__meta">													
													{item?.Subject && (
														<ul className='newsItem__meta__list list-unstyled mt-0 mb-2'>
															{map(item.Subject.slice(0, 1), (item, index) => (
																<li key={item}>
																	<span className="newsItem__subject">{getNewsItemSubject(item)}</span>
																</li>
															))}
														</ul>
													)}
													<time className="newsItem__date" dateTime={item.effective}>{formatDate(item.effective)}</time>
												</Card.Meta>
											</Card.Content>
										</Card>
									</Grid.Column>
								))}
							</>
						)}

						{(isEmpty(filteredNews) && !featuredNew) && (
							<Grid.Column mobile={12}>
								<p><FormattedMessage id="No news found" defaultMessage="No news found" /></p>
							</Grid.Column>
						)}
					</Grid>

					{link &&
						<p className="pt-5 mb-4 text-center d-print-none">
							<UniversalLink href={link} className="readMoreItem button ui primary">
								<span className="readMoreItem__text">
									{data.linkTitle ? (<span>{data.linkTitle}</span>) :
										(<FormattedMessage id="See all" defaultMessage="See all" />)}
								</span>
								<FontAwesomeIcon icon={faAngleRight} className="readMoreItem__icon ms-3" />
							</UniversalLink>
						</p>
					}
				</Container>

			</Segment>
		</div>
	);
};

export default injectIntl(View);
