import { 
  // Views
  Frontpage,
  NewsListing,
  EventsListing,
  EquipmentsListing,
  EquipmentView,
  BlogView2,
  BlogUnfolded,
  PublicationsView,
  MinisiteView,
  // Blocks
  IconListingBlock,
  SliderListingBlock,
  NewsListingBlock,
  EventsListingBlock,
  EquipmentsListingBlock,
  NewsBlockView,
  NewsBlockEdit,
  CalendarViewView,
  CalendarViewEdit,
  EventsBlockView,
  EventsBlockEdit,
  TramitsBlockView,
  TramitsBlockEdit,
  OnLiveBlockView,
  OnLiveBlockEdit,
  UsefulInfoBlockView,
  UsefulInfoBlockEdit,
  PhonesBlockView,
  PhonesBlockEdit,
  SocialNetworksBlockView,
  SocialNetworksBlockEdit,
  BasicSearchBlockView,
  BasicSearchBlockEdit,
  ImageView,
  ImageEdit,
  ImportTramitsForm,
  // Widgets
  IconSelectorWidget,
  ResultRenderer,
  SimpleColorPicker,
  LeafletWidget,
  AdvancedVocabularyTermWidget,
  AttachedImageWidget,
  DatetimeUTCWidget,
  // Icons
  ecityclicIconsList,
  brandsIconsList,
} from './components';

import {
  defaultPanelConfig
} from './config';
import { getImageBlockSizes } from '@plone/volto/components/manage/Blocks/Image/utils';
import ImageSettingsSchema from '@plone/volto/components/manage/Blocks/Image/LayoutSchema';
// Icons
import cardsSVG from '@plone/volto/icons/cards.svg';
import commentsSVG from '@plone/volto/icons/comment.svg'; 
import calendarSVG from '@plone/volto/icons/calendar.svg';
import computerSVG from '@plone/volto/icons/computer.svg';
import videoSVG from '@plone/volto/icons/video.svg';
import cameraSVG from '@plone/volto/icons/camera.svg';
import infoSVG from '@plone/volto/icons/info.svg';
import phoneSVG from '@plone/volto/icons/mobile.svg';
import zoomSVG from '@plone/volto/icons/zoom.svg';
// Widgets
import { UrlWidget } from '@plone/volto/components/manage/Widgets';

import reducers from './reducers';
import rssMiddleware from './middleware/rss';

const applyConfig = (config) => {
  // Language
  config.settings.isMultilingual = false;
  config.settings.defaultLanguage = 'ca';
  config.settings.supportedLanguages = ['en', 'ca'];

  // Navigation
  config.settings.navDepth = 2;

  // Opengraph image
  config.settings.contentMetadataTagsImageField = 'preview_image';

  // Hide tags
  config.settings.showTags = false;

  // Page size
  config.settings.defaultPageSize = 30;

  // Map
  config.settings.mapCenter = [41.615410240341134, 2.0106697082519536]

  //Links
  config.settings.openLinkInNewTab = true;

  // Allow to delete title block
  config.blocks.requiredBlocks = [];

  // Cookie Consent
  config.settings['volto-gdpr-privacy'] = {
    ...config.settings['volto-gdpr-privacy'],
    defaultPanelConfig: defaultPanelConfig,
  };

  // Reducers
  config.addonReducers = {
    ...config.addonReducers,
    ...reducers,
  };

  // Views
  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      'Plone Site': Frontpage,
      Place: EquipmentView,
      Minisite: MinisiteView,
    },
    layoutViews: {
      ...config.views.layoutViews,
      news_listing_view: NewsListing,
      events_listing_view: EventsListing,
      equipments_listing_view: EquipmentsListing,
      blog_view2: BlogView2,
      blog_unfolded: BlogUnfolded,
      publications_view: PublicationsView,
    },
    layoutViewsNamesMapping: {
      ...config.views.layoutViewsNamesMapping,
      news_listing_view: 'News listing',
      events_listing_view: 'Events listing',
      equipments_listing_view: 'Equipments listing',
      blog_view2: 'BlogView2',
      blog_unfolded: 'BlogUnfolded',
      publications_view: 'PublicationsView'
    },
  };

  // Blocks
  config.blocks.groupBlocksOrder = [
    ...config.blocks.groupBlocksOrder,
    { id: 'matadepera', title: 'Matadepera General' },
    { id: 'matadepera-frontpage', title: 'Matadepera Portada' }
  ];

  config.blocks.blocksConfig.listing.variations = [
    ...config.blocks.blocksConfig.listing.variations,
    { 
      id: 'icon',
      template: IconListingBlock,
      title: 'Icon',
    },
    { 
      id: 'slider',
      template: SliderListingBlock,
      title: 'Slider',
    },
    { 
      id: 'news',
      template: NewsListingBlock,
      title: 'News',
    },
    { 
      id: 'events',
      template: EventsListingBlock,
      title: 'Events',
    },
    { 
      id: 'equipments',
      template: EquipmentsListingBlock,
      title: 'Equipments',
    }
  ];
  config.blocks.blocksConfig.listing.showLinkMore = true;

  config.blocks.blocksConfig.news = {
    id: 'news',
    title: 'News',
    icon: cardsSVG,
    group: 'matadepera-frontpage',
    view: NewsBlockView,
    edit: NewsBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.events = {
    id: 'events',
    title: 'Events',
    icon: calendarSVG,
    group: 'matadepera-frontpage',
    view: EventsBlockView,
    edit: EventsBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.tramits = {
    id: 'tramits',
    title: 'Tramits',
    icon: computerSVG,
    group: 'matadepera',
    view: TramitsBlockView,
    edit: TramitsBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.on_live = {
    id: 'on_live',
    title: 'On Live',
    icon: videoSVG,
    group: 'matadepera',
    view: OnLiveBlockView,
    edit: OnLiveBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.useful_info = {
    id: 'useful_info',
    title: 'Useful Info',
    icon: infoSVG,
    group: 'matadepera',
    view: UsefulInfoBlockView,
    edit: UsefulInfoBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.phones = {
    id: 'phones',
    title: 'Phones',
    icon: phoneSVG,
    group: 'matadepera',
    view: PhonesBlockView,
    edit: PhonesBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.social_networks = {
    id: 'social_networks',
    title: 'Social Networks',
    icon: commentsSVG,
    group: 'matadepera',
    view: SocialNetworksBlockView,
    edit: SocialNetworksBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.basic_search = {
    id: 'basic_search',
    title: 'Basic Search',
    icon: zoomSVG,
    group: 'matadepera',
    view: BasicSearchBlockView,
    edit: BasicSearchBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.calendar_view = {
    id: 'calendar_view',
    title: 'Calendar View',
    icon: calendarSVG,
    group: 'matadepera',
    view: CalendarViewView,
    edit: CalendarViewEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.image = {
    id: 'image',
    title: 'Image',
    icon: cameraSVG,
    group: 'media',
    view: ImageView,
    edit: ImageEdit,
    schema: ImageSettingsSchema,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    getSizes: getImageBlockSizes,
  };

  config.blocks.blocksConfig.gridBlock.allowedBlocks = [
    ...config.blocks.blocksConfig.gridBlock.allowedBlocks,
    'news',
    'events',
    'tramits',
    'on_live',
    'useful_info',
    'phones',
    'social_networks',
    'basic_search',
    'calendar_view'
  ];
  config.blocks.blocksConfig.gridBlock.blocksConfig.news = {
    ...config.blocks.blocksConfig.news,
  };
  config.blocks.blocksConfig.gridBlock.blocksConfig.events = {
    ...config.blocks.blocksConfig.events,
  };
  config.blocks.blocksConfig.gridBlock.blocksConfig.tramits = {
    ...config.blocks.blocksConfig.tramits,
  };
  config.blocks.blocksConfig.gridBlock.blocksConfig.on_live = {
    ...config.blocks.blocksConfig.on_live,
  };
  config.blocks.blocksConfig.gridBlock.blocksConfig.useful_info = {
    ...config.blocks.blocksConfig.useful_info,
  };
  config.blocks.blocksConfig.gridBlock.blocksConfig.phones = {
    ...config.blocks.blocksConfig.phones,
  };
  config.blocks.blocksConfig.gridBlock.blocksConfig.social_networks = {
    ...config.blocks.blocksConfig.social_networks,
  };
  config.blocks.blocksConfig.gridBlock.blocksConfig.basic_search = {
    ...config.blocks.blocksConfig.basic_search,
  };
  config.blocks.blocksConfig.gridBlock.blocksConfig.calendar_view = {
    ...config.blocks.blocksConfig.calendar_view,
  };

  // Widgets
  config.widgets = {
    ...config.widgets,
    id: {
      ...config.widgets.id,
      content_icon: IconSelectorWidget,
      content_color: SimpleColorPicker ,
      geolocation: LeafletWidget ,
      registration_url: UrlWidget ,
      ticket_sales: UrlWidget,
      effective: DatetimeUTCWidget,
      expires: DatetimeUTCWidget,
    },
  };

  config.widgets.widget.icon_selector = IconSelectorWidget;
  config.widgets.widget.leaflet = LeafletWidget;
  config.widgets.widget.advanced_vocabulary_term_widget = AdvancedVocabularyTermWidget;
  config.widgets.widget.attachedimage = AttachedImageWidget;
  config.widgets.widget.datetime_utc = DatetimeUTCWidget;

  // Icon Selector
  config.settings.widget.icon_selector = {
    ...config.settings.widget.icon_selector,
    iconList: {
      ...config.settings.widget.icon_selector.iconList,
      ecityclic: ecityclicIconsList,
      brands: brandsIconsList,
    },
    defaultRenderer: {
      ...config.settings.widget.icon_selector.defaultRenderer,
      ResultRenderer
    }
  }

  config.settings.nonContentRoutes = [...config.settings.nonContentRoutes, /^\/import-tramits\/.*$/];
  config.addonRoutes.push(
    { path: '/import-tramits', component: ImportTramitsForm },
   
  );

  config.settings.available_colors = [
    '#3C3C3B',
    '#7FB927',
    '#E9F8CA',
    '#F6F5F5',
    '#162626',
    '#4f5c5c',
    '#e8e9e9',
    '#FAF2E7',
    '#152625',
  ];

  config.settings.apiExpanders = [
    ...config.settings.apiExpanders,
    {
      match: '',
      GET_CONTENT: ['header_info'],
    },
  ];

  if (__SERVER__) {
    const express = require('express');
    config.settings.expressMiddleware = [
      ...(config.settings.expressMiddleware || []),
      rssMiddleware(express),
    ];

  }

  return config;
};

export default applyConfig;
