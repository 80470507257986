import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Container as SemanticContainer, Grid } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { searchContent } from '@plone/volto/actions';
import { BodyClass } from '@plone/volto/helpers';
import { filter, map, isEmpty } from 'lodash';
import { flattenToAppURL, toPublicURL } from '@plone/volto/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';


const BlogUnfolded = ({ content }) => {
    const Container =
        config.getComponent({ name: 'Container' }).component || SemanticContainer;
    const PreviewImage = config.getComponent({ name: 'PreviewImage' }).component;

    const items = filter(content.items, (item) => {
        return item['@type'] !== 'Image' && !item.exclude_from_nav;
    });

    const dispatch = useDispatch();
    const allItems = useSelector((state) => map(state.search.subrequests.items?.items, (item) => {
        return {
            ...item,
            '@id': toPublicURL(item['@id']),
        }
    }));

    const getViewURL = function (item) {
        let URL = flattenToAppURL(item['@id']);
        if (item['@type'] === 'File') {
            URL += '/@@display-file/file';
        }
        if (item['@type'] === 'Image') {
            URL += '/@@display-file/image';
        }
        if (item?.getRemoteUrl) {
            URL = item?.getRemoteUrl;
        }
        return URL;
    };

    const getSubItems = function (itemID, subItems, mainItem) {
        return filter(subItems, (item) => {
            return item['@id'].includes(itemID) && item['@id'] !== itemID;
        });
    };

    useEffect(() => {
        let contentFilter = {
            b_size: 360,
            sort_on: 'getObjPositionInParent',
        };
        contentFilter['path.depth'] = 2
        dispatch(searchContent(flattenToAppURL(content['@id']), contentFilter, 'items'));
    }, [dispatch]);

    return (
        <>
            <BodyClass className="blogUnfolded" />

            {content.enable_announcements && <Announcements content={content} />}

            <Container id="content-core" className="blogUnfolded__page pt-5 list-unstyled" as="ul">

                {items.map((item) => (
                    <li key={item} className='blogUnfolded__item' >
                        <div className="blogUnfoldedItem__header">
                            {item.image_field && (
                                <PreviewImage
                                    item={item}
                                    alt=''
                                    className="blogUnfoldedItem__img" 
                                    sizes="(min-width: 1600px) 500px, (min-width: 1280px) 400px, (min-width: 600px) 200px, 50vw"
                                    loading="lazy"
                                />
                            )}
                            <UniversalLink
                                href={getViewURL(item)}
                                className="blogUnfoldedItem__link stretched-link" >
                                <span className="blogUnfoldedItem__link__text">{item.title}</span>
                            </UniversalLink>
                        </div>

                        {item.description && (
                            <p className="blogUnfoldedItem__desc">
                                {item.description}
                            </p>
                        )}
                      
                        {getSubItems(item['@id'], allItems, item) &&
                            <Grid as="ul" columns={12} className='blogUnfoldedItem__children list-unstyled'>
                                {getSubItems(item['@id'], allItems).map((item) => (
                                    <Grid.Column as="li" key={item} mobile={12} tablet={6} computer={4} widescreen={4} className='blogUnfoldedItem__children__item'>
                                        <UniversalLink href={getViewURL(item)} className="blogUnfoldedItem__children__link" >
                                            <strong  className="blogUnfoldedItem__children__text">{item.title}</strong>
                                            <FontAwesomeIcon icon={faAngleRight} className="blogUnfoldedItem__children__icon" />
                                        </UniversalLink>
                                    </Grid.Column>
                                ))}
                            </Grid>
                        }
                    </li>
                ))}

            </Container>
        </>
    );
};

export default BlogUnfolded;