import { map } from 'lodash';
import { Segment, Header, Container } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faXTwitter, faWhatsapp, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';

const View = (props) => {
	const { data, mode, content } = props;

	const socialIcons = {
		facebook: faFacebookF,
		twitter: faXTwitter,
		youtube: faYoutube,
		whatsapp: faWhatsapp,
    instagram: faInstagram
	}

	const socialNetworks = data?.socialNetworks || [];

	return (
		<Segment as="section" basic className='xxssBlock__wrapper d-print-none' >
			<div className="full-width xxssBlock">
				<Container className="xxssBlock__container">
					{data?.title ? (
						<Header as="h2" className='xxssBlock__title'>{data.title}</Header>
					) : (
						<Header as="h2" className='visually-hidden'>
							<FormattedMessage id="The link address is:" defaultMessage="The link address is:" />
						</Header>
					)}

{socialNetworks.length > 0 && (
            <ul className="xxssBlock__list my-0 list-unstyled">
              {map(socialNetworks, (item, key) => {
                const type = item.type ? item.type.toLowerCase() : null;
                if (!type || !socialIcons[type]) {
                  return null;
                }
                const icon = socialIcons[type];

                return (
                  <li key={key}>
                    <a className={`xxssBlock__link xxssBlock__link--${type}`} href={item.url}>
                      <FontAwesomeIcon icon={icon} className="xxssBlock__icon" />
                      <span className="visually-hidden">{item.type}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
        </Container>
      </div>
    </Segment>
  );
};


export default injectIntl(View);
