/**
 * Sharing component.
 * @module components/Sharing/Sharing
 */

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPrint } from '@fortawesome/free-solid-svg-icons';
import { faShareNodes, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faXTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { toPublicURL } from '@plone/volto/helpers';


const Sharing = props => {
	const content = props;
	const intl = useIntl();
	const print = () => window.print();
	const url = toPublicURL(content['@id']);

	return (
		<>

			<div className="published-wrapper documentActions mb-5 d-print-none">

				<Button className="printBtn d-print-none" onClick={print} >
					<FormattedMessage id="Print" defaultMessage="Print" />
					<FontAwesomeIcon icon={faPrint} className=" text-primary ms-3 fs-6" />
				</Button>

				<aside className="share-wrapper " aria-labelledby='shareTitle'>
					<h2 id="shareTitle" className="share-wrapper__title"><FormattedMessage id="Share" defaultMessage="Share" /></h2>

					<a className="socialShare__link socialShare__link-facebook" href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} rel="noopener" target="_blank">
						<FontAwesomeIcon icon={faFacebookF} />
						<span className="visually-hidden">Facebook <FormattedMessage id="Opens in new window" defaultMessage="Opens in new window" /></span>
					</a>

					<a className="socialShare__link" href={`https://twitter.com/share?url=${url}`} rel="noopener" target="_blank" >
						<FontAwesomeIcon icon={faXTwitter} />
						<span className="visually-hidden">Twitter
							<FormattedMessage id="Opens in new window" defaultMessage="Opens in new window" /></span>
					</a>

					<a className="socialShare__link" href={`https://api.whatsapp.com/send?text=${url}`} rel="noopener" target="_blank">
						<FontAwesomeIcon icon={faWhatsapp} />
						<span className="visually-hidden">Whatsapp
							<FormattedMessage id="Opens in new window" defaultMessage="Opens in new window" /></span>
					</a>

					<a className="socialShare__link" href={`mailto:?subject=${content.title}&body=${url}`} rel="noopener" target="_blank">
						<FontAwesomeIcon icon={faEnvelope} />
						<span className="visually-hidden"><FormattedMessage id="Email" defaultMessage="Email" />
							<FormattedMessage id="Opens in new window" defaultMessage="Opens in new window" /></span>
					</a>
				</aside>
			</div>
		</>
	);
};

export default Sharing;