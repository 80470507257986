import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  showWeather: {
    id: 'Show weather',
    defaultMessage: 'Show weather',
  },
  showAirQuality: {
    id: 'Show air quality',
    defaultMessage: 'Show air quality',
  },
  weatherLink: {
    id: 'Weather link',
    defaultMessage: 'Weather link',
  },
});





export const schema = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'showWeather', 'weatherLink', 'showAirQuality'],
      },
    ],
    properties: {
      title: {
        title: props.intl.formatMessage(messages.title),
        widget: 'text',
      },
      showWeather: {
        title: props.intl.formatMessage(messages.showWeather),
        type: 'boolean',
      },
      showAirQuality: {
        title: props.intl.formatMessage(messages.showAirQuality),
        type: 'boolean',
      },
      weatherLink: {
        title: props.intl.formatMessage(messages.weatherLink),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description'],
        allowExternals: true,
      },
    },
  };
};

export default schema;