import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { Segment, Header, List, Button } from 'semantic-ui-react';
import {
  When,
  Recurrence,
} from '@plone/volto/components/theme/View/EventDatesInfo';
import { searchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import { expandToBackendURL } from '@plone/volto/helpers';
import { Map } from '../';
import calendarSVG from '@plone/volto/icons/calendar.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare} from '@fortawesome/free-solid-svg-icons';

const messages = defineMessages({
  what: {
    id: 'event_what',
    defaultMessage: 'What',
  },
  when: {
    id: 'event_when',
    defaultMessage: 'When',
  },
  allDates: {
    id: 'event_alldates',
    defaultMessage: 'All dates',
  },
  downloadEvent: {
    id: 'Download Event',
    defaultMessage: 'Download Event',
  },
  where: {
    id: 'event_where',
    defaultMessage: 'Where',
  },
  contactName: {
    id: 'event_contactname',
    defaultMessage: 'Contact Name',
  },
  contactPhone: {
    id: 'event_contactphone',
    defaultMessage: 'Contact Phone',
  },
  attendees: {
    id: 'event_attendees',
    defaultMessage: 'Attendees',
  },
  website: {
    id: 'event_website',
    defaultMessage: 'Website',
  },
  visitWebsite: {
    id: 'visit_external_website',
    defaultMessage: 'Visit external website',
  },
  webTickets: {
    id: 'web_tickets',
    defaultMessage: 'Buy tickets online',
  },
  webRegistration: {
    id: 'web_registration',
    defaultMessage: 'Join event',
  },
  registration_url: {
    id: 'Registration URL',
    defaultMessage: 'Registration URL',
  },
  ticket_sales: {
    id: 'Ticket sales',
    defaultMessage: 'Ticket sales',
  },
  price: {
    id: 'Price',
    defaultMessage: 'Price',
  },
  organiser: {
    id: 'Organiser',
    defaultMessage: 'Organiser',
  },
  address: {
    id: 'Address',
    defaultMessage: 'Address',
  },
  virtual_location: {
    id: 'Virtual location',
    defaultMessage: 'Virtual location',
  },
  place: {
    id: 'Place',
    defaultMessage: 'Place',
  },
});

const EventDetails = ({ content, display_as = 'aside' }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const relatedPlace = useSelector((state) => state.search.subrequests.related_place?.items?.[0]);

  useEffect(() => {
    if (!content.related_place?.token) { return }

    let contentFilter = {
      portal_type: ['Place'],
      UID: content.related_place.token,
      review_state: 'published',
      metadata_fields: ['geolocation'],
    };

    dispatch(
      searchContent(
        flattenToAppURL('/'),
        contentFilter,
        'related_place',
      ),
    );
  }, [dispatch]);

  const getPosition = function (geolocation) {
    if (! geolocation ) {
      return null
    }
    return geolocation.split(';').map(parseFloat);
  }

  const geolocation = getPosition(content.geolocation)
  const geolocationPlace = getPosition(relatedPlace?.geolocation)

  return (
    <Segment
      as='dl'
      className='event-view__summary'
    >

      {content.event_subjects?.length > 0 && (
        <>
          <Header dividing sub as='dt' className='event-view__summary__term' >
            {intl.formatMessage(messages.what)}
          </Header>
          <dd>
            <div role="list" className="ui list">
              {content.event_subjects.map((item) => (
                <div role="listitem" className="item">{item.title}</div>
              ))}
            </div>
          </dd>
        </>
      )}
      <Header dividing sub as='dt' className='event-view__summary__term'>
        {intl.formatMessage(messages.when)}
      </Header>
      <dd> 
        <When 
          start={content.start}
          end={content.end}
          whole_day={content.whole_day}
          open_end={true}
        />
      </dd>
      {content.recurrence && (
        <>
          <Header dividing sub as='dt' className='event-view__summary__term'>
            {intl.formatMessage(messages.allDates)}
          </Header>
          <dd>
            <Recurrence recurrence={content.recurrence} start={content.start} />
          </dd>
        </>
      )}
      {content.organiser && (
        <>
          <Header dividing sub as='dt' className='event-view__summary__term'>
            {intl.formatMessage(messages.organiser)}
          </Header>
          <dd>
            {content.organiser}
          </dd>
        </>
      )}      
      {content.contact_name && (
        <>
          <Header dividing sub as='dt' className='event-view__summary__term'>
            {intl.formatMessage(messages.contactName)}
          </Header>
          <dd>
            {content.contact_email ? (
              <a href={`mailto:${content.contact_email}`}>
                {content.contact_name}
              </a>
            ) : (
              content.contact_name
            )}
          </dd>
        </>
      )}
      {content.contact_phone && (
        <>
          <Header dividing sub as='dt' className='event-view__summary__term'>
            {intl.formatMessage(messages.contactPhone)}
          </Header>
          <dd><a href={"tel:+34" + content.contact_phone.replace(/\s+/g, '')} >{content.contact_phone}</a></dd>
        </>
      )}
      {content.attendees?.length > 0 && (
        <>
          <Header dividing sub as='dt' className='event-view__summary__term'>
            {intl.formatMessage(messages.attendees)}
          </Header>
          <dd>
            <List items={content.attendees} />
          </dd>
        </>
      )}

      {(content.free || content.price) && (
        <>
          <Header dividing sub as='dt' className='event-view__summary__term'>
            {intl.formatMessage(messages.price)}
          </Header>
          <dd>
            {content.free && (<div>Activitat gratuïta</div>)}
            {content.price && (<div>{content.price}</div>)}
          </dd>
        </>
      )}

      {content.event_url && (
        <>
          <Header dividing sub as='dt' className='event-view__summary__term'>
            {intl.formatMessage(messages.website)}            
          </Header>
          <dd>
            <a
              href={content.event_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage(messages.website)} <FontAwesomeIcon className='ms-2' icon={faUpRightFromSquare} title={intl.formatMessage(messages.visitWebsite)} />
            </a>
          </dd>
        </>
      )}

      {content.ticket_sales && (
        <>
          <Header dividing sub as='dt' className='event-view__summary__term'>
            {intl.formatMessage(messages.ticket_sales)}
          </Header>
          <dd>
            <a
              href={content.ticket_sales}
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage(messages.webTickets)} <FontAwesomeIcon className='ms-2' icon={faUpRightFromSquare} title={intl.formatMessage(messages.visitWebsite)} />              
            </a>
          </dd>
        </>
      )}

      {content.registration_url && (
        <>
          <Header dividing sub as='dt' className='event-view__summary__term'>
            {intl.formatMessage(messages.registration_url)}
          </Header>
          <dd>
            <a
              href={content.registration_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage(messages.webRegistration)} <FontAwesomeIcon className='ms-2' icon={faUpRightFromSquare} title={intl.formatMessage(messages.visitWebsite)} />
            </a>
          </dd>
        </>
      )}

      <div className="download-event">
        <dt>
          <Icon name={calendarSVG} />
        </dt>
        <dd>
          <a
            className="ics-download"
            target="_blank"
            rel="noreferrer"
            href={`${expandToBackendURL(content['@id'])}/ics_view`}
          >
            {intl.formatMessage(messages.downloadEvent)}
          </a>
        </dd>
      </div>

      {(content.location || content.geolocation || content.related_place) && (
        <>
          <Header dividing sub as='dt' className='event-view__summary__term' >
            {intl.formatMessage(messages.where)}
          </Header>
          {content.location && (<dd className="my-2"><strong>{intl.formatMessage(messages.address)}:</strong> {content.location}</dd>)}
          {content.virtual_location && (<dd className="my-2"><strong>{intl.formatMessage(messages.virtual_location)}:</strong> {content.virtual_location}</dd>)}
          {content.related_place && (<dd className="my-2"><strong>{intl.formatMessage(messages.place)}:</strong> {content.related_place?.title}</dd>)}

          {geolocation && (
            <Map marker={geolocation} center={geolocation} />
          )}

          {(geolocationPlace && !geolocation) && (
            <Map marker={geolocationPlace} center={geolocationPlace} />
          )}

        </>
      )}

    </Segment>
  );
};

export default EventDetails;
