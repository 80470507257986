/**
 * Air quality actions.
 * @module actions/air_quality/air_quality
 */

import {
  GET_AIR_QUALITY,
} from '../../constants/ActionTypes';


/**
 * Get air quality function.
 * @function getAirQuality
 * @returns {Object} Get air quality action.
 */
export function getAirQuality(options) {
  return {
    type: GET_AIR_QUALITY,
    request: {
      op: 'get',
      path: '@air-quality',
    },
  };
}
