import { Segment, Header } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const YouTubeLatestVideo = () => {
  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiKey = 'AIzaSyCCXlbMUT-Ho3qYAljqwbyOyk-eQ2gOCLA'; // Substitueix per la teva clau d'API

  useEffect(() => {
    const controller = new AbortController();
    const fetchLatestVideo = async () => {
      try {
        const response = await axios.get('https://www.googleapis.com/youtube/v3/search', {
          params: {
            part: 'snippet',
            channelId: 'UCibNxbq5GcPkgyM1VgwlZmQ',
            maxResults: 1,
            order: 'date', // Ens assegurem de demanar el vídeo més recent
            key: apiKey,
          },
         signal: controller.signal,
        });

        const latestVideo = response.data.items[0];
        setVideo(latestVideo);
        setLoading(false);
      } catch (err) {
        if (axios.isCancel(err)) return;
        setError('Error carregant el vídeo');
        setLoading(false);
      }
    };

    fetchLatestVideo();
    return () => controller.abort();
  }, []);

  if (loading) return <p>Carregant...</p>;
  if (error) return <p>{error}</p>;

  const videoUrl = `https://www.youtube.com/embed/${video.id.videoId}`;

  return (
    <>     
      <iframe
          width="560"
          height="315"
          src={videoUrl}
          className='border-0 ratio ratio-16x9 w-100'
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Últim vídeo a Matadepera TV"
        ></iframe>
        <p className='pt-3 text-center'>
          <a className='button ui black' href={`https://www.youtube.com/watch?v=${video.id.videoId}`} >
            <FormattedMessage id="Anar al canal de Youtube" defaultMessage="Anar al canal de Youtube" />
          </a>
        </p>
    </>
  );
};


const View = (props) => { 
  const { data, mode, content } = props;

  return (
    <Segment as="section" className="bg-white px-0 liveSection d-print-none" basic>
      <div className="py-5">
        {data?.title ? (
          <Header className='mb-2 headline' as="h2">{data.title}</Header>
          ) : (
          <Header className='visually-hidden' as="h2"><FormattedMessage id="Matadepera en directe" defaultMessage="Matadepera en directe" /></Header>
        )}

        <YouTubeLatestVideo />
        
      </div>
    </Segment>
  );
};

export default injectIntl(View);