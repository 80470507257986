import React from 'react';
import NavItem from './NavItem';

const NavItems = ({ items, lang, mobile }) => {
  return (
    <>
      {items.map((item) => (
        <NavItem item={item} lang={lang} key={item.url} mobile={mobile} />
      ))}
    </>
  );
};

export default NavItems;
